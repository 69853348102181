import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from "redux"
import { Provider, RootStateOrAny, useSelector } from 'react-redux'
import thunk from 'redux-thunk';
import counterSlice from "./reducers";
import Layout from "./components/Layout";
import { router } from './Router';

// const persistConfig = {
//     key: 'root',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, counterSlice)

export type RootState = ReturnType<typeof store.getState>;

let baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// @ts-ignore
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
//const middlewareList = [thunk, logger]
const middlewareList = [thunk]
const enhancer = composeEnhancers(
	applyMiddleware(...middlewareList)
);

export const store = createStore(counterSlice, enhancer);

ReactDOM.render(
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>
	, rootElement)


