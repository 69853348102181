import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Api } from '../../../../Api';
import {UiContainer, UiFabContainer, UiFrmControl, UiPaper} from "../../../../utils/Helpers";
import {setLoadingMessage} from "../../../../reducers";


export default React.memo(function MailTemplates() {

    const [mailTemplate, setMailTemplate] = React.useState<any>(null);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };


    useEffect(() => {
        (async () => {
            var dbMailTemplate = await Api.MailTemplate.all(null, dispatch);
            setMailTemplate(dbMailTemplate);
        })();
    }, [])

    const handleChange = (event: any) => {
        setMailTemplate({
            ...mailTemplate,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMailTemplate({
            ...mailTemplate,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <UiContainer>
                {mailTemplate &&
                    <React.Fragment>

                        <Accordion expanded={expanded === 'mailcambiapassword'}
                            onChange={handleChangeAccordion('mailcambiapassword')}>
                            <AccordionSummary>
                                <Typography sx={{ fontWeight: 700 }}>TEMPLATE CAMBIO PASSWORD</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="modifica_password_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.modifica_password_t ?? "[INSERIRE IL TITOLO]"}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="modifica_password"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.modifica_password ?? "{{email}} {{password}} {{domain}}"}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'mailinvito'}
                            onChange={handleChangeAccordion('mailinvito')}>
                            <AccordionSummary>
                                <Typography sx={{ fontWeight: 700 }}>TEMPLATE INVITO</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="invito_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.invito_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="invito"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.invito ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'mailnews'}
                            onChange={handleChangeAccordion('mailnews')}>
                            <AccordionSummary>
                                <Typography sx={{ fontWeight: 700 }}>TEMPLATE NEWS</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="news_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.news_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="news"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.news ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'mailvideo'}
                            onChange={handleChangeAccordion('mailvideo')}>
                            <AccordionSummary>
                                <Typography sx={{ fontWeight: 700 }}>TEMPLATE VIDEO</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="video_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.video_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="video"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.video ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'mailprodotto'}
                            onChange={handleChangeAccordion('mailprodotto')}>
                            <AccordionSummary>
                                <Typography sx={{ fontWeight: 700 }}>TEMPLATE PRODOTTO</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="prodotto_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.prodotto_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="prodotto"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.prodotto ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'mailregistrazione'}
                                   onChange={handleChangeAccordion('mailregistrazione')}>
                            <AccordionSummary>
                                <Typography sx={{fontWeight: 700}}>TEMPLATE REGISTRAZIONE</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={""}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="registrazione_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.registrazione_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="registrazione"
                                            label="contenuto"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.registrazione ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'mailrichiediprezzo'}
                                   onChange={handleChangeAccordion('mailrichiediprezzo')}>
                            <AccordionSummary>
                                <Typography sx={{fontWeight: 700}}>TEMPLATE RICHIESTA PREZZO</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UiPaper first title={"TITOLO"}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="richiedi_prezzo_t"
                                            label="oggetto"
                                            fullWidth
                                            type="text"
                                            defaultValue={mailTemplate?.richiedi_prezzo_t ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                                <UiPaper title={"CONTENUTO"}>
                                    <UiFrmControl first>
                                        <TextField
                                            name="richiedi_prezzo_admin"
                                            label="richiedi_prezzo_admin"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.richiedi_prezzo_admin ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="richiedi_prezzo_cliente"
                                            label="richiedi_prezzo_cliente"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.richiedi_prezzo_cliente ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <TextField
                                            name="richiedi_prezzo_fornitore"
                                            label="richiedi_prezzo_fornitore"
                                            fullWidth
                                            multiline
                                            maxRows={10}
                                            type="text"
                                            defaultValue={mailTemplate?.richiedi_prezzo_fornitore ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </UiFrmControl>
                                </UiPaper>
                            </AccordionDetails>
                        </Accordion>
                        
                    </React.Fragment>
                }
            </UiContainer>
            <UiContainer hideBox>
                <UiFabContainer>
                    <Fab color="secondary"
                         onClick={async (e) => {
                             dispatch(setLoadingMessage("salvataggio in corso..."));
                             if (await Api.MailTemplate.save(mailTemplate, [], dispatch)) {
                             }
                             dispatch(setLoadingMessage(null));
                         }}
                    >
                        <SaveIcon/>
                    </Fab>
                </UiFabContainer>
            </UiContainer>
        </React.Fragment>
    );
})