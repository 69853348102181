import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Switch, Typography } from '@material-ui/core';
import Tab1 from '../tabs/Tab1';
import Tab2 from '../tabs/Tab2';
import Tab3 from '../tabs/Tab3';
import { makeStyles } from '@material-ui/core/styles';

enum ActiveTab {
    Tab1 = 1,
    Tab2 = 2,
    Tab3 = 3,
}

interface ModalWithTabsProps {
    onClose: () => void;
    onAccettaTutti: () => void;
    onAccettaSelezionati: (switchValues: boolean[]) => void;
}

const useStyles = makeStyles({
    dialogContent: {
        // Imposta la grandezza fissa della DialogContent
        width: '800px', // Aggiorna la larghezza desiderata
        maxHeight: '600px',
        overflowY: 'hidden',
    },
    tabButtonsContainer: {
        marginBottom: '16px',
        display: 'flex', // Imposta il display a flex per allineare i bottoni dei tab
        justifyContent: 'space-between', // Allinea i bottoni dei tab a sinistra e destra
    },
    tabButton: {
        flex: 1, // Per distribuire lo spazio disponibile in modo uniforme tra i bottoni dei tab
        marginRight: '8px',
    },
});

const ModalWithTabs: React.FC<ModalWithTabsProps> = ({ onClose, onAccettaTutti, onAccettaSelezionati }) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Tab1);
    const [switch1Checked, setSwitch1Checked] = useState<boolean>(false);
    const [switch2Checked, setSwitch2Checked] = useState<boolean>(false);
    const [switch3Checked, setSwitch3Checked] = useState<boolean>(true);
    const [showButtons, setShowButtons] = useState<boolean>(true);


    const handleTabChange = (tab: ActiveTab) => {
        setActiveTab(tab);
        setShowButtons(tab === ActiveTab.Tab1);
    };
    const handleRifiuta = () => {
        // Aggiungi l'effetto di ricaricamento della pagina
        window.location.reload();
    };
    const handleClose = () => {
        // Aggiungere qui la logica per chiudere la modale se necessario

        onClose();
    };

    const handleAccettaTutti = () => {
        // Implementa la logica per accettare tutti
        setSwitch1Checked(true);
        setSwitch2Checked(true);
        setSwitch3Checked(true);
        onAccettaTutti();
        console.log('Accetta Tutti');
    };

    const handleAccettaSelezionati = () => {
        // Implementa la logica per accettare selezionati
        const switchValues = [switch1Checked, switch2Checked, switch3Checked];
        onAccettaSelezionati(switchValues);
        console.log('Accetta Selezionati');
    };

    const handleSwitch1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitch1Checked(event.target.checked);
    };

    const handleSwitch2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitch2Checked(event.target.checked);
    };
    const handleSwitch3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitch3Checked(event.target.checked);
    };

    return (
        <Dialog open={true} classes={{ paper: classes.dialogContent }}>
            <DialogTitle>
                Consensi Privacy e Marketing
            </DialogTitle>
            <DialogContent>
                <div className={classes.tabButtonsContainer}>
                    <Button className={classes.tabButton} variant={activeTab === ActiveTab.Tab1 ? 'contained' : 'outlined'} onClick={() => handleTabChange(ActiveTab.Tab1)}>Generale</Button>
                    <Button className={classes.tabButton} variant={activeTab === ActiveTab.Tab3 ? 'contained' : 'outlined'} onClick={() => handleTabChange(ActiveTab.Tab3)}>Dettagli Informativa Privacy</Button>

                    <Button className={classes.tabButton} variant={activeTab === ActiveTab.Tab2 ? 'contained' : 'outlined'} onClick={() => handleTabChange(ActiveTab.Tab2)}>Marketing Profilato</Button>
                </div>
                <div>
                    {activeTab === ActiveTab.Tab1 && (
                        <>
                            <Typography variant="subtitle1">Marketing Profilato</Typography>
                            <Switch checked={switch1Checked} onChange={handleSwitch1Change} color="primary" />
                            <Typography variant="subtitle1">Marketing Profilato Terzi</Typography>
                            <Switch checked={switch2Checked} onChange={handleSwitch2Change} color="primary" />
                            <Typography variant="subtitle1">Privacy</Typography>
                            <Switch checked={switch3Checked} disabled color="primary" /> {/* Imposta disabled */}
                            <Tab1 />
                        </>
                    )}
                    {activeTab === ActiveTab.Tab2 && <Tab2 />}
                    {activeTab === ActiveTab.Tab3 && <Tab3 />}
                </div>
            </DialogContent>
            <DialogActions>
                {showButtons && (
                    <>
                        <Button onClick={handleAccettaTutti} color="primary">
                            Accetta Tutti
                        </Button>
                        <Button onClick={handleAccettaSelezionati} color="primary">
                            Accetta Selezionati
                        </Button>
                    </>
                )}
                <Button onClick={handleRifiuta} color="primary">
                    Rifiuta
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default ModalWithTabs;
