import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Moment from "moment";
import Api from "../../../../Api";
import {PageHeader, UiContainer} from '../../../../utils/Helpers';
import {TextNormaBold, TextNormal} from "../../../../utils/Text";


export default function RichiestaPrezzoDettagli(props) {

    const [dettagli, setDettagli] = React.useState<any>(null);
    const dispatch = useDispatch();

    let { sku } = useParams<any>();
    let { email_cliente } = useParams<any>();

    useEffect(() => {
        (async () => {
            await Api.RichiestaPrezzo.conferma(null, dispatch);
            setDettagli(await Api.RichiestaPrezzo.dettagli({ sku: sku, email_cliente: email_cliente }, dispatch));
        })();
    }, [])

    return (
        <UiContainer>
            <PageHeader title={"Richiesta Contatto da:"} />
            <table cellPadding={10}>
                <tr>
                    <td>
                        <TextNormal>
                            {"ragione sociale"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {dettagli?.ragione_sociale}
                        </TextNormaBold>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextNormal>
                            {"nome"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {dettagli?.nome}
                        </TextNormaBold>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextNormal>
                            {"prodotto"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {dettagli?.prodotto}
                        </TextNormaBold>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextNormal>
                            {"sku"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {dettagli?.sku}
                        </TextNormaBold>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextNormal>
                            {"data richiesta"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {Moment(dettagli?.data).format('DD/MM/YY HH:mm')}
                        </TextNormaBold>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TextNormal>
                            {dettagli?.tipo_contatto == "EMAIL" ? "email" : "telefono"}
                        </TextNormal>
                    </td>
                    <td>
                        <TextNormaBold>
                            {dettagli?.contatto}
                        </TextNormaBold>
                    </td>
                </tr>
            </table>
        </UiContainer>
    )
}