import React from 'react';
import {Box} from "@mui/material";

export const TextDescription = React.memo(function TextDescriptionFn(props: any) {
    return (
        <Box {...props} className={"TextDescription"} style={{fontWeight: 400, fontSize: 13}}>
            {props.children}
        </Box>
    )
})

export const TextNormal = React.memo(function TextNormal2(props: any) {
    return (
        <Box {...props} className={"TextNormal"} style={{fontWeight: 400, fontSize: 15}}>
            {props.children}
        </Box>
    )
})

export const TextNormaBold = React.memo(function TextNormaBoldFn(props: any) {
    return (
        <Box {...props} className={"TextNormal"} style={{fontWeight: 700, fontSize: 15}}>
            {props.children}
        </Box>
    )
})

export const TextTitle = React.memo(function TextTitleFn(props: any) {
    return (
        <Box {...props} className={"TextTitle"} style={{fontWeight: 700, fontSize: 14.5, textTransform: "uppercase"}}>
            {props.children}
        </Box>
    )
})

export const TextTitleSmall = React.memo(function TextTitleSmallFn(props: any) {
    return (
        <Box {...props} className={"TextTitleSmall"} style={{fontWeight: 700, fontSize: 11.5}}>
            {props.children}
        </Box>
    )
})

export const TextTitleSecondary = React.memo(function TextTitleFn(props: any) {
    return (
        <Box {...props} className={"TextTitleSecondary"}
             sx={{color: "secondary.main", fontWeight: 700, fontSize: 14.5, textTransform: "uppercase"}}>
            {props.children}
        </Box>
    )
})

export const TextTitleSecondarySmall = React.memo(function TextTitleSecondaryFn(props: any) {
    return (
        <Box {...props} className={"TextTitleSecondarySmall"}
             sx={{fontWeight: 700, fontSize: 11.5, color: "secondary.main", textTransform: "uppercase"}}>
            {props.children}
        </Box>
    )
})

export const TextErrorSmall = React.memo(function TextErrorSmallFn(props: any) {
    return (
        <Box {...props} sx={{fontWeight: 700, fontSize: 11.5, color: "error.main"}}>
            {props.children}
        </Box>
    )
})