import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DbCategoria } from "../../Db";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { isSmallScreen, Log } from "../../utils/Functions";
import { HookIsMobile, UiContainer, UiFabContainer } from "../../utils/Helpers";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Banner from '../../components/banner/Banner';
import CartCategorieItem from "./CartCategorieItem";
import App from '../../components/App';
import App_WOLF from "../../components/App_WOLF";
import CartCategorieItem_WOLF from "./CartCategorieItem_WOLF";

export default function CartCategorie() {

    let { tipologia } = useParams<any>();

    const [categorie, setCategorie] = React.useState<any>(null);
    const [prodottiArray, setProdottiArray] = React.useState<any>([]);
    const [arr, setArr] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state);

    async function reload() {
        setCategorie((await DbCategoria({ tipologia: tipologia, cart: true }, dispatch))?.data);
    }

    useEffect(() => {
        if (isSmallScreen())
            setArr([0, 1, 2]);
        else
            setArr([0, 1, 2, 3]);

        (async () => {
            await reload();
        })();
    }, [tipologia])

    useEffect(() => {
        setProdottiArray([]);
        if (categorie) {
            let tempArr: any = [];
            categorie.forEach((item, index) => {
                if (!isSmallScreen()) {
                    if (index % 4 == 0) {
                        const i = index + 4;
                        setProdottiArray({ items: [...prodottiArray, { items: [categorie[i - 4], categorie[i - 3], categorie[i - 2], categorie[i - 1]] }] });
                        tempArr.push({ items: [categorie[i - 4], categorie[i - 3], categorie[i - 2], categorie[i - 1]] });
                    }
                } else {
                    if (index % 3 == 0) {
                        const i = index + 3;
                        setProdottiArray({ items: [...prodottiArray, { items: [categorie[i - 3], categorie[i - 2], categorie[i - 1]] }] });
                        tempArr.push({ items: [categorie[i - 3], categorie[i - 2], categorie[i - 1]] });
                    }
                }
            });
            setProdottiArray(tempArr);
        }
    }, [categorie])

    useEffect(() => {
    }, [prodottiArray])

    return (
        <React.Fragment>
            <Banner sx={{ mb: 2, p: root.Dorsale && root.Dorsale.layout == "DEFAULT" ? 2 : 0 }} />
            <UiContainer className={root.Dorsale && root.Dorsale.layout == "WOLF" ? "box box_padding" : ""}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    {prodottiArray && prodottiArray && prodottiArray.map((cell, index) => (
                        <Stack
                            key={index}
                            sx={{}}
                            direction={im ? "column" : "row"}
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={2}
                        >
                            {arr.map((o, i) => {
                                const data = prodottiArray[index].items[i];
                                if (data == null) {
                                    if (im)
                                        return null;
                                }
                                return (
                                    <React.Fragment>
                                        {root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) &&
                                            <CartCategorieItem
                                                onClick={() => {
                                                    navigate(`/catalogo/${tipologia}/${data.guid}/${encodeURIComponent(data.nome)}`);
                                                }}
                                                tipologia={tipologia}
                                                data={data} />}
                                        {root.Dorsale && root.Dorsale.layout == "WOLF" &&
                                            <CartCategorieItem_WOLF
                                                onClick={() => {
                                                    navigate(`/catalogo/${tipologia}/${data.guid}/${encodeURIComponent(data.nome)}`);
                                                }}
                                                tipologia={tipologia}
                                                data={data} />}
                                    </React.Fragment>
                                )
                            })}
                        </Stack>
                    ))}
                </Stack>
                <UiFabContainer onReload={async () => {
                    await reload();
                }} />
            </UiContainer>
        </React.Fragment>
    )
}
