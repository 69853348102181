import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box} from "@mui/material";
import Api from "../../../../Api";
import {
    PageHeader,
    UiContainer,
    UiFabContainer,
    UiFrmControl,
    UiPaper,
    UiSkeletonForm
} from "../../../../utils/Helpers";
import {setLoadingMessage, showInfo} from "../../../../reducers";


export default React.memo(function TemplateOrdiniEdit() {
    interface MailTemplateOrdini {
        id: string;
        subject: string;
        body: string;
        categoria: string;
        sottocategoria: string;
        default_email: string;
    }

    const { id } = useParams<any>();
    const [item, setItem] = React.useState<any>();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);



    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])


    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        if (id != null) {
            const templateMailOrdine = await Api.MailTemplateMicroservice.findById(id, dispatch);
            setItem(templateMailOrdine);
        } else
            setItem({});
    }

    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.categoria} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"template ordine"}>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    hidden
                                    name="id"
                                    label="id"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.id ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="subject"
                                    label="Oggetto"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.subject ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="body"
                                    label="Contenuto"
                                    fullWidth
                                    required
                                    type="text"
                                    multiline
                                    maxRows={30}
                                    defaultValue={item?.body ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="categoria"
                                    label="Categoria"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.categoria ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="sottocategoria"
                                    label="Sottocategoria"
                                    fullWidth
                                    type="text"
                                    defaultValue={item?.sottocategoria ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="defaultEmail"
                                    label="Default Email"
                                    fullWidth
                                    type="text"
                                    defaultValue={item?.defaultEmail ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                 onClick={async () => {
                                     if (!window.confirm("confermi"))
                                         return false;
                                     dispatch(setLoadingMessage("Eliminazione in corso..."))
                                     if (await Api.MailTemplateMicroservice.del(item.id, dispatch)) {
                                         dispatch(showInfo("il record è stato eliminato correttamente"));
                                         navigate(-1);
                                     }
                                     dispatch(setLoadingMessage(null))
                                 }}>
                                <DeleteIcon/>
                            </Fab>
                            <Fab color="secondary"
                                 onClick={async (e) => {
                                     dispatch(setLoadingMessage("salvataggio in corso..."))
                                     if (await Api.MailTemplateMicroservice.saveTemplate(item, dispatch)) {
                                         dispatch(showInfo("il record è stato modificato correttamente"));
                                         navigate(-1);
                                     }
                                     dispatch(setLoadingMessage(null))
                                     return false;
                                 }}
                            >
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})
