import React, { useEffect } from 'react';
import {
    showInfo,
} from "../../../reducers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer, UiFileUploader
} from "../../../utils/Helpers";
import {
    DbCategoria, DbRuolo,
} from "../../../Db";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import Api from "../../../Api";
import { validationError, validationMessage, validationState } from "../../../utils/Functions";

export default React.memo(function PrivacyEdit() {

    let { guid } = useParams<any>();

    const [item, setItem] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    const [ruoli, setRuoli] = React.useState<any>(null);

    const [validation, setValidation] = React.useState<Array<any>>([
        {
            id: "nome",
            required: true,
        },
        {
            id: "testo",
            required: true,
        },
        {
            id: "posizione",
            required: true,
        },
    ]);

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        if (guid != "")
            setItem(await Api.Privacy.all({ guid: guid }, dispatch));
        else
            setItem({});
    }

    useEffect(() => {
        (async () => {
            setRuoli((await DbRuolo(dispatch)));
            await reload();
        })();
    }, [])

    return (
        <React.Fragment>
            {/*                        {JSON.stringify(validation)}*/}
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <UiFrmControl component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.guid ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    name="nome"
                                    error={validationError(validation, "nome")}
                                    helperText={validationMessage(validation, "nome")}
                                    label="nome"
                                    fullWidth
                                    type="text"
                                    defaultValue={item?.nome ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    name="testo"
                                    error={validationError(validation, "testo")}
                                    helperText={validationMessage(validation, "testo")}
                                    label="testo"
                                    fullWidth
                                    type="text"
                                    multiline
                                    maxRows={10}
                                    defaultValue={item?.testo ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    name="posizione"
                                    label="posizione"
                                    fullWidth
                                    type="number"
                                    defaultValue={item?.posizione ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                {ruoli &&
                                    <TextField
                                        name="ruolo"
                                        select
                                        fullWidth
                                        value={item.ruolo?.toUpperCase() ?? ""}
                                        label="ruolo"
                                        onChange={handleChange}
                                    >
                                        {ruoli.map((option) => (
                                            <MenuItem key={option.nome} value={option.nome}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            </UiFrmControl>
                            <UiFrmControl>
                                <FormControlLabel control={<Checkbox checked={item.predefinito ?? true}
                                    name={"predefinito"}
                                    onChange={handleChangeCheckbox} />}
                                    label={"predefinito"} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <FormControlLabel control={<Checkbox checked={item.obbligatorio ?? true}
                                    name={"obbligatorio"}
                                    onChange={handleChangeCheckbox} />}
                                    label={"obbligatorio"} />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                onClick={async () => {
                                    if (!window.confirm("confermi"))
                                        return false;
                                    if (await Api.Privacy.del(item, dispatch)) {
                                        dispatch(showInfo("la privacy è stata eliminata correttamente"));
                                        navigate(-1)
                                    }
                                }}>
                                <DeleteIcon />
                            </Fab>
                            <Fab color="secondary"
                                onClick={async (e) => {
                                    if (validationState(item, validation, setValidation) == false)
                                        return false;

                                    if (await Api.Privacy.save(item, [], dispatch)) {
                                        dispatch(showInfo("la privacy è stata modificata correttamente"));
                                        navigate(-1)
                                    }
                                    return false;
                                }}
                            >
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </UiFrmControl>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})