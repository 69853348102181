import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {BaseGrid} from "./BaseGrid";
import Api from "../Api";

export default function Logs(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.Log.all}
                onRowClick={(row) => {
     /*               navigate(`/settore/edit/${row.guid}`);*/
                }}
                onNew={() => {
            /*        navigate(`/settore/edit/`);*/
                }}
                headers={[
                    {
                        id: 'email',
                        label: 'email',
                    },
                    {
                        id: '_event',
                        label: 'event',
                    },
                    {
                        id: 'type',
                        label: 'type',
                    },
                ]}
            />
        </React.Fragment>)
}