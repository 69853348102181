import React from 'react';
import {RootStateOrAny, useSelector} from "react-redux";
import AnagraficaEditCliente from "./AnagraficaEditCliente";
import AnagraficaEditCliente_WOLF from "./AnagraficaEditCliente_WOLF";
import {useParams} from "react-router-dom";

export default React.memo(function AnagraficaEdit() {

    type AnagraficaType = {
        ruolo: string
    }

    //"FORNITORE", "CLIENTE", "ADMIN", "COGESTORE", "CITTADINO"
    const root = useSelector((state: RootStateOrAny) => state);
    return (
        <React.Fragment>
            {root.Dorsale && (root.Dorsale.layout == "DEFAULT") && <AnagraficaEditCliente/>}
            {root.Dorsale && root.Dorsale.layout == "WOLF" && <AnagraficaEditCliente_WOLF/>}
        </ React.Fragment>
    )
})