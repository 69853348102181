import { useState } from "react";
import {
    Box,
    Button,
    CardHeader,
    Stack,
} from "@mui/material";
import { RootStateOrAny, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Chip from "@mui/material/Chip";
import {
    currencyFormat,
    getNewProdottoImmagine,
} from "../../utils/Functions";
import { HookIsMobile } from "../../utils/Helpers";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import TextEllipsis from "react-text-ellipsis";
import {
    TextDescription,
    TextErrorSmall,
    TextNormal,
    TextTitle,
} from "../../utils/Text";
import { ModalRichiediPrezzo } from "../../components/modals/ModalRichiediPrezzo";
import { Link } from "react-router-dom";

export default function CartProdottiItem(
    this: { path: string; component: () => JSX.Element },
    props: any
) {
    const im = HookIsMobile();
    const data = props.data;
    const [richiediPrezzo, setRichiediPrezzo] = useState<any>(null);
    const root = useSelector((state: RootStateOrAny) => state);

    const fornitoreCorrente = props.fornitori?.find((x) => x.email === data.email_fornitore);

    console.log(fornitoreCorrente)

    const nomeFornitoreCorrente =
        fornitoreCorrente?.nome_attivita || "" || fornitoreCorrente?.ragione_sociale || "" || "n/a";

    return (
        <Card
            elevation={im ? 0 : 0}
            sx={{
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                border: "solid 1px #1B1B1B",
                borderColor: root?.GlobalTheme?.menu_bgcolor,
            }}>
            <CardHeader
                sx={{ height: 74 }}
                avatar={
                    <Avatar sx={{ bgcolor: "palette.primary" }}>
                        {data.tipologia.toString().charAt(0).toUpperCase()}
                    </Avatar>
                }
                title={
                    <TextEllipsis lines={2}>
                        <TextTitle title={data.nome}>{data.nome}</TextTitle>
                    </TextEllipsis>
                }
                subheader={
                    fornitoreCorrente?.vetrina_abilitata ? (
                        <Link
                            style={{
                                textDecoration: "none",
                                color: root.GlobalTheme.color_secondary,
                                fontStyle: "italic",
                            }}
                            to={`/vetrina/${nomeFornitoreCorrente}?guid=${fornitoreCorrente?.guid}`}
                        >
                            {nomeFornitoreCorrente}
                        </Link>
                    ) : (
                        <TextNormal>{nomeFornitoreCorrente}</TextNormal>
                    )
                }
            />
            <CardMedia
                component='img'
                height='160'
                image={
                    data?.ProdottoToMedia
                        ? getNewProdottoImmagine(
                            data?.ProdottoToMedia[0],
                            im ? window.innerWidth : window.innerWidth / props.arr.length
                        )
                        : null
                }
            />
            <CardContent sx={{ height: 100 }}>
                <TextDescription>
                    <TextEllipsis lines={4}>{data.descrizione}</TextEllipsis>
                </TextDescription>
            </CardContent>
            <CardActions sx={{ backgroundColor: "white" }} disableSpacing>
                <Stack
                    spacing={0}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ width: "100%" }}>
                    <Stack direction={"row"} justifyContent={"start"} alignItems={"center"}>
                        {data.quantita > 0 && data.prezzo > 0 && (
                            <IconButton
                                color={"secondary"}
                                onClick={async () => {
                                    props.setProdotto(data);
                                }}>
                                <AddShoppingCartIcon />
                            </IconButton>
                        )}
                        {data.quantita === 0 && (
                            <Box>
                                <TextErrorSmall>ESAURITO</TextErrorSmall>
                            </Box>
                        )}
                        <IconButton
                            onClick={async () => {
                                props.setSchedaProdotto(data);
                            }}>
                            <ZoomOutMapIcon />
                        </IconButton>
                    </Stack>
                    <Box>
                        {data.prezzo > 0 && !data.offerta && (
                            <Chip
                                size={"medium"}
                                sx={{ fontWeight: "bold" }}
                                color={"default"}
                                label={currencyFormat(data.prezzo)}
                            />
                        )}
                        {!!(data.offerta > 0 && data.prezzo) && (
                            <Box
                                sx={{
                                    textAlign: "center",
                                    opacity: 0.8,
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textDecoration: "line-through",
                                }}>
                                {currencyFormat(data.prezzo)}
                            </Box>
                        )}
                        {data.offerta > 0 && data.prezzo > 0 && (
                            <Chip
                                size={"medium"}
                                sx={{ fontWeight: "bold" }}
                                color={"default"}
                                label={currencyFormat(data.offerta)}
                            />
                        )}
                        {data.prezzo > 0 && (
                            <Box sx={{ fontSize: 10, fontWeight: 500, textAlign: "center" }}>Iva inclusa</Box>
                        )}
                        {data.prezzo <= 0 && (
                            <Button
                                sx={{ whiteSpace: "nowrap" }}
                                size={"small"}
                                variant={"outlined"}
                                onClick={() => {
                                    setRichiediPrezzo(data);
                                }}>
                                {data.richiedi_preventivo_t == null ? "Richiedi preventivo" : data.richiedi_preventivo_t}
                            </Button>
                        )}
                    </Box>
                    <ModalRichiediPrezzo setRichiediPrezzo={setRichiediPrezzo} richiediPrezzo={richiediPrezzo} />
                </Stack>
            </CardActions>
        </Card>
    );
}
