import React, { memo, useEffect, useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, IconButton, Modal,
    Tooltip,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DbAnagrafica, DbCondizione } from "../../Db";
import { useNavigate, useParams } from "react-router-dom";
import { HookIsMobile } from "../../utils/Helpers";
import { currencyFormat, downloadMedia, getNewProdottoImmagine, getProdottoImmagine } from "../../utils/Functions";
import { AddCartItem, setDialogMessage, setMessage } from "../../reducers";
import CardMedia from "@mui/material/CardMedia";
import { TextDescription, TextTitle, TextTitleSecondary, TextTitleSecondarySmall, TextTitleSmall } from "../../utils/Text";
import Api from "../../Api";
import { ModalRichiediPrezzo } from '../../components/modals/ModalRichiediPrezzo';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export default React.memo(function CartProdottiAcquista(props: any) {
    const [openTerminiAcquisto, setOpenTerminiAcquisto] = React.useState(false);
    const [condizione, setCondizione] = React.useState<any>(null);
    const [rivenditore, setrivenditore] = React.useState<boolean>(false);
    const [openDettaglio, setOpenDettaglio] = React.useState(false);
    const [fornitori, setFornitori] = React.useState<any>([]);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [openImage, setOpenImage] = React.useState(false);
    const [servizioEsterno, setServizioEsterno] = React.useState<any>(null);
    const [feedbackOk, setFeedbackOk] = React.useState(false);
    const [dialogTemporaneo, setdialogTemporaneo] = React.useState<string | null>(null);

    const [carouselCounter, setCarouselCounter] = useState(0)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const [richiediPrezzo, setRichiediPrezzo] = React.useState<any>(null);
    let { ruolo } = useParams<any>();
    const root = useSelector((state: RootStateOrAny) => state);
    (window as any).done = () => {
        setFeedbackOk(true);
    };

    useEffect(() => {
        (async () => {
            setrivenditore(await Api.Anagrafica.rivenditore(null, dispatch));
            setFornitori((await DbAnagrafica({ ruolo: "FORNITORE", flat: true }, dispatch))?.data);
            if (props.prodotto) {
                setOpenTerminiAcquisto(true);
                setCondizione(await DbCondizione({ guid: props.prodotto.guid_condizione }, dispatch));
            } else {
                setOpenTerminiAcquisto(false);
            }
        })();
    }, [props.prodotto])

    useEffect(() => {
        (async () => {
            setFornitori((await DbAnagrafica({ ruolo: "FORNITORE", flat: true }, dispatch))?.data);

            if (props.schedaProdotto) {
                setOpenDettaglio(true);
            } else {
                setOpenDettaglio(false);
            }
        })();
    }, [props.schedaProdotto])
    useEffect(() => {
        (async () => {
            if (props.onClose)
                props.onClose();
        })();
    }, [root.CartItems])
    const ServizioEsternoModal = React.memo(function ServizioEsternoModalFn(props: any) {
        return (
            <Modal
                sx={{}}
                open={props.servizioEsterno != null}
                onClose={() => {
                }}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    minWidth: "90vw",
                    minHeight: "80vh",
                    p: 0,
                }}>
                    <iframe
                        frameBorder="0"
                        style={{ minWidth: "90vw", minHeight: "80vh" }}
                        src={props.servizioEsterno}>
                    </iframe>
                    <Box sx={{ p: 2, textAlign: "center" }}>
                        <Button variant={"outlined"} size={"medium"} color={"inherit"} onClick={() => {
                            if (props.onAbort)
                                props.onAbort();
                        }}>
                            indietro
                        </Button>
                        {feedbackOk &&
                            <Button sx={{ ml: 2 }} variant={"contained"} size={"medium"} color={"secondary"}
                                onClick={() => {
                                    if (props.onClose)
                                        props.onClose();
                                }}>
                                ok fatto
                            </Button>
                        }
                    </Box>
                </Box>
            </Modal>
        )
    })

    function addToCart() {
        setOpenDettaglio(false);
        setOpenTerminiAcquisto(false);
        setServizioEsterno(null);
        setFeedbackOk(false);
        dispatch(AddCartItem(props.prodotto ?? props.schedaProdotto));
        dispatch(setDialogMessage("Articolo aggiunto correttamente nel carrello!"))
    }
    function isTemporaneo() {

        return (root?.LoginData?.role == "TEMPORANEO");
    }
    function Template(props: any) {
        const obj = props.obj;
        return (
            <Box key={props.index}>
                <Divider sx={{ fontWeight: 200, fontSize: 14, my: 1 }}>{obj.title}</Divider>
                {obj.desc &&
                    <TextDescription>
                        <div style={{ textDecoration: obj.lineThrough ? "line-through" : "none" }}
                            dangerouslySetInnerHTML={{ __html: obj?.value?.replaceAll("\n", "<BR>") }} />
                    </TextDescription>
                }
                {!obj.desc &&
                    <React.Fragment>
                        {obj.secondary == true &&
                            <TextTitleSecondary>
                                <div
                                    style={{ textDecoration: obj.lineThrough ? "line-through" : "none" }}
                                    dangerouslySetInnerHTML={{ __html: obj?.value?.replaceAll("\n", "<BR>") }} />
                            </TextTitleSecondary>
                        }
                        {(obj.secondary == null || obj.secondary == false) &&
                            <TextTitle>
                                <div
                                    style={{ textDecoration: obj.lineThrough ? "line-through" : "none" }}
                                    dangerouslySetInnerHTML={{ __html: obj?.value?.replaceAll("\n", "<BR>") }} />
                            </TextTitle>
                        }
                    </React.Fragment>
                }
            </Box>
        )
    }

    function Template_WOLF(props: any) {
        const obj = props.obj;
        return (
            <Box key={props.index} sx={{ textAlign: "left" }}>
                <TextTitle sx={{ opacity: .8 }}>
                    {obj.title}
                </TextTitle>
                {!obj.secondary &&
                    <TextDescription>
                        <div style={{ textDecoration: obj.lineThrough ? "line-through" : "none" }}
                            dangerouslySetInnerHTML={{ __html: obj?.value?.replaceAll("\n", "<BR>") }} />
                    </TextDescription>
                }
                {obj.secondary == true &&
                    <TextTitleSecondary>
                        <div
                            style={{ textDecoration: obj.lineThrough ? "line-through" : "none" }}
                            dangerouslySetInnerHTML={{ __html: obj?.value?.replaceAll("\n", "<BR>") }} />
                    </TextTitleSecondary>
                }
                <Divider sx={{ my: 1 }} />
            </Box>
        )
    }

    function GetFeeString(prodotto) {
        return ("" + currencyFormat((prodotto.offerta ?? prodotto.offerta) / 100 * prodotto.fee) + " (" + prodotto.fee + "%)");
    }

    const previousImage = () => {
        if (carouselCounter == 0) { }
        else {
            setCarouselCounter(carouselCounter - 1)
        }
    }

    const nextImage = () => {
        if (props.schedaProdotto.ProdottoToMedia.length == carouselCounter + 1) { }
        else {
            setCarouselCounter(carouselCounter + 1)
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={im}
                open={openTerminiAcquisto}
                onClose={() => {
                    if (props.onClose)
                        props.onClose();
                    setOpenTerminiAcquisto(false);
                }}
                scroll={scroll}
            >
                <DialogTitle id="scroll-dialog-title">Termini di Acquisto</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <div dangerouslySetInnerHTML={{ __html: condizione?.text?.replaceAll("\n", "<BR>") }} />
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={() => {
                        setOpenTerminiAcquisto(false);
                        if (props.onClose)
                            props.onClose();
                    }}>Annulla</Button>
                    {condizione?.guid_file != null &&
                        <Button variant={"outlined"} color={"secondary"} onClick={() => {
                            downloadMedia(condizione?.guid_file);
                        }}
                        >PDF</Button>
                    }
                    <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"secondary"} onClick={() => {
                        if (isTemporaneo()) {
                            setdialogTemporaneo("Prima di poter fare acquisti devi completare la registrazione." +
                                "<BR/>Verrai ora reindirizzato alla procedura guidata di registrazione.");
                            return false;
                        }
                        const servizioEsterno = (props.prodotto ?? props.schedaProdotto).servizio_esterno;
                        if (servizioEsterno) {
                            setFeedbackOk(false);
                            setServizioEsterno(servizioEsterno);
                        } else {
                            addToCart();
                        }
                    }}
                    >accetta e acquista</Button>
                </DialogActions>
            </Dialog>
            {props.schedaProdotto &&
                <Dialog
                    fullScreen={im}
                    open={openDettaglio}
                    onClose={() => {
                        setCarouselCounter(0)
                        if (props.onClose)
                            props.onClose();
                        setOpenDettaglio(false);
                    }}
                    scroll={scroll}
                >
                    <DialogTitle><TextTitle sx={{ textAlign: "left" }}>Scheda Prodotto</TextTitle></DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <ModalRichiediPrezzo setRichiediPrezzo={setRichiediPrezzo} richiediPrezzo={richiediPrezzo} />
                        <Tooltip title="clicca per vedere l'immagine">
                            <Box sx={{ position: 'relative' }}>
                                <IconButton sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    borderRadius: 0,
                                    display: props.schedaProdotto?.ProdottoToMedia?.length == 1 || carouselCounter == 0 ? 'none' : 'inherit'
                                }}
                                    onClick={previousImage}>
                                    <ChevronLeft />
                                </IconButton>
                                <CardMedia
                                    onClick={() => {
                                        setOpenImage(true);
                                    }}
                                    sx={{ cursor: "pointer", mb: 2, objectFit: 'contain' }}
                                    component="img"
                                    height="180"
                                    image={props.schedaProdotto.ProdottoToMedia ? getNewProdottoImmagine(props.schedaProdotto?.ProdottoToMedia[carouselCounter]) : ''}
                                />
                                <IconButton sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    borderRadius: 0,
                                    display: props.schedaProdotto.ProdottoToMedia.length == 1 || carouselCounter == props.schedaProdotto.ProdottoToMedia.length - 1 ? 'none' : 'inherit'
                                }}
                                    onClick={nextImage}>
                                    <ChevronRight />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        {
                            [
                                { title: "nome", value: props.schedaProdotto.nome, bold: true },
                                {
                                    title: "fornitore",
                                    value: (
                                        (fornitori?.find(x => x.email === props.schedaProdotto.email_fornitore)?.nome_attivita || "") ||
                                        (fornitori?.find(x => x.email === props.schedaProdotto.email_fornitore)?.ragione_sociale || "") ||
                                        "n/a"
                                    )
                                },
                                {
                                    title: "descrizione",
                                    desc: true,
                                    value: props.schedaProdotto.descrizione,
                                    small: true
                                },
                                {
                                    title: "prezzo",
                                    value: currencyFormat(props.schedaProdotto.prezzo) + " " + "*iva compresa",
                                    bold: true,
                                    secondary: props.schedaProdotto.offerta == null,
                                    lineThrough: props.schedaProdotto.offerta != null
                                },
                                {
                                    title: "offerta",
                                    secondary: props.schedaProdotto.offerta != null,
                                    value: currencyFormat(props.schedaProdotto.offerta) + " " + "*iva compresa",
                                    bold: true,
                                    lineThrough: false,
                                    hide: props.schedaProdotto.offerta == null
                                },
                                (root?.Tipologia !=
                                    "ENTERPRISE" ? {
                                    hide: rivenditore == false,
                                    title: "fee",
                                    value: GetFeeString(props.schedaProdotto)
                                } : null),
                            ].map((obj, index) => {
                                if (obj == null)
                                    return null
                                if (obj.hide == true)
                                    return null
                                return (
                                    <React.Fragment>
                                        {root.Dorsale && root.Dorsale.layout == "DEFAULT" &&
                                            <Template_WOLF obj={obj} index={index} />
                                        }
                                        {root.Dorsale && root.Dorsale.layout == "WOLF" &&
                                            <Template obj={obj} index={index} />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        {props.schedaProdotto.prezzo <= 0 &&
                            <Button
                                sx={{ whiteSpace: "nowrap" }}
                                variant={"outlined"} size={"large"} color={"primary"}
                                onClick={(e) => {
                                    if (setRichiediPrezzo)
                                        setRichiediPrezzo(props.schedaProdotto);
                                }}>
                                {props.schedaProdotto.richiedi_preventivo_t == null ? "richiedi preventivo" : props.schedaProdotto.richiedi_preventivo_t}
                            </Button>
                        }
                        <Button variant={"outlined"} size={"large"} color={"primary"}
                            onClick={(e) => {
                                if (props.onClose)
                                    setCarouselCounter(0)
                                props.onClose();
                                setOpenDettaglio(false);
                            }}>
                            annulla
                        </Button>
                        {props.schedaProdotto?.guid_file &&
                            <Button variant={"outlined"} size={"large"} color={"secondary"} onClick={async () => {
                                downloadMedia(props.schedaProdotto?.guid_file);
                            }}>
                                allegato
                            </Button>
                        }
                        {props.schedaProdotto.quantita > 0 && props.schedaProdotto.prezzo > 0 &&
                            <Button variant={"outlined"} size={"large"} color={"secondary"} onClick={async () => {
                                setCondizione(await DbCondizione({ guid: props.schedaProdotto.guid_condizione }, dispatch));
                                setCarouselCounter(0)
                                setOpenDettaglio(false);
                                setOpenTerminiAcquisto(true);
                            }}>
                                acquista
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            }
            {/*IMAGE Zoom*/}
            {props.schedaProdotto &&
                <Modal
                    open={openImage}
                    onClose={() => {
                        setOpenImage(false)
                    }}
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: im ? "90vw" : "auto",
                        bgcolor: 'background.paper',
                        p: 2,
                    }}>
                        <Box sx={{ position: 'relative' }}>
                            <IconButton sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                borderRadius: 0,
                                display: props.schedaProdotto?.ProdottoToMedia?.length == 1 || carouselCounter == 0 ? 'none' : 'inherit'
                            }}
                                onClick={previousImage}>
                                <ChevronLeft />
                            </IconButton>
                            <CardMedia
                                component="img"
                                height="600"
                                image={getNewProdottoImmagine(props.schedaProdotto?.ProdottoToMedia[carouselCounter])}
                            />
                            <IconButton sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                borderRadius: 0,
                                display: props.schedaProdotto.ProdottoToMedia.length == 1 || carouselCounter == props.schedaProdotto.ProdottoToMedia.length - 1 ? 'none' : 'inherit'
                            }}
                                onClick={nextImage}>
                                <ChevronRight />
                            </IconButton>
                        </Box>
                    </Box>
                </Modal>
            }
            <ServizioEsternoModal servizioEsterno={servizioEsterno} onAbort={() => {
                /*                setOpenDettaglio(false);
                 setOpenTerminiAcquisto(false);*/
                setServizioEsterno(null);
                setFeedbackOk(false);
            }} onClose={() => {
                addToCart();
            }} />
            {dialogTemporaneo != null &&
                <Dialog
                    fullScreen={im}
                    open={true}
                    onClose={() => {
                        setdialogTemporaneo(null);
                    }}
                    scroll={scroll}
                >
                    <DialogContent dividers={scroll === 'paper'}>
                        <Box>
                            <Box sx={{ mb: 1 }}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: dialogTemporaneo?.replaceAll("\n", "<BR>") }} />
                            </Box>
                            <Box>
                                <Button variant={"contained"} onClick={() => {
                                    navigate("/registrati/temporaneo/" + root?.LoginData?.email);
                                }}>Portami alla registrazione</Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>
    )
})