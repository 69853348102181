import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../Api";

export default function Bilancio(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            {/*<BaseGrid*/}
            {/*    getDataSourceAsync={Api.Bilancio.all}*/}
            {/*    onRowClick={(row) => {*/}
            {/*        navigate(`/bilancio/edit/${row.guid}/${row.email}`);*/}
            {/*    }}*/}
            {/*    onNew={() => {*/}
            {/*        navigate(`/bilancio/nuovo/inserisci`);*/}
            {/*    }}*/}
            {/*    headers={[*/}
            {/*        {*/}
            {/*            id: 'email',*/}
            {/*            label: 'email',*/}
            {/*            primary: true,*/}
            {/*            mobile: true,*/}
            {/*        },*/}
            {/*        {*/}
            {/*            id: 'ragione_sociale',*/}
            {/*            label: 'ragione_sociale',*/}
            {/*        },*/}
            {/*        {*/}
            {/*            id: 'importo',*/}
            {/*            label: 'importo',*/}
            {/*            mobile: true,*/}
            {/*            currency: true,*/}
            {/*        },*/}
            {/*        {*/}
            {/*            id: 'data',*/}
            {/*            label: 'data',*/}
            {/*            date: true,*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*/>*/}
        </React.Fragment>)
}