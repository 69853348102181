import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BaseGrid } from "../BaseGrid";
import Api from "../../Api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";

export default function StripeContainer(props) {

    const navigate = useNavigate();
    const PUBLIC_KEY = "pk_test_51KCOcXIoYN6MWq5ivdNANyyWWKfbH0g33QqAORwtrfw1cAFpKZnNMaRyebk1OkGnK0zi1Ayvon2ZF0HCw5eW46Tk00CGFx0HJL";
    const stripeTestPromise = loadStripe(PUBLIC_KEY);

    return (
        <Elements stripe={stripeTestPromise}>
            {props.children}
        </Elements>
    )
}