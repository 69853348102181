import React, { Component, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Api from "../Api";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import App from "./App";
// import App_WOLF from "./App_WOLF";
import { setDorsale } from "../reducers";

export default function Layout(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const root = useSelector((state: RootStateOrAny) => state);

	useEffect(() => {
		(async () => {
			dispatch(setDorsale(await Api.Dorsale.main(null, dispatch)));
		})();
	}, []);

	return (
		<React.Fragment>
			{root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) && <App />}
			{/* {root.Dorsale && root.Dorsale.layout == "WOLF" && <App_WOLF/>} */}
		</React.Fragment>
	);
}
