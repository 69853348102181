import React, {useEffect} from 'react';
import {DbHomeNumbers} from "../../Db";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import {HookIsMobile, UiContainer} from "../../utils/Helpers";
import {Box, Button, DialogProps, Divider} from "@mui/material";
import {kFormatter} from "../../utils/Functions";
import Moment from "moment";
import {useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Api from "../../Api";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import TextEllipsis from 'react-text-ellipsis';
import CountUp from 'react-countup';
import {TextDescription, TextTitle, TextTitleSecondarySmall} from "../../utils/Text";
import HomeProdotti from "./HomeProdottti";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {NewsDialog} from "../../components/news/NewsDialog";
import Banner from "../../components/banner/Banner";

export const HomeBox = React.memo(function HomeBoxFn(props: any) {
    const root = useSelector((state: RootStateOrAny) => state);
    const im = HookIsMobile();
    return (
        <Box className={"box"} style={props.style}
            sx={{ ...props.sx, p: (root.Dorsale && root.Dorsale.layout == "WOLF") ? im ? 0 : 2 : 2 }}>
            {props.title &&
                <Stack sx={{ mb: props.mb ?? 2 }} direction={"row"} alignItems={"center"} alignContent={"center"}
                    justifyContent={"space-between"}>
                    {props.title &&
                        <Box sx={{ fontSize: 15, fontWeight: 700, textTransform: "capitalize", opacity: .8 }}>
                            {props.title}
                        </Box>
                    }
                    {props.middle &&
                        <>
                            {props.middle}
                        </>
                    }
                    {props.action &&
                        <Button variant={"outlined"} size={"small"} color={"secondary"} sx={{}} onClick={() => {
                            if (props.onClick)
                                props.onClick();
                        }}>
                            {props.action}
                        </Button>
                    }
                </Stack>
            }
            {props.children}
        </Box>
    )
})
const HomeNumberBox = React.memo(function HomeNumberBoxFn(props: any) {
    const numberIcon: any = {
        fontSize: 50,
        opacity: 1,
        color: "secondary.main"
    };
    const item = props.homeNumbers[props.index];
    return (
        <HomeBox>
            <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} alignItems={"center"}>
                <Stack direction={"column"} spacing={1} justifyContent={"space-between"} alignContent={"start"}
                    alignItems={"start"}>
                    <Box sx={{ fontSize: 12, fontWeight: 500, textTransform: "capitalize", opacity: .8 }}>
                        {item.title}
                    </Box>
                    {item.type != "currency" &&
                        <CountUp start={0} duration={2} end={item.val} delay={0}>
                            {({ countUpRef }) => (
                                <div style={{ fontWeight: 700, fontSize: 18 }}>
                                    <span ref={countUpRef} />
                                </div>
                            )}
                        </CountUp>
                    }
                    {item.type == "currency" &&
                        <CountUp start={0}
                            formattingFn={(value: number) => {
                                return kFormatter(value);
                            }}
                            duration={2} end={item.val} delay={0}>
                            {({ countUpRef }) => {
                                return (
                                    <div style={{ fontWeight: 700, fontSize: 18 }}>
                                        <span ref={countUpRef} />
                                    </div>
                                );
                            }}
                        </CountUp>
                    }
                </Stack>
                {item.type == "product" &&
                    <CardGiftcardIcon sx={numberIcon} />
                }
                {item.type == "currency" &&
                    <MonetizationOnIcon sx={numberIcon} />
                }
                {item.type == "vendite" &&
                    <LocalGroceryStoreIcon sx={numberIcon} />
                }
                {item.type == "people" &&
                    <PeopleAltIcon sx={numberIcon} />
                }
            </Stack>
        </HomeBox>)
})
export default React.memo(function Home() {
    const dispatch = useDispatch();
    const [news, setNews] = React.useState<any>([]);
    const [settori, setSettori] = React.useState<any>([]);
    const [settoriSelected, setSettoriSelected] = React.useState<any>(null);
    const [homeNumbers, setHomeNumbers] = React.useState<any>(null);
    const [newsMacroCategorie, setnewsMacroCategorie] = React.useState<any>(null);
    const [currNews, setCurrNews] = React.useState<any>(null);
    const [video, setVideo] = React.useState<any>(null);
    const [macroSelected, setmacroSelected] = React.useState<any>(null);
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state)
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let dbSettori = (await Api.Settore.all({ mine: true }, dispatch))?.data;
            if (!dbSettori)
                dbSettori = [];
            dbSettori = [{ guid: "00000000-0000-0000-0000-000000000000", nome: "Generici" }].concat(dbSettori);
            setSettori(dbSettori);
            let dbMacroCategorie = await Api.NewsMacroCategoria.all(null, dispatch);
            if (dbMacroCategorie?.data == null)
                dbMacroCategorie = { data: [{ nome: "generiche" }] };
            else
                dbMacroCategorie.data.unshift({ nome: "generiche" });
            setnewsMacroCategorie(dbMacroCategorie);
            setHomeNumbers((await DbHomeNumbers(dispatch)));
            if (!dbSettori)
                setVideo((await Api.Video.all({ home: true }, dispatch)).data);
        })();
    }, [])
    useEffect(() => {
        (async () => {
            if (settori) {
                setSettoriSelected(settori);
            }
        })();
    }, [settori])
    useEffect(() => {
        (async () => {
            if (newsMacroCategorie && macroSelected == null) {
                setmacroSelected(newsMacroCategorie.data[0]);
            }
        })();
    }, [newsMacroCategorie])
    useEffect(() => {
        (async () => {
            if (settoriSelected && macroSelected) {
                setNews((await Api.News.all({
                    home: true,
                    take: 3,
                    macro: macroSelected?.guid,
                    generiche: macroSelected.guid == null,
                    settori: JSON.stringify(settoriSelected)
                }, dispatch))?.data);
                setVideo((await Api.Video.all({ home: true, settori: JSON.stringify(settoriSelected) }, dispatch)).data);
            }
        })();
    }, [settoriSelected, macroSelected])

    function Notizie(props) {
        return (
            <HomeBox {...props}
                     style={{position: "relative", height: im ? "auto" : 358}}
                     mb={0}
                     title={"ultime notizie"}
                     onClick={() => {
                         navigate("/vedi-tutte");
                     }}>
                <Notizie_Tabs
                    selected={macroSelected}
                    onChange={(x) => {
                        setmacroSelected(x);
                    }} />
                <Box sx={{
                    display: im ? "block" : "table",
                }}>
                    {news && news.map((n) => {
                        return (
                            <Box sx={{ display: im ? "block" : "table-row", verticalAlign: "top" }}>
                                <Box sx={{ display: "table-cell", p: .5, verticalAlign: "top" }}>
                                    <TextTitleSecondarySmall style={{ whiteSpace: "nowrap" }}>
                                        {Moment(n["data_ins"]).format('DD/MM/YY HH:mm')}
                                    </TextTitleSecondarySmall>
                                </Box>
                                <Box sx={{ display: im ? "block" : "table-cell", p: .3, pb: 1, verticalAlign: "top" }}>
                                    <Box sx={{
                                        cursor: "pointer",
                                    }} onClick={() => {
                                        setCurrNews(n);
                                    }}>
                                        <Box sx={{
                                            flexDirection: im ? "column" : "row",
                                            display: "flex",
                                        }}>
                                            <Box sx={{
                                                whiteSpace: "nowrap",
                                                color: n.colore,
                                                fontWeight: 700,
                                                fontSize: 14.5,
                                                mr: 1,
                                                textTransform: "uppercase"
                                            }}>
                                                {n.categoria ?? "generica"}
                                            </Box>
                                            <TextTitle>
                                                <TextEllipsis
                                                    lines={1}>
                                                    {n.titolo}
                                                </TextEllipsis>
                                            </TextTitle>
                                        </Box>
                                        <TextDescription sx={{ minHeight: 39 }}>
                                            <TextEllipsis
                                                lines={2}>
                                                {n.contenuto}
                                            </TextEllipsis>
                                        </TextDescription>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Button sx={{
                    textAlign: "right",
                    position: im ? "relative" : "absolute",
                    marginLeft: im ? 2 : 0,
                    marginTop: im ? 2 : 0,
                    bottom: 8,
                    right: 16
                }}
                        color={"secondary"} variant={"outlined"} size={"small"} onClick={() => {
                    navigate("/vedi-tutte/" + macroSelected.nome);
                }}>Vedi tutte</Button>
            </HomeBox>
        )
    }

    function Notizie_Tabs(props: any) {
        useEffect(() => {
            (async () => {
            })();
        }, [])
        return <Box sx={{
            overflowX: "auto",
            whiteSpace: im ? "normal" : "nowrap",
            py: 1,
        }}>
            {
                newsMacroCategorie?.data?.map((x, i) => {
                    return (
                        <Button
                            sx={{ my: 1, mr: 1, whiteSpace: "nowrap", minWidth: "max-content" }}
                            size={"small"} variant={"contained"}
                            color={props?.selected?.guid == x.guid ? "secondary" : "inherit"}
                            onClick={() => {
                                if (props.onChange)
                                    props.onChange(x);
                            }}>
                            {x.nome}
                        </Button>
                    )
                })
            }
        </Box>
    }

    function Notizie_WOLF(props) {
        return (
            <HomeBox {...props}
                     mb={0}
                     style={{position: "relative", height: im ? "auto" : 500}}
                     title={"ultime notizie"}
                     onClick={() => {
                         navigate("/vedi-tutte");
                     }}>
                <Box sx={{position: "relative"}}>
                    <Notizie_Tabs
                        selected={macroSelected}
                        onChange={(x) => {
                            setmacroSelected(x);
                        }} />
                    {news && news.map((n, i) => {
                        return (
                            <Box sx={{ minHeight: im ? "auto" : 120 }}>
                                <Stack direction={"row"} spacing={1}>
                                    <TextTitle sx={{ opacity: .7, whiteSpace: "nowrap" }}>
                                        {Moment(n["data_ins"]).format('DD/MM/YY')}
                                    </TextTitle>
                                    <TextTitle sx={{ opacity: .7, whiteSpace: "nowrap" }}>
                                        {"|"}
                                    </TextTitle>
                                    <TextTitle sx={{ opacity: .7, whiteSpace: "nowrap" }}>
                                        {Moment(n["data_ins"]).format('HH:mm')}
                                    </TextTitle>
                                    <TextTitle sx={{ opacity: .7, whiteSpace: "nowrap" }}>
                                        {"|"}
                                    </TextTitle>
                                    <TextTitle sx={{ opacity: .7 }}>
                                        <div style={{ color: n.colore }}>
                                            <TextEllipsis
                                                lines={1}>
                                                {n.categoria}
                                            </TextEllipsis>
                                        </div>
                                    </TextTitle>
                                </Stack>
                                <TextTitle
                                    onClick={() => {
                                        setCurrNews(n);
                                    }} sx={{ my: 1, cursor: "pointer" }}>
                                    <TextEllipsis
                                        lines={1}>
                                        {n.titolo}
                                    </TextEllipsis>
                                </TextTitle>
                                <TextDescription sx={{}}>
                                    <TextEllipsis
                                        lines={2}>
                                        {n.contenuto}
                                    </TextEllipsis>
                                </TextDescription>
                                <Box sx={{
                                    cursor: "pointer",
                                }} onClick={() => {
                                    setCurrNews(n);
                                }}>
                                </Box>
                                <Stack spacing={0} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    {i < 2 &&
                                        <Divider sx={{ mt: im ? 2 : 1, mb: im ? 2 : 0, width: "100%" }} />
                                    }
                                </Stack>
                            </Box>
                        )
                    })}
                </Box>
                <Button
                    sx={{
                        textAlign: "right",
                        position: im ? "relative" : "absolute",
                        bottom: im ? 0 : 16,
                        right: im ? 0 : 16,
                        mt: im ? 1 : 0,
                    }}
                    color={"secondary"} variant={"outlined"} size={"small"} onClick={() => {
                    navigate("/vedi-tutte/" + macroSelected.nome);
                }}>Vedi tutte</Button>
            </HomeBox>
        )
    }

    return (
        <React.Fragment>
            <UiContainer hideBox style={{ padding: 12, paddingTop: im ? 0 : 16 }}>
                <Stack direction={im ? "column" : "row"} justifyContent={"space-between"} alignContent={"center"}
                    spacing={1}
                    sx={{ pb: 2 }}>
                    <Box>
                        <Box component={"span"}
                            sx={{ fontWeight: 500, fontSize: 15, color: root?.GlobalTheme?.text_dsc_color }}>
                            {"Benvenuto "}
                        </Box>
                        <Box component={"span"} sx={{
                            fontWeight: 700,
                            fontSize: 15,
                            whiteSpace: "nowrap",
                            color: root?.GlobalTheme?.text_dsc_color
                        }}>
                            {root.LoginData?.name}
                        </Box>
                    </Box>
                    {settori && settori.length > 0 &&
                        <Autocomplete
                            multiple
                            size={"small"}
                            style={{ width: im ? "100%" : "50%" }}
                            options={settori}
                            isOptionEqualToValue={(option, value) => option.guid === value.guid}
                            getOptionLabel={(option: any) => option.nome}
                            value={settoriSelected ?? []}
                            onChange={(_, newValue) => {
                                setSettoriSelected(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    name={"settori"}
                                    label=""
                                />
                            )}
                        />
                    }
                </Stack>
                {homeNumbers &&
                    <Grid container alignItems={"stretch"} justifyContent={"stretch"} alignContent={"stretch"}
                        spacing={3}>
                        {root.Tipologia != "ENTERPRISE" && homeNumbers.map((hn, hmIndex) => {
                            return (
                                <Grid item xs={6} lg={3}>
                                    <HomeNumberBox homeNumbers={homeNumbers} index={hmIndex} />
                                </Grid>
                            )
                        })}
                        <Grid item xs={12} lg={7}>
                            {!im &&
                                <HomeBox title={""}>
                                    {video &&
                                        <Box sx={{}}>
                                            <iframe width="100%" height="340"
                                                src={`https://www.youtube.com/embed/${video[0].youtube_id}`}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen />
                                        </Box>
                                    }
                                </HomeBox>
                            }
                            {im &&
                                <div>
                                    {video &&
                                        <Box sx={{ mt: 1 }} className={"video-container"}>
                                            <iframe width="100%" height="100%"
                                                src={`https://www.youtube.com/embed/${video[0].youtube_id}`}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen />
                                        </Box>
                                    }
                                </div>
                            }
                            {root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) &&
                                <Notizie sx={{ mt: 3 }} />}
                            {root.Dorsale && root.Dorsale.layout == "WOLF" && <Notizie_WOLF sx={{ mt: 3 }} />}
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <HomeProdotti layout={root?.Dorsale?.layout} settori={settoriSelected} />
                        </Grid>
                    </Grid>
                }
            </UiContainer>
            {currNews &&
                <NewsDialog currNews={currNews} setCurrNews={setCurrNews} />
            }
            <Box sx={{ mt: 2 }}>
                <Banner />
            </Box>
        </React.Fragment>
    );
})