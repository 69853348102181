import React, { useEffect } from 'react';
import {
    showInfo,
} from "../../../reducers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer, UiFileUploader
} from "../../../utils/Helpers";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, FormControlLabel, MenuItem } from "@mui/material";
import Api from "../../../Api";
import Checkbox from "@mui/material/Checkbox";

export default React.memo(function BannerEdit() {

    let { guid } = useParams<any>();

    const [item, setItem] = React.useState<any>(null);
    const [fileImmagine, setFileImmagine] = React.useState<any>(null);
    const [mediaImmagine, setMediaImmagine] = React.useState<any>(null);
    const [imageToDelete, setImageToDelete] = React.useState<any>(null);

    const [settori, setSettori] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        (async () => {
            setSettori(await Api.Settore.all(null, dispatch));
            await reload();
        })();
    }, [])

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };

    async function reload() {
        if (guid != null) {
            const dbBanner = await Api.Banner.all({ guid: guid }, dispatch);
            setItem(dbBanner);
            if (dbBanner?.guid_immagine)
                setMediaImmagine(await Api.Media.all({ guid: dbBanner.guid_immagine }, dispatch));
        } else
            setItem({});
    }


    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"gestione banners"}>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.guid ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="nome"
                                    label="nome"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.nome ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="image_url"
                                    label="image_url"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.image_url ?? ""}
                                    onBlur={handleChange}
                                />
                                <a target={"_blank"} href={item?.image_url ?? ""}>anteprima</a>
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"carica immagine"}
                                    media={mediaImmagine}
                                    onDelete={(e) => {
                                        setImageToDelete(e);
                                    }}
                                    onChange={(e) => {
                                        setFileImmagine(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="link"
                                    label="link"
                                    fullWidth
                                    required
                                    type="link"
                                    defaultValue={item?.link ?? ""}
                                    onBlur={handleChange}
                                />
                                <a target={"_blank"} href={item?.link ?? ""}>anteprima</a>
                            </UiFrmControl>
                            <UiFrmControl>
                                {settori &&
                                    <TextField
                                        name="guid_settore"
                                        disabled={false}
                                        select
                                        fullWidth
                                        value={item?.guid_settore ?? ""}
                                        label="settore"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>
                                            NESSUNO
                                        </MenuItem>
                                        {settori.data.map((option) => (
                                            <MenuItem key={option.guid} value={option.guid}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            </UiFrmControl>
                            <UiFrmControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item?.banner_in_evidenza ?? false}
                                            name={"banner_in_evidenza"}
                                            onChange={handleChangeCheckbox}
                                        />}
                                    label={"in evidenza"} />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                onClick={async () => {
                                    if (!window.confirm("confermi"))
                                        return false;
                                    if (await Api.Banner.del(item, dispatch)) {
                                        dispatch(showInfo("il record è stato eliminato correttamente"));
                                        navigate(-1);
                                    }
                                }}>
                                <DeleteIcon />
                            </Fab>
                            <Fab color="secondary"
                                onClick={async (e) => {
                                    if (await Api.Banner.save(item, [
                                        {
                                            name: "fileImmagine",
                                            value: fileImmagine,
                                        },
                                        {
                                            name: "imageToDelete",
                                            guid: imageToDelete,
                                        }], dispatch)) {
                                        dispatch(showInfo("il record è stato modificato correttamente"));
                                        navigate(-1);
                                    }
                                    return false;
                                }}
                            >
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})