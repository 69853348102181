import { createSlice, isFulfilled, isPending } from '@reduxjs/toolkit'

const initialState: any = {
    isLogged: false,
    LoginData: {},
    MESSAGE: "",
    SEVERITY: "success",
    CartItems: [],
    AddCartItem: {},
    Numbers: [],
    Filters: null,
    ValidationName: null,
    ValidationText: null,
    Credito: 0,
    Tipologia: null,
    ShowLoading: false,
    MenuToOpen: null,
    LoadingMessage: null,
    GlobalTheme: null,
    DialogMessage: null,
    Labels: null,
    Dorsale: null,
};
export const counterSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        setDorsale: (state, action) => {
            return { ...state, Dorsale: action.payload };
        },
        setLabels: (state, action) => {
            return { ...state, Labels: action.payload };
        },
        setGlobalTheme: (state, action) => {
            return { ...state, GlobalTheme: action.payload };
        },
        setLoadingMessage: (state, action) => {
            return { ...state, LoadingMessage: action.payload };
        },
        setDialogMessage: (state, action) => {
            return { ...state, DialogMessage: action.payload };
        },
        setMenuToOpen: (state, action) => {
            return { ...state, MenuToOpen: action.payload };
        },
        setShowLoading: (state, action) => {
            return { ...state, ShowLoading: action.payload };
        },
        setCredito: (state, action) => {
            return { ...state, Credito: action.payload };
        },
        setTipologia: (state, action) => {
            return { ...state, Tipologia: action.payload };
        },
        setRootFilters: (state, action) => {
            return { ...state, Filters: action.payload };
        },
        setIsLogged: (state, action) => {
            return { ...state, isLogged: action.payload.email != null, LoginData: action.payload };
        },
        clearMessage: (state, action) => {
            return { ...state, MESSAGE: "" };
        },
        setMessage: (state, action) => {
            return { ...state, MESSAGE: action.payload };
        },
        showError: (state, action) => {
            return { ...state, MESSAGE: action.payload, SEVERITY: "error" };
        },
        showInfo: (state, action) => {
            return { ...state, MESSAGE: action.payload, SEVERITY: "info" };
        },
        setCartItems: (state, action) => {
            return { ...state, CartItems: action.payload };
        },
        AddCartItem: (state, action) => {
            return { ...state, CartItems: [...state.CartItems, action.payload] };
        },
        RemoveCartItem: (state, action) => {
            var array = [...state.CartItems];
            array = array.filter(x => x.sku != action.payload.sku);
            localStorage.setItem("CartItems", JSON.stringify(array));
            return { ...state, CartItems: array };
        },
        ClearCartItems: (state, action) => {
            return { ...state, CartItems: [] };
        },
        SetNumbers: (state, action) => {
            if (state.Numbers.filter(x => x.id == action.payload.id).length > 0)
                state.Numbers.filter(x => x.id == action.payload.id)[0].value = action.payload.value;
            else
                return { ...state, Numbers: [...state.Numbers, { value: action.payload.value, id: action.payload.id }] };
        },
        SetNumbersAll: (state, action) => {
            return { ...state, Numbers: action.payload };
        },
        setValidationName: (state, action) => {
            return { ...state, ValidationName: action.payload };
        },
        setValidationText: (state, action) => {
            return { ...state, ValidationText: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPending, (state, action) => {
                /*   state.LOADING = true;*/
                /*         document.body.style.overflow = "hidden";*/
            })
            .addMatcher(isFulfilled, (state, action) => {
                /* state.LOADING = false;*/
                /*           document.body.style.overflow = "auto";*/
                if ((action.payload as any)?.success != undefined) {
                    state.SEVERITY = "success";
                    state.MESSAGE = (action.payload as any).success;
                } else if ((action.payload as any)?.warning != undefined) {
                    state.SEVERITY = "warning";
                    state.MESSAGE = (action.payload as any).warning;
                } else if ((action.payload as any)?.error != undefined) {
                    state.SEVERITY = "error";
                    state.MESSAGE = (action.payload as any).error;
                } else if ((action.payload as any)?.info != undefined) {
                    state.SEVERITY = "info";
                    state.MESSAGE = (action.payload as any).info;
                }
            });
    },
});
export const {
    setDorsale,
    setLabels,
    setDialogMessage,
    setGlobalTheme,
    setLoadingMessage,
    setMenuToOpen,
    setShowLoading,
    setTipologia,
    setCredito,
    setValidationText,
    setValidationName,
    setRootFilters,
    setCartItems,
    RemoveCartItem,
    AddCartItem,
    ClearCartItems,
    showError,
    showInfo,
    setIsLogged,
    clearMessage,
    setMessage,
    SetNumbers,
    SetNumbersAll
} = counterSlice.actions;
export default counterSlice.reducer;