import React, {useEffect} from 'react';

import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";


import RefreshIcon from '@mui/icons-material/Refresh';
import {MenuItem} from "@mui/material";
import Api from "../../../../Api";
import {DbCategoria} from "../../../../Db";
import {
    PageHeader,
    UiContainer,
    UiFabContainer,
    UiFileUploader,
    UiFormInfo,
    UiFrmControl,
    UiPaper,
    UiSkeletonForm
} from "../../../../utils/Helpers";
import { getLabel } from '../../../../utils/Functions';
import {showInfo} from "../../../../reducers";

export default React.memo(function SottoCategoriaEdit() {

    let {guid} = useParams<any>();

    const [item, setItem] = React.useState<any>(null);
    const [categorie, setCategorie] = React.useState<any>(null);

    const [fileImmagine, setFileImmagine] = React.useState<any>(null);
    const [imageToDelete, setImageToDelete] = React.useState<any>(null);
    const [media, setMedia] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        if (guid != "")
            setItem(await Api.SottoCategoria.all({ guid: guid }, dispatch));
        else
            setItem({});
    }

    useEffect(() => {
        (async () => {
            if (item) {
                if (item.guid_immagine)
                    setMedia(await Api.Media.all({ guid: item.guid_immagine }, dispatch));
            }
        })();
    }, [item])

    useEffect(() => {
        (async () => {
            setCategorie((await DbCategoria(null, dispatch))?.data);
            await reload();
        })();
    }, [])

    return (
        <React.Fragment>
            {/*                        {JSON.stringify(validation)}*/}
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <UiFrmControl component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.guid ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    error={root?.ValidationName == "nome"}
                                    helperText={root?.ValidationName == "nome" ? root?.ValidationText : null}
                                    name="nome"
                                    label="nome"
                                    fullWidth
                                    type="text"
                                    defaultValue={item?.nome ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    fullWidth
                                    select
                                    error={root?.ValidationName == "guid_categoria"}
                                    helperText={root?.ValidationName == "guid_categoria" ? root?.ValidationText : null}
                                    label={getLabel("categoria", root)}
                                    name="guid_categoria"
                                    SelectProps={{ style: { fontWeight: 700 } }}
                                    value={item?.guid_categoria ?? ""}
                                    onChange={handleChange}
                                >
                                    {categorie == null &&
                                        <MenuItem></MenuItem>
                                    }
                                    {categorie != null && categorie.map((option) => (
                                        <MenuItem key={option.guid} value={option.guid}>
                                            {option.nome}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="posizione"
                                    label={"posizione"}
                                    fullWidth
                                    type="posizione"
                                    value={item?.posizione ?? 0}
                                    onChange={handleChange}
                                    inputProps={{
                                        step: "1"
                                    }}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    media={media}
                                    onDelete={(e) => {
                                        setImageToDelete(e);
                                    }}
                                    onChange={(e) => {
                                        setFileImmagine(e);
                                    }} />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFormInfo item={item} />

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                 onClick={async () => {
                                     if (!window.confirm("confermi"))
                                         return false;
                                     if (await Api.SottoCategoria.del(item, dispatch)) {
                                         dispatch(showInfo("il record è stato eliminato correttamente"));
                                         navigate(-1);
                                     }
                                 }}>
                                <DeleteIcon/>
                            </Fab>
                            <Fab color="secondary"
                                 onClick={async (e) => {
                                     if (await Api.SottoCategoria.save(item, [
                                         {
                                             name: "fileImmagine",
                                             value: fileImmagine,
                                         },
                                         {
                                             name: "imageToDelete",
                                             guid: imageToDelete
                                         }
                                     ], dispatch)) {
                                         dispatch(showInfo("il record è stato modificatao correttamente"));
                                         navigate(-1);
                                     }
                                     return false;
                                 }}
                            >
                                <SaveIcon/>
                            </Fab>
                        </UiFabContainer>

                    </UiFrmControl>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})