import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiContainer, UiFileUploader, UiColorPicker
} from "../../../utils/Helpers";
import { setGlobalTheme, setLoadingMessage, setTipologia, showInfo } from "../../../reducers";
import Api from "../../../Api";
import { FormControlLabel, MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export default React.memo(function ManifestEdit() {

    const [fileFavIcon, setFileFavIcon] = React.useState<any>(null);
    const [mediaFavIcon, setMediaFavIcon] = React.useState<any>(null);

    const [fileTouchIcon, setFileTouchIcon] = React.useState<any>(null);
    const [mediaTouchIcon, setMediaTouchIcon] = React.useState<any>(null);

    const [fileIcon192, setFileIcon192] = React.useState<any>(null);
    const [mediaIcon192, setMediaIcon192] = React.useState<any>(null);

    const [fileIcon512, setFileIcon512] = React.useState<any>(null);
    const [mediaIcon512, setMediaIcon512] = React.useState<any>(null);

    const [manifest, setManifest] = React.useState<any>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            var dbManifest = await Api.Manifest.all(null, dispatch);
            setManifest(dbManifest);
            setMediaFavIcon(await Api.Media.all({ guid: dbManifest.favicon_guid }, dispatch));
            setMediaTouchIcon(await Api.Media.all({ guid: dbManifest.touchicon_guid }, dispatch));
            setMediaIcon192(await Api.Media.all({ guid: dbManifest.icon192_guid }, dispatch));
            setMediaIcon512(await Api.Media.all({ guid: dbManifest.icon512_guid }, dispatch));
        })();
    }, [])

    const handleChange = (event: any) => {
        setManifest({
            ...manifest,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setManifest({
            ...manifest,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <UiContainer>
                {manifest &&
                    <React.Fragment>
                        <UiPaper first title={""}>
                            <UiFrmControl first>
                                <UiFileUploader
                                    image={true}
                                    label={"favicon"}
                                    media={mediaFavIcon}
                                    onChange={(e) => {
                                        setFileFavIcon(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"touch icon"}
                                    media={mediaTouchIcon}
                                    onChange={(e) => {
                                        setFileTouchIcon(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"icon 192"}
                                    media={mediaIcon192}
                                    onChange={(e) => {
                                        setFileIcon192(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"icon 512"}
                                    media={mediaIcon512}
                                    onChange={(e) => {
                                        setFileIcon512(e);
                                    }} />
                            </UiFrmControl>
                        </UiPaper>
                    </React.Fragment>
                }
            </UiContainer>
            <UiContainer hideBox>
                <UiFabContainer>
                    <Fab color="secondary"
                        onClick={async (e) => {
                            dispatch(setLoadingMessage("salvataggio in corso..."));
                            if (await Api.Manifest.save(manifest, [
                                {
                                    name: "fileFavIcon",
                                    value: fileFavIcon,
                                },
                                {
                                    name: "fileTouchIcon",
                                    value: fileTouchIcon,
                                },
                                {
                                    name: "fileIcon192",
                                    value: fileIcon192,
                                },
                                {
                                    name: "fileIcon512",
                                    value: fileIcon512,
                                },
                            ], dispatch)) {
                                window.location.reload();
                            }
                            dispatch(setLoadingMessage(null));
                        }}
                    >
                        <SaveIcon />
                    </Fab>
                </UiFabContainer>
            </UiContainer>
        </React.Fragment>
    );
})