import React, { useEffect } from 'react';
import {
    Box, Button,
    CardHeader, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider, MenuItem, Modal,
    Stack, Tooltip,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DbAnagrafica, DbCondizione } from "../../Db";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Chip from "@mui/material/Chip";
import { currencyFormat, downloadMedia, getProdottoImmagine, IsNullOrEmpty, isSmallScreen, truncate } from "../../utils/Functions";
import { AddCartItem, setLoadingMessage } from "../../reducers";
import { HookIsMobile, UiContainer, UiFabContainer } from "../../utils/Helpers";
import CartProdottiFilters from "./CartProdottiFilters";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Banner from '../../components/banner/Banner';
import Api from "../../Api";
import { v4 as uuidv4 } from 'uuid';
import CartProdottiAcquista from "./CartProdottiAcquista";
import TextEllipsis from 'react-text-ellipsis';
import {
    TextDescription,
    TextErrorSmall,
    TextNormaBold,
    TextTitle,
    TextTitleSecondarySmall,
    TextTitleSmall
} from "../../utils/Text";
import TextField from "@mui/material/TextField";
import { HomeProdottiItem } from '../../pages/home/HomeProdottti';

export default function CartProdottiItem_WOLF(this: { path: string; component: () => JSX.Element; }, props: any) {

    const im = HookIsMobile();
    const data = props.data;
    const root = useSelector((state: RootStateOrAny) => state);

    return (
        <HomeProdottiItem
            style={{ width: im ? "100%" : "50%" }}
            pIndex={props.pIndex}
            onZoom={(p) => {
                props.setSchedaProdotto(p);
            }}
            onAddToCart={(p) => {
                props.setProdotto(p);
            }}
            prodotti={props.prodotti}
            prodotto={props.p} />
    )
}
