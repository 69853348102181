import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiContainer, UiFileUploader, UiColorPicker
} from "../../../utils/Helpers";
import { setGlobalTheme, setLoadingMessage, setTipologia, showInfo } from "../../../reducers";
import Api from "../../../Api";
import { FormControlLabel, MenuItem } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export default React.memo(function ThemeEdit() {

    const [fileLogo, setFileLogo] = React.useState<any>(null);
    const [mediaLogo, setMediaLogo] = React.useState<any>(null);
    const [fileLogo2, setFileLogo2] = React.useState<any>(null);
    const [fileLogo3, setFileLogo3] = React.useState<any>(null);
    const [fileLogo2Delete, setFileLogo2Delete] = React.useState<any>(null);
    const [fileLogo3Delete, setFileLogo3Delete] = React.useState<any>(null);
    const [mediaLogo2, setMediaLogo2] = React.useState<any>(null);
    const [mediaLogo3, setMediaLogo3] = React.useState<any>(null);
    const [dorsale, setDorsale] = React.useState<any>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            var dbDorsale = await Api.Dorsale.all(null, dispatch);
            setDorsale(dbDorsale);
            setMediaLogo(await Api.Media.all({ guid: dbDorsale.guid_logo }, dispatch));
            setMediaLogo2(await Api.Media.all({ guid: dbDorsale.guid_logo2 }, dispatch));
            setMediaLogo3(await Api.Media.all({ guid: dbDorsale.guid_logo3 }, dispatch));
        })();
    }, [])

    const handleChange = (event: any) => {
        setDorsale({
            ...dorsale,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDorsale({
            ...dorsale,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <React.Fragment>
            <UiContainer>
                {dorsale &&
                    <React.Fragment>
                        <UiPaper padding title={""}>
                            <UiFrmControl first>
                                <TextField
                                    name="google_font"
                                    label="google font"
                                    fullWidth
                                    type="text"
                                    defaultValue={dorsale?.google_font ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="font_family"
                                    label="font family"
                                    fullWidth
                                    type="text"
                                    defaultValue={dorsale?.font_family ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="color_primary"
                                    label="colore primario"
                                    defaultValue={dorsale?.color_primary ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="color_secondary"
                                    label="colore secondario"
                                    defaultValue={dorsale?.color_secondary ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="main_bgcolor"
                                    label="background color"
                                    defaultValue={dorsale?.main_bgcolor ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                        </UiPaper>
                        <UiPaper padding title={"logo"}>
                            <UiFrmControl first>
                                <UiFileUploader
                                    image={true}
                                    label={"carica logo"}
                                    media={mediaLogo}
                                    onDelete={(e) => {
                                    }}
                                    onChange={(e) => {
                                        setFileLogo(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="logo_py"
                                    label="logo padding y"
                                    fullWidth
                                    type="text"
                                    defaultValue={dorsale?.logo_py ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="logo_px"
                                    label="logo padding x"
                                    fullWidth
                                    type="text"
                                    defaultValue={dorsale?.logo_px ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="logo_bgcolor"
                                    label="logo background"
                                    defaultValue={dorsale?.logo_bgcolor ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"carica logo secondario"}
                                    media={mediaLogo2}
                                    onDelete={(e) => {
                                        setFileLogo2Delete(e);
                                    }}
                                    onChange={(e) => {
                                        setFileLogo2(e);
                                    }} />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    image={true}
                                    label={"carica logo terziario"}
                                    media={mediaLogo3}
                                    onDelete={(e) => {
                                        setFileLogo3Delete(e);
                                    }}
                                    onChange={(e) => {
                                        setFileLogo3(e);
                                    }} />
                            </UiFrmControl>
                        </UiPaper>
                        <UiPaper padding title={"menu"}>
                            <UiFrmControl>
                                <TextField
                                    select
                                    fullWidth
                                    label="theme_mode"
                                    name="theme_mode"
                                    value={dorsale?.theme_mode ?? ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"DARK"}>DARK</MenuItem>
                                    <MenuItem value={"LIGHT"}>LIGHT</MenuItem>
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="menu_bgcolor"
                                    label="menu_bgcolor"
                                    defaultValue={dorsale?.menu_bgcolor ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="submenu_bgcolor"
                                    label="submenu_bgcolor"
                                    defaultValue={dorsale?.submenu_bgcolor ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                        </UiPaper>
                        <UiPaper padding title={"Overrides"}>
                            <UiFrmControl first>
                                <UiColorPicker
                                    name="text_normal_color"
                                    label="colore testo"
                                    defaultValue={dorsale?.text_normal_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="text_title_color"
                                    label="colore titolo"
                                    defaultValue={dorsale?.text_title_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="text_title_small_color"
                                    label="colore titolo small"
                                    defaultValue={dorsale?.text_title_small_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="text_dsc_color"
                                    label="colore descrizioni"
                                    defaultValue={dorsale?.text_dsc_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="button_chip_color"
                                    label="colore testo bottoni"
                                    defaultValue={dorsale?.button_chip_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="caption_color"
                                    label="caption color"
                                    defaultValue={dorsale?.caption_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiColorPicker
                                    name="input_color"
                                    label="input color"
                                    defaultValue={dorsale?.input_color ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <FormControlLabel control={<Checkbox checked={dorsale?.color_reverse ?? false}
                                    name="color_reverse"
                                    onChange={handleChangeCheckbox} />} label="inverti colori bottoni" />
                            </UiFrmControl>
                        </UiPaper>
                    </React.Fragment>
                }
            </UiContainer>
            <UiContainer hideBox>
                <UiFabContainer>
                    <Fab color="secondary"
                        onClick={async (e) => {
                            dispatch(setLoadingMessage("salvataggio in corso..."));
                            if (await Api.Dorsale.save(dorsale, [
                                {
                                    name: "fileLogo",
                                    value: fileLogo,
                                },
                                {
                                    name: "fileLogo2",
                                    value: fileLogo2,
                                },
                                {
                                    name: "fileLogo3",
                                    value: fileLogo3,
                                },
                                {
                                    name: "fileLogo2Delete",
                                    value: fileLogo2Delete,
                                },
                                {
                                    name: "fileLogo3Delete",
                                    value: fileLogo3Delete,
                                },
                            ], dispatch)) {
                                window.location.reload();
                            }
                            dispatch(setLoadingMessage(null));
                        }}
                    >
                        <SaveIcon />
                    </Fab>
                </UiFabContainer>
            </UiContainer>
        </React.Fragment>
    );
})