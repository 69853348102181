import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BaseGrid } from '../BaseGrid';
import Api from "../../Api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { Box } from "@mui/material";
import { TextNormaBold } from "../../utils/Text";
import { UiContainer } from "../../utils/Helpers";

export default function StripeOkOffline(props) {

    return (
        <UiContainer>
            <TextNormaBold>
                Grazie per il tuo ordine.
            </TextNormaBold>
        </UiContainer>
    )
}