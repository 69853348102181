import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogProps,
    Stack,
    TextField,
    Grid,
    Typography,
    InputAdornment,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { DbLogin } from "../../Db";
import { useNavigate } from "react-router-dom";
import { HookIsMobile } from "../../utils/Helpers";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Api from "../../Api";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { AxiosError } from 'axios'
import { reportError } from "ajv/dist/compile/errors";
import { Simulate } from "react-dom/test-utils";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export default function Login(props) {
    const [login, setLogin] = React.useState<any>(null);
    const [open, setOpen] = React.useState<any>(false);
    const [dorsaleMain, setDorsaleMain] = React.useState<any>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const [scroll] = React.useState<DialogProps['scroll']>('body');
    const handleChange = (event: any) => {
        setLogin({
            ...login,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(() => {
        (async () => {
            setDorsaleMain(await Api.Dorsale.main(null, dispatch));
        })();
        setOpen(true);
    }, [])
    const useStyles = makeStyles({
        dialog: {
            position: 'absolute',
            left: "5%",
        }
    });
    const BackdropStyles = makeStyles({
        root: {
            backgroundColor: "transparent"
        },
        paper: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden"
        }
    });
    const classes = useStyles();
    const classesBackdrop = BackdropStyles();

    const [openModal, setOpenModal] = useState(false);
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState<string | null>(null);

    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(true);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);

        setEmail('');

        setResetPasswordError(null);
        setResetPasswordSuccess(false);

        if (resetPasswordSuccess) {
            setEmail('');
            setResetPasswordError(null);
            setResetPasswordSuccess(false);
        }


    };

    const handleModalCloseMessageComplete = () => {
        setOpenModal(false);




    };

    const handleResetPassword = async () => {
        if (!email) {
            setResetPasswordError("Il campo email � obbligatorio.");
            return;
        }

        const entity = {
            email: email,
        };
        Api.Dorsale.ResetPassword(entity, dispatch)
            .then((response: any) => {
                handleCloseModal();
                setResetPasswordSuccess(true);
                setEmail('');
                setResetPasswordError(null);
            })
            .catch((e: AxiosError) => {
                console.log(e.response?.data)
                setResetPasswordError(e.response?.data);
            });
    };

    const handleResetPassword3 = async () => {
        try {
            if (!email) {
                setResetPasswordError("Il campo email � obbligatorio.");
                return;
            }

            const entity = {
                email: email,
            };

            await Api.Dorsale.ResetPassword(entity, dispatch);
            handleCloseModal();
            setResetPasswordSuccess(true);
            setEmail('');
            setResetPasswordError(null);
        } catch (error) {
            console.error(error);
            setResetPasswordError("L'email inserita non e' presente a sistema");
        }
    };






    return (
        <React.Fragment>
            <Box sx={{
                width: "100vw",
                height: "100vh",
                backgroundImage: "url('/db/dorsale/loginimage')",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }} />
            <Dialog
                BackdropProps={{
                    classes: {
                        root: classesBackdrop.root
                    }
                }
                }
                open={true}
                fullScreen={im}
                onClose={() => {
                    setOpen(false)
                }}
                fullWidth
                maxWidth={"xs"}
                classes={{
                    paper: !im ? classes.dialog : ""
                }}
                scroll={scroll}
            >
                <DialogContent style={{ overflow: "hidden" }}>
                    <Stack component={"form"}
                        noValidate
                        autoComplete="off"
                        sx={{ p: im ? 0 : 5, height: "100%" }}
                        direction={"column"}
                        spacing={2}
                        justifyContent={"space-between"}
                        alignItems={"stretch"}>
                        {dorsaleMain.guid_logo2 &&
                            <Box sx={{ textAlign: "center" }}>
                                <img width={"300px"} src={"/db/dorsale/logoimage2"} />
                            </Box>
                        }
                        <Stack spacing={1} justifyContent={"center"} alignItems={"center"} direction={"row"}
                            sx={{ fontWeight: 500, whiteSpace: "nowrap", fontSize: 16, pb: 4, textAlign: "center" }}>
                            <Box>
                                Benvenuto in
                            </Box>
                            <img width={"120px"} src={"/db/dorsale/logoimage"} />
                        </Stack>
                        <Box sx={{ pb: 3 }}>
                            <TextField
                                name={"email"}
                                sx={{ mb: 3, width: "-webkit-fill-available" }}
                                label="email"
                                onChange={handleChange}
                                variant="standard"
                            />
                            <TextField
                                name={"password"}
                                sx={{ mb: 0, width: "-webkit-fill-available" }}
                                label="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                variant="standard"
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePassword}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Stack sx={{ width: "100%", py: 0 }} direction={"row"}
                            spacing={2}
                            justifyContent={"space-between"} alignItems={"center"}>
                            <Stack sx={{ position: "relative", top: 0, left: -10 }} direction={"row"} spacing={0}
                                justifyContent={"start"} alignItems={"center"}>
                                <Checkbox size={"small"} defaultChecked />
                                <Box sx={{ fontSize: 12, fontWeight: 300 }}>Ricordami</Box>
                            </Stack>
                            <Link sx={{ fontWeight: 300, fontSize: 13, whiteSpace: 'nowrap' }} href={'#'} onClick={handleOpenModal}>
                                Password dimenticata?
                            </Link>
                            <Dialog open={openModal} onClose={handleCloseModal}>
                                <DialogContent>
                                    <Stack spacing={2}>
                                        <TextField
                                            label="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                        {resetPasswordError && (
                                            <Typography variant="body1" color="error">{resetPasswordError}</Typography>
                                        )}
                                        <Button
                                            type="submit"
                                            sx={{ width: '100%', my: 3, p: 2 }}
                                            color="secondary"
                                            variant="contained"
                                            size="large"
                                            onClick={handleResetPassword}
                                            disabled={!email}
                                        >
                                            Reset Password
                                        </Button>
                                    </Stack>
                                </DialogContent>
                            </Dialog>


                            {resetPasswordSuccess && (
                                <Dialog open={resetPasswordSuccess} onClose={() => setResetPasswordSuccess(false)}>
                                    <DialogContent>
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="h6">Mail Recupero Password Inviata</Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={() => {
                                                        setResetPasswordSuccess(false);
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Typography variant="body1">E' stata inviata la mail per recuperare la password. Ricordati di controllare anche nella cartella spam!</Typography>
                                    </DialogContent>
                                </Dialog>
                            )}
                        </Stack>
                        <Stack direction={"column"} spacing={0} justifyContent={"stretch"} alignItems={"stretch"}>
                            <Button type="submit" sx={{ width: "100%", my: 3, p: 2 }} color={"secondary"}
                                variant={"contained"}
                                size={"large"}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if ((await DbLogin(login, dispatch)) != null) {
                                        /*                     window.location.href = "/";*/
                                        window.location.reload();
                                    }
                                    return false;
                                }}>
                                Entra
                            </Button>
                            <Button type="submit" sx={{width: "100%", p: 2}} color={"secondary"}
                                    variant={"outlined"}
                                    size={"large"}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        navigate("/registrati/cliente")
                                        return false;
                                    }}>
                                Registrati ora
                            </Button>
                        </Stack>
                        <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"}>
                            {dorsaleMain.yt &&
                                <IconButton color={"primary"} onClick={async () => {
                                    window.open(dorsaleMain.yt);
                                }}>
                                    <YouTubeIcon />
                                </IconButton>
                            }
                            {dorsaleMain.fb &&
                                <IconButton color={"primary"} onClick={async () => {
                                    window.open(dorsaleMain.fb);
                                }}>
                                    <FacebookOutlinedIcon />
                                </IconButton>
                            }
                            {dorsaleMain._in &&
                                <IconButton color={"primary"} onClick={async () => {
                                    window.open(dorsaleMain._in);
                                }}>
                                    <LinkedInIcon />
                                </IconButton>
                            }
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

        </React.Fragment>
    )
}
