import React, {useEffect} from 'react';
import {
    Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle,
    ListItemIcon,
    ListSubheader,
    Stack, ThemeProvider
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import DescriptionIcon from "@mui/icons-material/Description";
import SellIcon from "@mui/icons-material/Sell";
import {useNavigate, useLocation} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Chip from "@mui/material/Chip";
import {setMenuToOpen, SetNumbers, setRootFilters, setShowLoading} from "../../reducers";
import {getLabel, Log, reloadNumbers} from "../../utils/Functions";
import RssFeedIcon from '@mui/icons-material/RssFeed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Paper from "@mui/material/Paper";
import ListItemButton from '@mui/material/ListItemButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import {HookIsMobile} from "../../utils/Helpers";
import Api from "../../Api";
import {createTheme} from "@mui/material/styles";
import {theming} from "../../Theming";
import Typography from "@mui/material/Typography";

export default function Menu_DEFAULT(props: any) {
    const [theme, setTheme] = React.useState<any>(null);
    const [itemVisibility, setItemVisibility] = React.useState<any>({});
    const [menuState, setMenuState] = React.useState<any>({});
    const [scaricaCiclope, setScaricaCiclope] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const location = useLocation();
    const im = HookIsMobile();
    let lastisNull = false;
    useEffect(() => {
        if (root.GlobalTheme)
            setTheme(theming(root.GlobalTheme));
    }, [root.GlobalTheme])
    useEffect(() => {
        if (root.Numbers) {
            let jasper = Object.assign({}, itemVisibility);
            root.Numbers.map((n, i) => {
                if (n.value <= 0) {
                    jasper[n.id] = false;
                }
            });
            setItemVisibility(jasper);
        }
    }, [root.Numbers])

    function canHide(obj: any) {
        let hide = 0;
        obj?.items?.map((o, i) => {
            if (!hasRole(o?.role))
                hide++;
            else if (o?.canHide && hasRole(o?.canHide) && itemVisibility[o?.id] == false)
                hide++;
        });
        return hide == obj?.items?.length;
    }

    function hasRole(roles) {
        if (roles == null)
            return true;
        const indexOf = roles.indexOf(root.LoginData?.role?.toUpperCase());
        var ok = indexOf.toString() != "-1";
        return ok;
    }

    function Item(props: any) {
        const obj = props.obj;
        return (
            <React.Fragment>
                <ListItem button selected={location.pathname.includes(obj?.route)} onClick={() => {
                    if (obj.items == null) {
                        if (props.onClose)
                            props.onClose();
                        dispatch(setMenuToOpen(null));
                        dispatch(setRootFilters(null));
                        dispatch(setShowLoading(false));
                        navigate((obj.route));
                    } else {
                        if (props.onOpen)
                            props.onOpen();
                    }
                }}>
                    <ListItemIcon>
                        {obj.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Box component={"span"}
                                style={{ fontWeight: 600, fontSize: 13, textTransform: "uppercase" }}>
                                {getLabel(obj.name, root)}
                            </Box>
                        }
                    />
                    {theme != null &&
                        <ThemeProvider theme={theme}>
                            {root.Numbers.filter(x => x.id == obj.id).length > 0 &&
                                <Chip sx={{ ml: 1, fontWeight: 700 }} size={"small"} color={"secondary"}
                                    label={root.Numbers.filter(x => x.id == obj.id)[0].value} />
                            }
                        </ThemeProvider>
                    }
                    {obj.items &&
                        <React.Fragment>
                            {props.open ? <ExpandLess /> : <ExpandMore />}
                        </React.Fragment>
                    }
                </ListItem>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <List className={"SubMenu"} component="div" disablePadding>
                        {obj.items && obj.items.map((o, i) => {
                            if (!hasRole(o?.role))
                                return;
                            if (o?.canHide && hasRole(o?.canHide) && itemVisibility[o?.id] == false)
                                return;
                            return (
                                <ListItemButton selected={location.pathname.includes(o?.route)} key={i} sx={{pl: 2}}
                                                onClick={(e) => {
                                                    if (props.onClose)
                                                        props.onClose();
                                                    dispatch(setRootFilters(null));
                                                    dispatch(setShowLoading(false));
                                                    navigate((o.route));
                                                    dispatch(setMenuToOpen(obj.name));
                                                }}
                                >
                                    <ListItemIcon>
                                        <KeyboardArrowRightIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={""}
                                        primary={
                                            <Box component={"span"}
                                                style={{ fontWeight: 500, fontSize: 13, textTransform: "capitalize" }}>
                                                {getLabel(o.name, root).toLowerCase()}
                                            </Box>
                                        }
                                    />
                                    {theme != null &&
                                        <ThemeProvider theme={theme}>
                                            {root.Numbers.filter(x => x.id == o.id).length > 0 &&
                                                <Chip sx={{ ml: 1, fontWeight: 700 }} size={"small"} color={"secondary"}
                                                    label={root.Numbers.filter(x => x.id == o.id)[0].value} />
                                            }
                                        </ThemeProvider>
                                    }
                                </ListItemButton>
                            )
                        })}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    };
    return (
        <React.Fragment>
            <Box sx={{ position: "relative", height: "100%", backgroundColor: root.GlobalTheme.menu_bgcolor }}>
                {theme != null && <ThemeProvider theme={theme}>
                    {/*                    <Paper elevation={0} sx={{zIndex: 100000}} square>*/}
                    <Stack spacing={0}
                        direction="column"
                        justifyContent="cener"
                        alignItems="center"
                        sx={{
                            py: root.GlobalTheme.logo_py,
                            px: root.GlobalTheme.logo_px,
                            fontWeight: 700,
                            textTransform: "uppercase",
                            backgroundColor: (theme) => root.GlobalTheme.logo_bgcolor ?? theme.palette.primary.main
                        }}
                    >
                        <img width={"100%"} src={"/db/dorsale/logoimage"} />
                    </Stack>
                    {/*                  </Paper>*/}
                </ThemeProvider>
                }
                <List
                    sx={{
                        zIndex: 99999,
                        width: '100%',
                        maxWidth: 400,
                        bgcolor: 'background.paper'
                    }}
                    className={"myDrawer"}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {/* opzioni a disposizione*/}
                        </ListSubheader>
                    }
                >
                    {props.menu && props.menu.map((obj, index) => {
                        // @ts-ignore
                        if (!hasRole(obj?.role))
                            return;
                        if (obj == null)
                            lastisNull = true;
                        if (obj && obj.visible == false)
                            return;
                        return (<div key={index}>
                            {obj != null && !canHide(obj) &&
                                <Item onOpen={() => {
                                    setMenuState({ [index]: !menuState[index] });
                                }} open={menuState[index]} key={index} onClose={props.onclose} obj={obj} />
                            }
                        </div>);
                    })}
                </List>
                {!im &&
                    <ThemeProvider theme={theme}>
                        <Box sx={{
                            zIndex: 1,
                            position: "absolute",
                            bottom: 30,
                            left: 0,
                            textAlign: "center",
                            width: "100%"
                        }}>
                            <Button sx={{}} size={"small"} color={"secondary"} variant={"contained"} onClick={() => {
                                setScaricaCiclope(true);
                            }}>
                                Scarica Ciclope Viewer
                            </Button>
                        </Box>
                    </ThemeProvider>
                }
                <Box sx={{
                    zIndex: 1,
                    position: "absolute",
                    bottom: im ? 20 : 80,
                    left: 0,
                    textAlign: "center",
                    width: "100%"
                }}>
                    <Box>
                        <img style={{ maxWidth: 180 }} onError={e => (e.target as any).style.display = 'none'}
                            src={"/db/dorsale/logoimage3"} />

                    </Box>
                </Box>
            </Box>
            <Dialog
                fullScreen={im}
                open={scaricaCiclope}
                onClose={() => {
                    setScaricaCiclope(false);
                }}
                scroll={scroll}
            >
                <DialogContent sx={{ backgroundColor: "white" }} dividers={scroll === 'paper'}>
                    <img
                        src={"ciclope.jpg"} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}