export class jwt{
    access_token: string;
    refresh_token: string;
    exp: number;
    expire_date: number

    constructor(access_token: string, refresh_token: string, exp: number) {
        this.access_token = access_token;
        this.refresh_token = refresh_token;
        this.exp = exp;
        this.expire_date = exp + Date.now();
    }
}

export function isTokenExpired(jwtToken) {
    let exp = JSON.parse(jwtToken).expires_in
    if (exp === null || exp === undefined){
        localStorage.removeItem("token_data")
    }
    const currentTime = Date.now(); 
    return exp < currentTime;
}