import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { indigo, red, orange, deepOrange, grey, } from "@mui/material/colors";

export function theming(dorsaleTheme) {

    /*    let theme = createTheme({
            palette: {
                primary: indigo,
                secondary: {
                    main: indigo["100"],
                },
            }
        });*/

    let theme = createTheme({
        palette: {
            primary: {
                main: dorsaleTheme.color_primary,
            },
            secondary: {
                main: dorsaleTheme.color_secondary,
            },
        }

        /* let theme = createTheme({
             palette: {
                 primary: {
                     main: "#706F6F",
                 },
                 secondary: {
                     main: "#008C36",
                 },
             }
             */
    });
    theme = createTheme(theme, {
        /*        typography: {
                    fontFamily: 'Titillium Web',
                },*/
        components: {
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        textTransform: "uppercase",
                        fontSize: 13,
                        fontWeight: 700,
                    },
                    ol: {
                        flexWrap: "nowrap",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    text: {
                        color: dorsaleTheme.color_reverse ? dorsaleTheme.color_secondary : dorsaleTheme.color_primary,
                    },
                    outlined: {
                        color: dorsaleTheme.color_reverse ? dorsaleTheme.color_secondary : dorsaleTheme.color_primary,
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    colorSecondary: {
                        color: dorsaleTheme.color_reverse ? dorsaleTheme.color_secondary : dorsaleTheme.color_primary,
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    colorPrimary: {
                        color: dorsaleTheme.color_reverse ? dorsaleTheme.color_secondary : dorsaleTheme.color_primary,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    colorPrimary: {
                        color: dorsaleTheme.color_reverse ? dorsaleTheme.color_secondary : dorsaleTheme.color_primary,
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        textTransform: "capitalize",
                        fontWeight: 700,
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    root: {
                        '&.Mui-completed': {
                            color: theme.palette.secondary.main,
                        },
                        '&.Mui-active': {
                            color: theme.palette.secondary.main,
                        },
                    },
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-input": {
                            background: 'white'
                        },
                        "& .MuiInputBase-multiline": {
                            background: 'white'
                        },
                        "& .MuiAutocomplete-inputRoot": {
                            background: 'white'
                        },
                    },
                },
                defaultProps: {
                    variant: dorsaleTheme.layout == "WOLF" ? "standard" : "outlined",
                },
            },
            MuiAutocomplete: {
                defaultProps: {
                    variant: dorsaleTheme.layout == "WOLF" ? "standard" : "outlined",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: dorsaleTheme.layout == "WOLF" ? "standard" : "outlined",
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: 500,
                    },
                },
            },
        }
    });

    return responsiveFontSizes(theme);
}