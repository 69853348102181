import React from 'react';

interface Tab1Props {
  // Inserire le prop necessarie per il contenuto della Tab1
}

const Tab1: React.FC<Tab1Props> = (props) => {
  // Implementare il contenuto della Tab1
    return <div></div>;
};

export default Tab1;
