import React, { useEffect } from 'react';
import { Box, Stack, } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import { isSmallScreen } from "../../utils/Functions";
import { HookIsMobile, UiContainer, UiFabContainer } from "../../utils/Helpers";
import CardMedia from "@mui/material/CardMedia";
import Banner from '../../components/banner/Banner';
import Api from "../../Api";
import CartCategorieItem from "./CartCategorieItem";
import CartCategorieItem_WOLF from "./CartCategorieItem_WOLF";

export default function CartSottoCategorie() {

    let { tipologia } = useParams<any>();
    let { guidcategoria } = useParams<any>();
    let { seo } = useParams<any>();

    const [sottocategorie, setSottocategorie] = React.useState<any>(null);
    const [prodottiArray, setProdottiArray] = React.useState<any>([]);
    const [arr, setArr] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state);

    async function reload() {
        setSottocategorie((await Api.SottoCategoria.all({
            tipologia: tipologia,
            guidcategoria: guidcategoria,
            cart: true
        }, dispatch))?.data);
    }

    useEffect(() => {
        if (isSmallScreen())
            setArr([0, 1, 2]);
        else
            setArr([0, 1, 2, 3]);

        (async () => {
            await reload();
        })();
    }, [tipologia])

    useEffect(() => {
        setProdottiArray([]);
        if (sottocategorie) {
            let tempArr: any = [];
            sottocategorie.forEach((item, index) => {
                if (!isSmallScreen()) {
                    if (index % 4 == 0) {
                        const i = index + 4;
                        setProdottiArray({ items: [...prodottiArray, { items: [sottocategorie[i - 4], sottocategorie[i - 3], sottocategorie[i - 2], sottocategorie[i - 1]] }] });
                        tempArr.push({ items: [sottocategorie[i - 4], sottocategorie[i - 3], sottocategorie[i - 2], sottocategorie[i - 1]] });
                    }
                } else {
                    if (index % 3 == 0) {
                        const i = index + 3;
                        setProdottiArray({ items: [...prodottiArray, { items: [sottocategorie[i - 3], sottocategorie[i - 2], sottocategorie[i - 1]] }] });
                        tempArr.push({ items: [sottocategorie[i - 3], sottocategorie[i - 2], sottocategorie[i - 1]] });
                    }
                }
            });
            setProdottiArray(tempArr);
            if (sottocategorie.length == 1) {
                navigate(`/catalogo/${tipologia}/${guidcategoria}/${seo}/${sottocategorie[0].guid}/${sottocategorie[0].nome}`);
            }
        }
    }, [sottocategorie])

    useEffect(() => {
    }, [prodottiArray])

    function Item(data: any) {
        data = data.data;
        return (
            <Paper elevation={0}
                onClick={() => {
                    navigate(`/catalogo/${tipologia}/${guidcategoria}/${seo}/${data.guid}/${encodeURIComponent(data.nome)}`);
                }}
                sx={{
                    position: "relative",
                    visibility: data == null ? "hidden" : "visible",
                    px: 2,
                    display: "flex",
                    width: "100%",
                    height: 200,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                }}>
                {data?.guid_immagine &&
                    <CardMedia
                        component="img"
                        height="180"
                        image={`/db/media/get?guid=${data?.guid_immagine}`}
                    >
                    </CardMedia>
                }
                <Box sx={{
                    p: .5,
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <Typography sx={{ textTransform: "uppercase", fontWeight: 700 }}
                        variant={"caption"}>{data?.nome}</Typography>
                </Box>
            </Paper>
        );
        return (
            <Box sx={{ height: 300, width: "100%" }}>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Banner sx={{ mb: 2, p: root.Dorsale && root.Dorsale.layout == "DEFAULT" ? 2 : 0 }} />
            <UiContainer className={root.Dorsale && root.Dorsale.layout == "WOLF" ? "box box_padding" : ""}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    {prodottiArray && prodottiArray && prodottiArray.map((cell, index) => (
                        <Stack
                            key={index}
                            sx={{}}
                            direction={im ? "column" : "row"}
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={2}
                        >
                            {arr.map((o, i) => {
                                const data = prodottiArray[index].items[i];
                                if (prodottiArray[index].items[i] == null) {
                                    if (im)
                                        return null;
                                    /* return (
                                         <Item data={prodottiArray[index].items[i]}/>
                                     )*/
                                }
                                return (
                                    <React.Fragment>
                                        {root.Dorsale && (root.Dorsale.layout == "DEFAULT" || root.Dorsale.layout == null) &&
                                            <CartCategorieItem
                                                onClick={() => {
                                                    navigate(`/catalogo/${tipologia}/${guidcategoria}/${seo}/${data.guid}/${encodeURIComponent(data.nome)}`);
                                                }}
                                                tipologia={tipologia}
                                                data={data} />}
                                        {root.Dorsale && root.Dorsale.layout == "WOLF" &&
                                            <CartCategorieItem_WOLF
                                                onClick={() => {
                                                    navigate(`/catalogo/${tipologia}/${guidcategoria}/${seo}/${data.guid}/${encodeURIComponent(data.nome)}`);
                                                }}
                                                tipologia={tipologia}
                                                data={data} />}
                                    </React.Fragment>
                                )
                            })}
                        </Stack>
                    ))}
                </Stack>
                <UiFabContainer onReload={async () => {
                    await reload();
                }} />
            </UiContainer>
        </React.Fragment>
    )
}
