import React, {useEffect} from 'react';
import StripePaymentForm from '../../components/stripe/StripePaymentForm';
import StripeContainer from "../../components/stripe/StripeContainer";
import {RootStateOrAny, useSelector} from "react-redux";

export default function StripePayment(props) {

    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        /*        if (root?.LoginData?.name?.trim("") == "") {
                    history.push("/registrati/temporaneo/" + root?.LoginData?.email);
                }*/
    }, [])

    return (
        <StripeContainer>
            <StripePaymentForm />
        </StripeContainer>
    )
}