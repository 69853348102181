import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, MenuItem } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import Api from "../../../Api";
import {PageHeader, UiContainer, UiFabContainer, UiFrmControl, UiPaper, UiSkeletonForm} from "../../../utils/Helpers";
import {setLoadingMessage, showInfo} from "../../../reducers";

export default React.memo(function SchedulatoreEdit() {
    const [item, setItem] = React.useState<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        setItem((await Api.Schedulatore.all(null, dispatch))?.data[0]);
    }

    function getLabel() {
        switch (item?.ricorrenza) {
            case "GIORNALIERA":
                return "giorni";
            case "SETTIMANALE":
                return "settimane";
            case "MENSILE":
                return "mesi";
        }
    }

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])
    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"configurazione schedulatore ordini"}>
                            <UiFrmControl first>
                                <TextField
                                    select
                                    name={"ricorrenza"}
                                    label={"ricorrenza"}
                                    value={item.ricorrenza ?? "GIORNALIERA"}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"GIORNALIERA"}>GIORNALIERA</MenuItem>
                                    <MenuItem value={"SETTIMANALE"}>SETTIMANALE</MenuItem>
                                    <MenuItem value={"MENSILE"}>MENSILE</MenuItem>
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="valore"
                                    label={getLabel()}
                                    fullWidth
                                    type="number"
                                    value={item?.valore ?? 0}
                                    onChange={handleChange}
                                    inputProps={{
                                        step: "1"
                                    }}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <DatePicker
                                    label="data inizio"
                                    value={item?.inizio ?? new Date()}
                                    onChange={(newValue) => {
                                        setItem({
                                            ...item,
                                            inizio: newValue,
                                        });
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="emails"
                                    label="emails"
                                    fullWidth
                                    multiline
                                    maxRows={10}
                                    type="text"
                                    helperText={"inserire un'email per riga"}
                                    value={item?.emails ?? ""}
                                    onChange={handleChange}
                                />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <React.Fragment>
                                <Fab color="secondary"
                                    onClick={async (e) => {
                                        dispatch(setLoadingMessage("salvataggio in corso..."))
                                        if (await Api.Schedulatore.save(item, dispatch)) {
                                            dispatch(showInfo("il record è stato modificato correttamente"));
                                            dispatch(setLoadingMessage(null))
                                            return false;
                                        } else {
                                            dispatch(setLoadingMessage(null))
                                        }
                                        dispatch(setLoadingMessage(null))
                                        return false;
                                    }}>
                                    <SaveIcon />
                                </Fab>
                            </React.Fragment>
                            <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                        </UiFabContainer>
                    </Box>
                }

                <Button sx={{ mt: 3 }} variant={"contained"} color={"secondary"} onClick={() => {
                    navigate("/schedulatore/history");
                }}>
                    dettaglio invii
                </Button>

                {!item && <UiSkeletonForm />}
            </UiContainer>
        </ React.Fragment>
    );
})