import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Modal, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';


import RefreshIcon from '@mui/icons-material/Refresh';
import StarOutline from '@mui/icons-material/StarOutline';
import StarRate from '@mui/icons-material/StarRate';
import Autocomplete from '@mui/material/Autocomplete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { makeStyles } from "@material-ui/core";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';
import {
    HookIsMobile,
    PageHeader,
    UiContainer, UiFabContainer, UiFileListUploader,
    UiFileUploader,
    UiFormInfo,
    UiFrmControl,
    UiPaper
} from '../../../utils/Helpers';
import Api from "../../../Api";
import { DbAnagrafica, DbCategoria, DbCondizione, DbProdottotosottocategoria } from "../../../Db";
import { setLoadingMessage, showInfo } from "../../../reducers";
import { getLabel, HasGrant, RUOLO, validationError, validationMessage, validationState } from "../../../utils/Functions";

export default React.memo(function ProdottoEdit() {
    let { sku } = useParams<any>();
    let tipologia: any = null;
    const [item, setItem] = React.useState<any>(null);
    const [file, setFile] = React.useState<any>(null);
    const [fileToDelete, setFileToDelete] = React.useState<any>(null);
    let [arrayDbImages, setArrayDbImages] = useState<any>([])
    const [arrayImageToUpload, setArrayImageToUpload] = React.useState<any>([]);
    const [arrayImageToDelete, setArrayImageToDelete] = React.useState<any>([]);
    const [arrayDisplayImages, setArrayDisplayImages] = React.useState<any>([]);
    const [multiImgModalOpen, setMultiImgModalOpen] = useState<any>(false)
    const [media, setMedia] = React.useState<any>(null);
    const [categorie, setCategorie] = React.useState<any>(null);
    const [fornitori, setFornitori] = React.useState<any>(null);
    const [condizioni, setCondizioni] = React.useState<any>([]);
    const [fornitore, setFornitore] = React.useState<any>([]);
    const [categoria, setCategoria] = React.useState<any>(null);
    const [sottoCategorie, setSottoCategorie] = React.useState<any>(null);
    const [prodottoToSottoCategorie, setProdottoToSottoCategorie] = React.useState<any>([]);
    const [settori, setSettori] = React.useState<any>(null);
    const [settoriSelected, setSettoriSelected] = React.useState<any>(null);
    const [validation, setValidation] = React.useState<Array<any>>([
        {
            id: "sku",
            required: true,
        },
        {
            id: "tipologia",
            required: true,
        },
        {
            id: "sottocategoria",
            required: true,
        },
        {
            id: "nome",
            required: true,
        },
        {
            id: "descrizione",
            required: true,
        },
        {
            id: "email_fornitore",
            required: true,
        },
    ]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const root = useSelector((state: RootStateOrAny) => state);
    const im = HookIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    if (location.pathname.includes("/prodotti"))
        tipologia = "prodotto";
    if (location.pathname.includes("/servizi"))
        tipologia = "servizio";
    if (location.pathname.includes("/logistica"))
        tipologia = "logistica";
    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };
    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(() => {
        (async () => {
            if (settori == null && open == true) {
                setLoading(true);
                setSettori((await Api.Settore.all(null, dispatch)).data);
                setLoading(false);
            }
        })();
    }, [open]);
    /*    useEffect(() => {
     console.log(item);
     }, [item]);*/
    useEffect(() => {
        //MOUNT
        return () => {
            //UNMOUNT
        }
    }, [])
    useEffect(() => {
        if (fornitori && item) {
            setFornitore(fornitori.filter(x => x.email == item.email_fornitore)[0]);
        }
    }, [fornitori])
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
        },
    }));
    const classes = useStyles();

    async function reload() {
        const dbsottocategorie = (await Api.SottoCategoria.all(null, dispatch)).data;
        const dbcategorie = (await DbCategoria(null, dispatch)).data;
        const dbprodottotosottocategorie = await DbProdottotosottocategoria(sku, dispatch);
        setCategorie(dbcategorie);
        setSottoCategorie(dbsottocategorie);
        setProdottoToSottoCategorie(dbprodottotosottocategorie);
        if (sku) {
            dispatch(setLoadingMessage("caricamento in corso..."));
            const dbItem = await Api.Prodotto.findBySku({ sku: sku }, dispatch);
            dispatch(setLoadingMessage(null));
            if (dbprodottotosottocategorie.length > 0)
                dbItem.sottocategoria = 1;
            setItem(dbItem);
            if (dbItem) {
                if (dbItem.guid_file)
                    setMedia(await Api.Media.all({ guid: dbItem.guid_file }, dispatch));
                if (dbItem.ProdottoToMedia) {
                    var arrayImages: any[] = [];
                    for (const prodottoToMedia of dbItem.ProdottoToMedia) {
                        const media = await Api.Media.all({ guid: prodottoToMedia.guid_media }, dispatch);
                        arrayImages.push(media);
                    }
                    setArrayDbImages(arrayImages);
                    setArrayDisplayImages(arrayImages);
                }
                let _categoria: any = null;
                if (dbprodottotosottocategorie.length > 0 && dbcategorie) {
                    _categoria = dbcategorie.filter(x => x.guid == dbprodottotosottocategorie[0].guid_categoria)[0];
                    setCategoria(_categoria);
                }
                if (dbsottocategorie.filter(x => x.guid_categoria == _categoria?.guid).length == 1) {
                    setItem({
                        ...dbItem,
                        sottocategoria: dbsottocategorie.filter(x => x.guid_categoria == _categoria?.guid),
                    });
                    if (dbprodottotosottocategorie.length == 0) {
                        setProdottoToSottoCategorie(dbsottocategorie.filter(x => x.guid_categoria == _categoria?.guid));
                    }
                }
            }
            setSettoriSelected((await Api.Settore.prodotto({ sku: dbItem.sku }, dispatch)).data);
        } else {
            setItem({});
            setItem({
                ...item,
                ["tipologia"]: tipologia.toUpperCase(),
            });
        }
        setFornitori((await DbAnagrafica({
            email_cogestore: root.LoginData?.email,
            flat: true
        }, dispatch))?.data ?? []);
        setCondizioni((await DbCondizione(null, dispatch))?.data ?? []);
    }

    useEffect(() => {
        if (item && sottoCategorie && sottoCategorie && prodottoToSottoCategorie) {
            if (prodottoToSottoCategorie.length > 0 && categorie) {
                setCategoria(categorie.filter(x => x.guid == prodottoToSottoCategorie[0].guid_categoria)[0]);
            }
            if (sottoCategorie.filter(x => x.guid_categoria == categoria?.guid).length == 1) {
                setItem({
                    ...item,
                    sottocategoria: sottoCategorie.filter(x => x.guid_categoria == categoria?.guid),
                });
                if (prodottoToSottoCategorie.length == 0) {
                    setProdottoToSottoCategorie(sottoCategorie.filter(x => x.guid_categoria == categoria?.guid));
                }
            }
        }
    }, [sottoCategorie, prodottoToSottoCategorie])
    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])

    return (
        <React.Fragment>
            <Box>
                <UiContainer>
                    <PageHeader title={item?.nome} />
                    {item &&
                        <Box component="form"
                            id={"myForm"}
                            autoComplete="off">
                            <UiPaper first title={"principali"}>
                                <UiFrmControl first>
                                    <Stack
                                        spacing={2}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center">
                                        <TextField
                                            name="sku"
                                            label="sku"
                                            error={validationError(validation, "sku")}
                                            helperText={validationMessage(validation, "sku")}
                                            inputProps={{ style: { fontWeight: 700 } }}
                                            fullWidth
                                            required
                                            type="text"
                                            defaultValue={item?.sku ?? ""}
                                            onBlur={handleChange}
                                        />
                                        <Tooltip title="imposta visibilità per utenti temporanei">
                                            <Checkbox checked={item?.open ?? false}
                                                icon={<NoMeetingRoomIcon color={"primary"} fontSize={"large"} />}
                                                checkedIcon={<MeetingRoomIcon color={"primary"}
                                                    fontSize={"large"} />}
                                                name="open"
                                                onChange={handleChangeCheckbox} />
                                        </Tooltip>
                                        <Tooltip title="imposta come preferito">
                                            <Checkbox checked={item?.preferito ?? false}
                                                icon={<StarOutline color={"primary"} fontSize={"large"} />}
                                                checkedIcon={<StarRate color={"primary"} fontSize={"large"} />}
                                                name="preferito"
                                                onChange={handleChangeCheckbox} />
                                        </Tooltip>
                                        <Tooltip title="imposta la visibilità">
                                            <Checkbox checked={item?.nascosto}
                                                icon={<VisibilityIcon color={"primary"} fontSize={"large"} />}
                                                checkedIcon={<VisibilityOffIcon color={"primary"}
                                                    fontSize={"large"} />}
                                                name="nascosto"
                                                onChange={handleChangeCheckbox} />
                                        </Tooltip>
                                    </Stack>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        sx={{ width: !im ? "100%" : "100%" }}
                                        select
                                        error={validationError(validation, "tipologia")}
                                        helperText={validationMessage(validation, "tipologia")}
                                        label="tipologia"
                                        name="tipologia"
                                        SelectProps={{ style: { fontWeight: 700 } }}
                                        value={item?.tipologia ?? tipologia.toUpperCase()}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"PRODOTTO"}>Prodotto</MenuItem>
                                        <MenuItem value={"SERVIZIO"}>Servizio</MenuItem>
                                        <MenuItem value={"LOGISTICA"}>Logistica</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <Stack direction={"row"} spacing={2} justifyContent="center"
                                        alignItems="center">
                                        <TextField
                                            fullWidth
                                            name="guid_condizione"
                                            select
                                            label="Condizione"
                                            defaultValue={item?.guid_condizione ?? ""}
                                            onBlur={handleChange}
                                        >
                                            {condizioni.map((option) => (
                                                <MenuItem key={option.guid} value={option.guid}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Button
                                            sx={{ flexGrow: 1, whiteSpace: im ? "normal" : "nowrap" }} variant={"text"}
                                            onClick={() => {
                                                navigate("/condizioni-di-vendita");
                                            }}>condizioni di vendita</Button>
                                    </Stack>
                                </UiFrmControl>
                                {HasGrant(root, RUOLO.COGESTORE) &&
                                    <React.Fragment>
                                        <UiFrmControl>
                                            <Autocomplete
                                                options={fornitori ?? []}
                                                getOptionLabel={(option: any) => option.ragione_sociale + (option.nome_attivita !== undefined ? " - " + option.nome_attivita : "")}
                                                value={fornitore}
                                                onChange={(_, newValue) => {
                                                    setFornitore(newValue);
                                                    setItem({
                                                        ...item,
                                                        email_fornitore: newValue?.email,
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={validationError(validation, "email_fornitore")}
                                                        helperText={validationMessage(validation, "email_fornitore")}
                                                        variant="outlined"
                                                        label="fornitore"
                                                        placeholder="fornitore"
                                                    />
                                                )}
                                            />
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            <TextField
                                                fullWidth
                                                name="contatto_fornitore"
                                                label="email fornitore (aggiuntiva)"
                                                type="text"
                                                defaultValue={item?.contatto_fornitore ?? ""}
                                                onBlur={handleChange}
                                            />
                                        </UiFrmControl>
                                    </React.Fragment>
                                }
                                <UiFrmControl>
                                    <Stack
                                        spacing={2}
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="start">
                                        <TextField
                                            sx={{ width: !im ? "100%" : "100%" }}
                                            select
                                            label={getLabel("categoria", root)}
                                            name="categoria"
                                            value={categoria?.guid ?? ""}
                                            onChange={(e) => {
                                                setProdottoToSottoCategorie([]);
                                                setItem({
                                                    ...item,
                                                    sottocategoria: null,
                                                });
                                                if (categorie)
                                                    setCategoria(categorie.filter(x => x.guid == e.target.value)[0]);
                                                /* setSottoCategorie(sottoCategorie.filter(x => x.guid_categoria == e.target.value));*/
                                            }}
                                        >
                                            {categorie && categorie.map((option) => (
                                                <MenuItem
                                                    key={option.guid}
                                                    value={option.guid}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <Autocomplete
                                        multiple
                                        options={sottoCategorie ? sottoCategorie.filter(x => x.guid_categoria == categoria?.guid) : []}
                                        getOptionLabel={(option: any) => option?.nome as any}
                                        value={sottoCategorie ? sottoCategorie.filter(x => x.guid_categoria == categoria?.guid).length == 1
                                            ? sottoCategorie.filter(x => x.guid_categoria == categoria?.guid)
                                            : prodottoToSottoCategorie : []}
                                        onChange={(_, newValue) => {
                                            setItem({
                                                ...item,
                                                sottocategoria: newValue,
                                            });
                                            setProdottoToSottoCategorie(newValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={validationError(validation, "sottocategoria")}
                                                helperText={validationMessage(validation, "sottocategoria")}
                                                name={"sottocategoria"}
                                                label={getLabel("sottocategoria", root)}
                                                placeholder={getLabel("sottocategoria", root)}
                                            />
                                        )}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <Autocomplete
                                        multiple
                                        options={settori ?? []}
                                        getOptionLabel={(option: any) => option?.nome as any}
                                        isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        loading={loading}
                                        value={settoriSelected ?? []}
                                        onChange={(_, newValue) => {
                                            setSettoriSelected(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name={"settori"}
                                                label={getLabel("settori", root)}
                                                placeholder={getLabel("settori", root)}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ?
                                                                <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        sx={{ width: !im ? "100%" : "100%" }}
                                        select
                                        error={root?.ValidationName == "gestione"}
                                        helperText={root?.ValidationName == "gestione" ? root?.ValidationText : null}
                                        label="gestione"
                                        name="gestione"
                                        value={item?.gestione ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"FORNITORE"}>DA FORNITORE</MenuItem>
                                        <MenuItem value={"CENTRALIZZATO"}>CENTRALIZZATO</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                            </UiPaper>
                            <UiPaper title={"dettaglio"}>
                                <UiFrmControl first>
                                    <TextField
                                        name="nome"
                                        label="nome"
                                        error={validationError(validation, "nome")}
                                        helperText={validationMessage(validation, "nome")}
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.nome ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="descrizione"
                                        label="descrizione"
                                        error={validationError(validation, "descrizione")}
                                        helperText={validationMessage(validation, "descrizione")}
                                        fullWidth
                                        required
                                        multiline
                                        maxRows={10}
                                        type="text"
                                        defaultValue={item?.descrizione ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="image_url"
                                        label="URL immagine"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.image_url ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="servizio_esterno"
                                        label="URL servizio esterno"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.servizio_esterno ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        value={"<!--DORSALE TRACKING PIXEL START-->\n" +
                                            "<img height=\"1\" width=\"1\" alt=\"\" onload=\"window.parent.done()\" style=\"display:none\"  src=\"https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png\"/>\n" +
                                            "<!--DORSALE TRACKING PIXEL END-->"}
                                        name="tag"
                                        label="HTML TAG"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        multiline={true}
                                        maxRows={3}
                                        fullWidth
                                        type="text"
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="richiedi_preventivo_t"
                                        label="label richiedi prevenitvo"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.richiedi_preventivo_t ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="step"
                                        label="step"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.step ?? "1"}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="quantita"
                                        label="quantita"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.quantita ?? 0}
                                        onBlur={handleChange}
                                        inputProps={{
                                            step: item?.step ?? "1"
                                        }}
                                    />
                                </UiFrmControl>
                            </UiPaper>

                            <UiPaper title={"costo"}>
                                <UiFrmControl first>
                                    <Stack direction={"row"} spacing={2} justifyItems={"center"} alignItems={"center"}>
                                        <CurrencyTextField
                                            className={classes.root}
                                            label="prezzo"
                                            name="prezzo"
                                            variant="outlined"
                                            value={item?.prezzo ?? 0}
                                            currencySymbol="€"
                                            outputFormat="string"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            onChange={(event, value) => {
                                                setItem({
                                                    ...item,
                                                    prezzo: value,
                                                });
                                            }}
                                        />
                                    </Stack>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <CurrencyTextField
                                        className={classes.root}
                                        label="offerta"
                                        name="offerta"
                                        variant="outlined"
                                        value={item?.offerta ?? 0}
                                        currencySymbol="€"
                                        outputFormat="string"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        onChange={(event, value) => {
                                            setItem({
                                                ...item,
                                                offerta: value,
                                            });
                                        }}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="iva"
                                        label="iva"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.iva ?? 0}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="fee"
                                        label="fee"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.fee ?? 0}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="fee_admin"
                                        label="fee admin"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.fee_admin ?? 0}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </UiFrmControl>
                            </UiPaper>
                            {console.log(arrayDisplayImages)}
                            {console.log(arrayImageToDelete)}
                            <UiPaper title={"immagine"}>
                                <UiFrmControl first>
                                    <UiFileListUploader
                                        image={true}
                                        media={arrayDisplayImages}
                                        onDelete={(e) => {
                                            let appoggio = [...arrayDisplayImages]
                                            appoggio = appoggio.filter((img) => e.filename !== img.filename)

                                            if (arrayDbImages.some((dbimg) => dbimg.filename == e.filename)) {
                                                setArrayImageToDelete([...arrayImageToDelete, e])
                                            }
                                            setArrayDisplayImages([...appoggio])
                                            setArrayImageToUpload([...appoggio])
                                        }}
                                        onChange={(e) => {
                                            if (arrayDisplayImages.find((image) => image.filename === e.filename)) {
                                                setMultiImgModalOpen(true)
                                            } else {
                                                setArrayDisplayImages([...arrayDisplayImages, e])
                                                setArrayImageToUpload([...arrayImageToUpload, e]);
                                            }
                                        }} />
                                </UiFrmControl>
                            </UiPaper>
                            <UiPaper>
                                <UiFrmControl>

                                    <TextField
                                        select
                                        name={"notification"}
                                        label={"notifica"}
                                        defaultValue={item.notification ?? "NESSUNA"}
                                        onBlur={handleChange}
                                    >
                                        <MenuItem value={"PUSH"}>PUSH</MenuItem>
                                        <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
                                        <MenuItem value={"ANY"}>ENTRAMBE</MenuItem>
                                        <MenuItem value={"NESSUNA"}>NESSUNA</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                            </UiPaper>
                            <UiPaper title={"allegato"}>
                                <UiFrmControl first>
                                    <UiFileUploader
                                        media={media}
                                        onDelete={(e) => {
                                            setFileToDelete(e);
                                        }}
                                        onChange={(e) => {
                                            setFile(e);
                                        }} />
                                </UiFrmControl>
                            </UiPaper>

                            <UiFormInfo item={item} />

                            <UiFabContainer>
                                <Fab color="default" size="small"
                                    onClick={async () => {
                                        await reload();
                                    }}>
                                    <RefreshIcon />
                                </Fab>
                                <Fab color="primary" size="small"
                                    onClick={async () => {
                                        if (!window.confirm("confermi"))
                                            return false;
                                        if (await Api.Prodotto.del(item, dispatch)) {
                                            dispatch(showInfo("Il prodotto è stato eliminato correttamente"));
                                            navigate(-1);
                                        }
                                    }}>
                                    <DeleteIcon />
                                </Fab>
                                <Fab color="secondary"
                                    onClick={async (e) => {
                                        if (validationState(item, validation, setValidation) == false)
                                            return false;
                                        dispatch(setLoadingMessage("salvataggio in corso..."));
                                        item.prodottoSottoCategorie = prodottoToSottoCategorie;
                                        item.settori = settoriSelected;

                                        //TODO Gestione Immagini
                                        if (await Api.Prodotto.save(item, [
                                            {
                                                name: "fileAttachment",
                                                value: file,
                                                guid: file
                                            },
                                            {
                                                name: "fileToDelete",
                                                guid: fileToDelete
                                            },
                                            {
                                                name: "arrayImageToUpload",
                                                value: arrayImageToUpload,
                                            },
                                            {
                                                name: "arrayImageToDelete",
                                                value: arrayImageToDelete
                                            },
                                        ], dispatch)) {
                                            dispatch(setLoadingMessage(null));
                                            navigate(-1);
                                        }
                                        dispatch(setLoadingMessage(null));
                                    }}>
                                    <SaveIcon />
                                </Fab>
                            </UiFabContainer>

                        </Box>
                    }
                </UiContainer>
            </Box>
            <Modal open={multiImgModalOpen} onClose={() => setMultiImgModalOpen(false)}>
                <Box sx={{ padding: 5, position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%', width: 400, bgcolor: 'background.paper', boxShadow: 24 }}>
                    Non è possibile inserire due immagini identiche.
                </Box>
            </Modal>
        </ React.Fragment>
    );
})