import React from 'react';
import {useNavigate} from "react-router-dom";
import {BaseGrid} from "../../../components/BaseGrid";
import {DbCondizione} from "../../../Db";

export default function Condizioni(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={DbCondizione}
                onRowClick={(row) => {
                    navigate(`/condizioni-di-vendita/edit/${row.guid}/${row.nome}`);
                }}
                onNew={() => {
                    navigate(`/condizioni-di-vendita/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        mobile: true,
                    },
                ]}
            />
        </React.Fragment>)
}