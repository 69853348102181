import React from 'react';
import StripeContainer from "../../../components/stripe/StripeContainer";
import Ricarica from "../../../unused/ricarica/Ricarica";

export default function RicaricaStripe(props) {

    return (
        <StripeContainer>
            <Ricarica />
        </StripeContainer>
    )
}