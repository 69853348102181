import axios from "axios";
import {setLoadingMessage, setValidationName, setValidationText, showError, showInfo} from "./reducers";
import {reloadNumbers} from "./utils/Functions";
import {clearCache} from "./Cache";
import {isTokenExpired} from "./security/jwt";
import Api from "./Api";


async function baseCallAuth(url: string, dispatch: any) {
    /*    var cache = getCacheData();
        if (cache != null && cache[url] != null) {
            /!*        console.log("CHACHE HIT");*!/
            return cache[url];
        }*/

    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'json'
    };

    const ress = await axios.get(url, auth)
        .then((res) => {
            if (res.data.error != null) {
                dispatch(showError(res.data.error));
                dispatch(setLoadingMessage(null));
                return false;
            }
            if (res.data.validationName != null) {
                dispatch(setValidationName(res.data.validationName));
                dispatch(setValidationText(res.data.validationText));
                const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
                if (el != null)
                    el.focus();
                return false;
            }

            if (res?.data == null)
                return true;
            if (res?.data == "")
                return true;
            return res?.data;
            console.log('Risposta:', res.data);
        })
        .catch((error) => {
            console.error('Errore:', error.message + " - " + error.response.data.error);
            dispatch(showError(error.message + " - " + error.response.data.error));
            dispatch(setLoadingMessage(null));
        });

    return ress;
}
async function login(email: string, password: string,dispatch: any) {
    const axios = require('axios');

    const entity = {
        email: email,
        password: password
    }
    let config = {
        method: 'POST',
        maxBodyLength: Infinity,
        url: '/db/login',
        headers: {
            contentType: 'application/json'
        },
        data : entity
    };
    return axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data.error != null){
                dispatch(showError(response.data.error));
                dispatch(setLoadingMessage(null));
                return null; 
            }
            response.data.expires_in =  Date.now() + Math.floor(response.data.expires_in*1000);
            localStorage.setItem("token_data", JSON.stringify(response.data))
            return response;
        })
        .catch((error) => {
            console.error('Errore:', error.message + " - " + error.response.data.error);
            dispatch(showError(error.message + " - " + error.response.data.error));
            dispatch(setLoadingMessage(null));
        });
}

async function download(url: string,dispatch: any){
    baseCallOctetStream(url, dispatch)
        .then(response => {
            const disposition = response?.headers['content-disposition'];
            let fileName;
            if (disposition) {
                const matches = /filename=([^;]*)/.exec(disposition);
                if (matches && matches[1]) {
                    fileName = matches[1];
                    // Ora hai il nome del file
                    console.log('Nome del file:', fileName);
                }
            }
            
            const blob = new Blob([response?.data]);

            // Crea un URL temporaneo per il Blob
            const url = window.URL.createObjectURL(blob);

            // Crea un link per il download
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName || 'file.csv'; // Imposta il nome del file
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();

            // Rilascia l'URL temporaneo quando il download è completato
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Errore:', error);
        });
}

async function baseCallOctetStream(url: string, dispatch: any) {
    /*    var cache = getCacheData();
        if (cache != null && cache[url] != null) {
            /!*        console.log("CHACHE HIT");*!/
            return cache[url];
        }*/

    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    let startTime: Date = new Date();
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'application/octet-stream'
    };
    const res = await axios.get<any>(url, auth);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return null;
    }
    let endTime: Date = new Date();

    if (typeof res.data === 'object')
        res.data.time = endTime.getTime() - startTime.getTime();

    /*    setCacheData(url, res?.data);*/

    console.log(url, res);

    return res;
}
export async function baseCall(url: string, dispatch: any) {
    /*    var cache = getCacheData();
        if (cache != null && cache[url] != null) {
            /!*        console.log("CHACHE HIT");*!/
            return cache[url];
        }*/

    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    let startTime: Date = new Date();
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'json'
    };
    const res = await axios.get<any>(url, auth);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return null;
    }
    let endTime: Date = new Date();

    if (typeof res.data === 'object')
        res.data.time = endTime.getTime() - startTime.getTime();

    /*    setCacheData(url, res?.data);*/

    console.log(url, res);

    return res.data;
}

async function refreshToken(url: string, entity: any, dispatch: any) {
    const axios = require('axios');
    const requestData = {refresh_token: JSON.parse(entity).refresh_token}
    let config = {
        method: "POST",
        maxBodyLength: Infinity,
        url: '/db/refresh-token',
        headers: {
            contentType: 'application/json'
        },
        data : requestData
    };
    return axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data.error != null) {
                dispatch(showError("Login scaduta. Si prega di ri-effettuare la login."));
                dispatch(setLoadingMessage(null));
                localStorage.removeItem("token_data")
                return null;
            }
            response.data.expires_in =  Date.now() + Math.floor(response.data.expires_in*1000);
            localStorage.setItem("token_data", JSON.stringify(response.data))
        })
        .catch((error) => {
            console.error('Errore:', error.message + " - " + error.response.data.error);
            dispatch(showError(error.message + " - " + error.response.data.error));
            dispatch(setLoadingMessage(null));
        });
}

async function baseCallPostMod(url: string, entity: any, dispatch: any) {
    /*    clearCache();*/
    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'json'
    };

    const ress = await axios.post<any>(url, entity, auth)
        .then((res) => {
            if (res.data.error != null) {
                dispatch(showError(res.data.error));
                dispatch(setLoadingMessage(null));
                return false;
            }
            if (res.data.validationName != null) {
                dispatch(setValidationName(res.data.validationName));
                dispatch(setValidationText(res.data.validationText));
                const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
                if (el != null)
                    el.focus();
                return false;
            }

            if (res?.data == null)
                return true;
            if (res?.data == "")
                return true;
            return res?.data;
            console.log('Risposta:', res.data);
        })
        .catch((error) => {
            console.error('Errore:', error.message + " - " + error.response.data.error);
            dispatch(showError(error.message + " - " + error.response.data.error));
            dispatch(setLoadingMessage(null));
        });

    return ress;

}

async function baseCallDeleteMod(url: string, id: any, dispatch: any) {
    /*    clearCache();*/
    dispatch(setValidationName(null));
    dispatch(setValidationText(null));

    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'json'
    };

    const res = await axios.delete<any>(url + "/" + id, auth);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return false;
    }
    if (res.data.validationName != null) {
        dispatch(setValidationName(res.data.validationName));
        dispatch(setValidationText(res.data.validationText));
        const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
        if (el != null)
            el.focus();
        return false;
    }
    await reloadNumbers(dispatch);
    if (res?.data == null)
        return true;
    if (res?.data == "")
        return true;
    return res?.data;
}

async function baseCallPost(url: string, item: any, dispatch: any) {
    /*    clearCache();*/
    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    console.log(url, item);
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const auth = {
        headers: {
            Authorization: `Bearer ${auth_token}`
        },
        ResponseType: 'json'
    };
    const res = await axios.post<any>(url, {item}, auth);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return false;
    }
    if (res.data.validationName != null) {
        dispatch(setValidationName(res.data.validationName));
        dispatch(setValidationText(res.data.validationText));
        const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
        if (el != null)
            el.focus();
        return false;
    }
    await reloadNumbers(dispatch);
    if (res?.data == null)
        return true;
    if (res?.data == "")
        return true;
    return res?.data;
}

export interface baseCallPost2Files {
    name: string;
    value?: any;
    guid?: any;
}
async function saveProdottoPost(url: string, item: any, files: Array<baseCallPost2Files>, dispatch: any) {
    clearCache();
    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    const formData = new FormData();
    files && files.map((f, i) => {
        if (f.guid != null && f.guid != "") {
            formData.append(f.name, f.guid);
        } else if (f.value != null) {
            for (const file of f.value) {
                if (f.name == "arrayImageToDelete") {
                    formData.append(f.name, file.guid)
                } else
                    formData.append(f.name, file.data);
            }

        }
    });
    formData.append('item', JSON.stringify(item));
    const config = {
        headers: {
            contentType: 'multipart/form-data',
            Authorization: `Bearer ${auth_token}`
        },
    };

    const res = await await axios.post<any>(url, formData, config);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return false;
    }
    if (res.data.validationName != null) {
        dispatch(setValidationName(res.data.validationName));
        dispatch(setValidationText(res.data.validationText));
        const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
        if (el != null)
            el.focus();
        return false;
    }
    dispatch(showInfo("operazione completata"));
    await reloadNumbers(dispatch);
    if (res?.data == null)
        return true;
    if (res?.data == "")
        return true;
    return res?.data;
}
async function baseCallPost2(url: string, item: any, files: Array<baseCallPost2Files>, dispatch: any) {
    clearCache();
    dispatch(setValidationName(null));
    dispatch(setValidationText(null));
    const formData = new FormData();
    const stored_token = localStorage.getItem("token_data");
    let auth_token = '';
    if (stored_token != null){
        auth_token = JSON.parse(stored_token).access_token;
        if (isTokenExpired(stored_token)){
            await refreshToken("/db/refresh-token", stored_token, dispatch);
        }
    }
    files && files.map((f, i) => {
        if (f.guid != null && f.guid != "") {
            formData.append(f.name, f.guid);
        } else if (f.value != null) {
            formData.append(f.name, f.value);
        }
    });
    formData.append('item', JSON.stringify(item));
    const config = {
        headers: {
            contentType: 'multipart/form-data',
            Authorization: `Bearer ${auth_token}`
        },
    };

    const res = await await axios.post<any>(url, formData, config);
    if (res.data.error != null) {
        dispatch(showError(res.data.error));
        dispatch(setLoadingMessage(null));
        return false;
    }
    if (res.data.validationName != null) {
        dispatch(setValidationName(res.data.validationName));
        dispatch(setValidationText(res.data.validationText));
        const el = window.document.getElementsByName(res.data.validationName as string)[0] as HTMLElement;
        if (el != null)
            el.focus();
        return false;
    }
    dispatch(showInfo("operazione completata"));
    await reloadNumbers(dispatch);
    if (res?.data == null)
        return true;
    if (res?.data == "")
        return true;
    return res?.data;
}
export const getFile = async (endpoint: string, filters: any, isExport: boolean,dispatch: any) => {
    var str = "";
    if (filters != null)
        for (var key in filters) {
            if (str != "") {
                str += "&";
            }
            if (filters[key] != null)
                str += key + "=" + encodeURIComponent(filters[key]);
        }
    if (isExport){
        str += "&export=true"; 
    }

    return await download(`${endpoint}?${str}`, dispatch);
};
export const get = async (endpoint: string, filters: any, dispatch: any) => {
    var str = "";
    if (filters != null)
        for (var key in filters) {
            if (str != "") {
                str += "&";
            }
            if (filters[key] != null)
                str += key + "=" + encodeURIComponent(filters[key]);
        }
    return await baseCall(`/db${endpoint}?${str}`, dispatch);
};

export const getCallExternalById = async (endpoint: string, id: string, dispatch: any) => {
    return await baseCallAuth(`${endpoint}/${id}`, dispatch);
};

export const getCallExternal = async (endpoint: string, filters: any, dispatch: any) => {
    var str = "";
    if (filters != null)
        for (var key in filters) {
            if (str != "") {
                str += "&";
            }
            if (filters[key] != null)
                str += key + "=" + encodeURIComponent(filters[key]);
        }
    return await baseCallAuth(`${endpoint}?${str}`, dispatch);
};

export const post = async (endpoint: string, entity: any, dispatch: any) => {
    return await baseCallPost(`/db${endpoint}`, entity, dispatch);
};
export const saveProdotto = async (endpoint: string, entity: any, files: Array<baseCallPost2Files>, dispatch: any) => {
    return await saveProdottoPost(`/db${endpoint}`, entity, files, dispatch);
};
export const post2 = async (endpoint: string, entity: any, files: Array<baseCallPost2Files>, dispatch: any) => {
    return await baseCallPost2(`/db${endpoint}`, entity, files, dispatch);
};

export const getExternal = async (endpoint: string, filters: any, dispatch: any) => {
    return await getCallExternal(`${endpoint}`, filters, dispatch);
};
export const postExternalWithoutFile = async (endpoint: string, entity: any, dispatch: any) => {
    return await baseCallPostMod(`${endpoint}`, entity, dispatch);
};

export const deleteEntity = async (endpoint: string, entity: any, dispatch: any) => {
    return await baseCallDeleteMod(`${endpoint}`, entity, dispatch);
};

export async function DbLogin(data: any, dispatch: any) {
    return await login(data.email, data.password, dispatch);
}

export async function DbIsLogged(attivita: any,dispatch: any) {
    return await baseCall(`/db/islogged?attivita=${attivita??""}`, dispatch);
}

export async function DbAnagrafica(filters: any, dispatch: any) {
    return await baseCall(`/db/anagrafica/all?ruolo=${filters?.ruolo ?? ""}&email_cogestore=${filters?.email_cogestore ?? ""}&flat=${filters?.flat ?? ""}&mine=${filters?.mine ?? ""}&global=${filters?.global ?? ""}&guid=${filters?.guid ?? ""}&take=${filters?.take ?? ""}`, dispatch);
}

export async function DbAnagraficaDel(anagrafica: any, dispatch: any) {
    return await baseCallPost(`/db/anagraficadel`, anagrafica, dispatch);
}

export async function DbProdottotosottocategoria(sku: any, dispatch: any) {
    return await baseCall(`/db/prodottotosottocategoria?sku=${sku ?? ""}`, dispatch);
}

export async function DbFornitoreToCogestore(email: any, dispatch: any) {
    return await baseCall(`/db/fornitoretocogestore?email=${email ?? ""}`, dispatch);
}

export async function DbCondizione(filters: any, dispatch: any) {
    return await baseCall(`/db/condizione?order=${filters?.order ?? ""}&guid=${filters?.guid ?? ""}`, dispatch);
}

export async function DbDorsale(filters: any, dispatch: any) {
    return await baseCall(`/db/dorsale`, dispatch);
}

export async function DbDorsaleTipologia(dispatch: any) {
    return await baseCall(`/db/dorsale/tipologia`, dispatch);
}

export async function DbCredito(dispatch: any) {
    return await baseCall(`/db/credito`, dispatch);
}

export async function DbCategoria(filters: any, dispatch: any) {
    return await baseCall(`/db/categoria/all?order=${filters?.order ?? ""}&guid=${filters?.guid ?? ""}&tipologia=${filters?.tipologia ?? ""}&cart=${filters?.cart ?? ""}`, dispatch);
}

export async function DbNews(filters: any, dispatch: any) {
    return await baseCall(`/db/news?guid=${filters?.guid ?? ""}`, dispatch);
}

export async function DbNewsEdit(news: any, dispatch: any) {
    return await baseCallPost(`/db/newsedit`, news, dispatch);
}

export async function DbNewsDel(news: any, dispatch: any) {
    return await baseCallPost(`/db/newsdel`, news, dispatch);
}

export async function DbRuolo(dispatch: any) {
    return await baseCall(`/db/ruolo2`, dispatch);
}

export async function DbComune(prov: any, dispatch: any) {
    return await baseCall(`/db/comune?prov=${prov ?? ""}`, dispatch);
}

export async function DbHomeNumbers(dispatch: any) {
    return await baseCall(`/db/homenumbers`, dispatch);
}

