import React, {useEffect} from 'react';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Api from "../../../Api";
import {BaseGrid} from "../../../components/BaseGrid";
import {NewsDialog} from "../../../components/news/NewsDialog";


export default function News(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const root = useSelector((state: RootStateOrAny) => state);
    const [macroCategorie, setmacroCategorie] = React.useState<any>(null);
    const [categorie, setcategorie] = React.useState<any>(null);
    const dispatch = useDispatch();
    let { categoria } = useParams<any>();
    let { guid } = useParams<any>();
    const [currNews, setCurrNews] = React.useState<any>(null);

    function IsAdmin() {
        return root.LoginData.role == "ADMIN" || root.LoginData.role == "COGESTORE";
    }

    async function getNewsAsync() {
        const newsData = await Api.News.all(null, dispatch);

        const newsConNotifica = newsData.data.map(news => ({
            ...news,
            notification: {
                'ANY': 'ENTRAMBI',
                'NONE': 'NESSUNA'
            }[news.notification] || news.notification
        }));

        return {
            data: newsConNotifica,
            tot: newsData.tot,
            time:newsData.time,
        };
    }

    useEffect(() => {
        (async () => {
            let newVar = await Api.NewsMacroCategoria.all(null, dispatch);
            if (newVar?.data == null)
                newVar = { data: [{ nome: "GENERICHE" }] };
            else
                newVar.data.push({ nome: "GENERICHE" });
            setmacroCategorie(newVar);
            let dbCategorie = await Api.NewsCategoria.all(null, dispatch);
            if (dbCategorie?.data == null)
                dbCategorie = { data: [{ nome: "GENERICHE" }] };
            else
                dbCategorie.data.push({ nome: "GENERICHE" });
            setcategorie(dbCategorie);
            if (guid != null) {
                var news = await Api.News.all({ guid: guid }, dispatch);
                setCurrNews(news);
            }
        })();
    }, [])
    return (
        <React.Fragment>
            {macroCategorie && categorie &&
                <BaseGrid
                getDataSourceAsync={getNewsAsync}
                    overrideRootFilter={true}
                    filtersDefault={{ home: location.pathname.includes("/vedi-tutte"), nomeMacro: categoria }}
                    onRowClick={(row) => {
                        if (location.pathname.includes("/vedi-tutte")) {
                            setCurrNews(row);
                        } else
                            navigate(`/news/edit/${row.guid}/${encodeURIComponent(row.titolo)}`);
                    }}
                    filters={[
                        [
                            {
                                id: 'home',
                                label: 'home',
                                hide: true,
                            },
                            {
                                id: 'nomeMacro',
                                label: 'macro categoria',
                                options: macroCategorie?.data.map(x => x.nome),
                                disabled: location.pathname.includes("/vedi-tutte"),
                                onChange: (e) => {
                                    if (e == null)
                                        navigate(`/news/`);
                                    else
                                        navigate(`/news/${e}`);
                                }
                            },
                            {
                                id: 'nomeCategoria',
                                label: 'categoria',
                                options: categoria != null ? categorie?.data.filter(x => x.macro == categoria).map(x => x.nome) : categorie?.data.map(x => x.nome),
                            },
                        ],
                        [
                            {
                                id: 'global',
                                label: 'Cerca ovunque',
                            },
                        ]]}
                    onNew={() => {
                        navigate(`/news/nuovo/inserisci`);
                    }}
                    hideMod={true}
                    disableAdd={location.pathname.includes("/vedi-tutte")}
                    headers={[
                        {
                            id: 'data_ins',
                            label: 'Data pubblicazione',
                            date: true,
                        },
                        {
                            id: 'titolo',
                            label: 'Titolo',
                            mobile: true,
                            bold: true,
                        },
                        {
                            id: 'categoria',
                            label: 'Categoria',
                        },
                        {
                            id: 'contenuto',
                            label: 'Contenuto',
                        },
                        {
                            id: 'notification',
                            label: 'Notifiche',
                        },
                    ]}
                />
            }
            {currNews &&
                <NewsDialog currNews={currNews} setCurrNews={setCurrNews} />
            }
        </React.Fragment>)
}