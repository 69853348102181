import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { HookIsMobile } from "../../utils/Helpers";
import BaseCardDisplay from "../../components/BaseCardDisplay";
import { useNavigate } from "react-router";
import Api from "../../Api";
import HeaderFilters from "../../components/header-filter/HeaderFilters";
import SettoriFilter from "../../components/header-filter/SettoriFilter";

function Vetrina() {
    const im = HookIsMobile();
    const [settoriFornitori, setSettoriFornitori] = useState<any>([]);
    const [fornitori, setFornitori] = useState<any>([]);
    const [displayFornitori, setDisplayFornitori] = useState<any[]>([]);
    const [filtro, setFiltro] = useState({ nome: "", indirizzo: "" });
    const [filtroSettori, setFiltroSettori] = useState<any>({});
    const [isLoading, setIsLoading] = useState<Boolean>(false);

    const placeholdersFiltri = [
        { key: "nome", label: "Cerca per nome" },
        { key: "indirizzo", label: "Cerca per indirizzo" },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const fornitoriResponse = await Api.Anagrafica.vetrina({ ruolo: "FORNITORE" }, dispatch);
            const fornitoriData = fornitoriResponse?.Result?.data || [];

            // Creare un Set per i settori unici
            const allSettori = new Set<string>();

            for (const fornitore of fornitoriData) {
                // Assumendo che ogni fornitore abbia una propriet� "settori" che contiene i settori associati
                const settoriFornitore = fornitore.settori_list || [];

                for (const settore of settoriFornitore) {
                    allSettori.add(settore);
                }
            }

            // Convertire il Set in un array ordinato
            const sortedSettori = Array.from(allSettori).sort((a, b) => a.localeCompare(b));

            const initialSelectedSettori = {};
            sortedSettori.forEach((settore) => {
                initialSelectedSettori[settore] = true;
            });

            setFiltroSettori(initialSelectedSettori);
            setSettoriFornitori(sortedSettori);
            setFornitori(fornitoriData);
            setDisplayFornitori(fornitoriData);
            setIsLoading(true);
        })();
    }, [dispatch]);

    const HandleRedirectDettaglio = (fornitore) => {
        navigate(`${fornitore.nome_attivita || fornitore.ragione_sociale}?guid=${fornitore.guid}`);
    };

    const handleFilterChange = (newFiltro) => {
        setFiltro(newFiltro);
    };

    const datiFornitoriFiltrati = (fornitore) => {
        const nomeFornitore = fornitore.nome_attivita || fornitore.ragione_sociale;
        const nomeFiltrato = filtro.nome.toLowerCase();
        const nomePassesFilter = nomeFornitore.toLowerCase().includes(nomeFiltrato);

        const indirizzoFornitore = fornitore.indirizzo || "";
        const indirizzoFiltrato = filtro.indirizzo.toLowerCase();
        const indirizzoPassesFilter = indirizzoFornitore.toLowerCase().includes(indirizzoFiltrato);

        const settoriFornitore = fornitore.settori_list || [];
        const settoriDaVisualizzare = Object.keys(filtroSettori).filter(
            (settoreId) => filtroSettori[settoreId] === true
        );
        const settoriPassesFilter = settoriFornitore.some((settore) =>
            settoriDaVisualizzare.includes(settore)
        );

        return nomePassesFilter && indirizzoPassesFilter && settoriPassesFilter;
    };

    useEffect(() => {
        const appoggio = fornitori;
        setDisplayFornitori(appoggio.filter(datiFornitoriFiltrati));
    }, [filtro, filtroSettori]);

    const handleSettoreToggle = (settore: string) => {
        setFiltroSettori((prevFiltroSettori) => {
            const updatedFiltroSettori = { ...prevFiltroSettori };
            updatedFiltroSettori[settore] = !updatedFiltroSettori[settore];
            return updatedFiltroSettori;
        });
    };

    return (
        <>
            <Stack direction={"row"} width={"100%"}>
                <Stack
                    spacing={2}
                    direction={"column"}
                    justifyContent='flex-start'
                    sx={{ width: "100%" }}
                    alignItems={im ? "center" : "start"}>
                    <Box
                        sx={{
                            width: `100%`,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}>
                        <HeaderFilters filters={placeholdersFiltri} onFilterChange={handleFilterChange} />
                    </Box>

                    <Stack direction={"row"} width={"100%"}>
                        <Stack
                            width={"100%"}
                            className='baseCardDisplayWrap'
                            direction={im ? "column" : "row"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                            justifyContent={"space-around"}
                            margin={3}>
                            {displayFornitori.length === 0 ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: displayFornitori.length === 0 ? "flex-start" : "center",
                                        height: "100vh",
                                    }}>
                                    {!isLoading ? (
                                        <p>Caricamento in corso...</p>
                                    ) : (
                                        <p>Nessun fornitore disponibile al momento.</p>
                                    )}
                                </div>
                            ) : (
                                displayFornitori.map((fornitore, index) => (
                                    <BaseCardDisplay
                                        key={index}
                                        titolo={fornitore.nome_attivita ?? fornitore.ragione_sociale}
                                        descrizione={fornitore.descrizione}
                                        guid_immagine={
                                            fornitore?.AnagraficaToMedia?.length > 0
                                                ? fornitore.AnagraficaToMedia[0].guid_media
                                                : null
                                        }
                                        isCardClickable={fornitore.vetrina_abilitata ? true : false}
                                        onClickRedirect={() => fornitore.vetrina_abilitata ? HandleRedirectDettaglio(fornitore) : {}}
                                    />
                                ))
                            )}
                        </Stack>
                    </Stack>
                </Stack>
                <SettoriFilter settori={settoriFornitori} onSettoreToggle={handleSettoreToggle} />
            </Stack>
        </>
    );
}

export default Vetrina;
