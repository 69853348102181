import React, { useEffect } from 'react';
import CardMedia from "@mui/material/CardMedia";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Tooltip } from "@mui/material";
import {HookIsMobile} from "../../utils/Helpers";
import Api from "../../Api";

enum ActiveTab {
    Tab1 = 1,
    Tab2 = 2,
}


export default React.memo(function AccettaPrivacyCliente(props: any) {

    const dispatch = useDispatch();
    const im = HookIsMobile();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [privacy, setPrivacy] = React.useState<any>(null);
    const [index, setIndex] = React.useState<number>(0);
    const [scroll] = React.useState<DialogProps['scroll']>('body');
    const [privacy_content] = React.useState<any>([]);
    const navigate = useNavigate();

    const [checkbox1Checked, setCheckbox1Checked] = React.useState(false);
    const [checkbox2Checked, setCheckbox2Checked] = React.useState(false);

    const handleConsentiClick = async () => {
        (window.document.getElementsByClassName("MuiDialog-container")[0] as any).scroll({
            top: 0,
            behavior: 'smooth'
        });
        privacy[index].isok = true;
        privacy_content.push(privacy[index]);
        if (privacy[index + 1] != null && privacy[index + 1].text != null && privacy[index + 1].text != "") {
            setIndex(index => index + 1);
        } else {
            setOpenDialog(false);
            if (props.onClose != null)
                props.onClose(privacy_content);
        }

        const checkbox1Value = checkbox1Checked;
        const checkbox2Value = checkbox2Checked;

        const queryString = `?checkbox1=${encodeURIComponent(checkbox1Value)}&checkbox2=${encodeURIComponent(checkbox2Value)}`;

        if (window.location.pathname === "/registrati/cliente") {
            navigate("/registrati/cliente" + queryString);
        }
        else {
            navigate(queryString);
        }


    };




    useEffect(() => {
        (async () => {
            setPrivacy(await Api.Privacy.getbyruolo({ ruolo: props.ruolo }, dispatch));
            setOpenDialog(true);
        })();
    }, [])

    return (
        <React.Fragment>
            {props.show == true && privacy != null &&
                <Dialog
                    fullScreen={im}
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(true);
                    }}
                    scroll={scroll}
                >

                    <DialogTitle id="scroll-dialog-title">{privacy[index].title}</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <div dangerouslySetInnerHTML={{ __html: privacy[index].text?.replaceAll("\n", "<BR>") }} />
                    </DialogContent>


                    {privacy[index].required == false &&


                        <DialogActions>

                            <>
                                <Tooltip title="Letta l'informativa privacy di questo sito, presto il mio consenso per l'invio di comunicazioni 
promozionali (compresa la newsletter) da parte del sito a mezzo mail e riferito a prodotti propri">
                                    <Checkbox
                                        checked={checkbox1Checked}
                                        onChange={(event) => setCheckbox1Checked(event.target.checked)}
                                    />
                                </Tooltip>
                                <span>Marketing Profilato</span>
                                <Tooltip title="Letta l'informativa privacy di questo sito, presto il mio consenso per l'analisi delle 
mie scelte di acquisto su questo sito e'finalizzata all'invio di comunicazioni promozionali da parte di terzi soggetti a mezzo mail
e riferite a prodotti propri di mio specifico interesse">
                                    <Checkbox
                                        checked={checkbox2Checked}
                                        onChange={(event) => setCheckbox2Checked(event.target.checked)}
                                    />
                                </Tooltip>
                                <span>Marketing Profilato Da Terzi</span>
                            </>



                        </DialogActions>

                    }



                    {privacy[index].required == false &&


                        <DialogActions>



                            <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"secondary"} onClick={handleConsentiClick}
                            >acconsenti</Button>
                        </DialogActions>

                    }



                    {privacy[index].required == false &&


                        <DialogActions>


                            <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"primary"} onClick={() => {
                                window.location.reload();
                            }}
                            >Annulla</Button>
                            {privacy[index].required == false &&
                                <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"secondary"} onClick={async () => {
                                    (window.document.getElementsByClassName("MuiDialog-container")[0] as any).scroll({
                                        top: 0,
                                        behavior: 'smooth'
                                    });
                                    privacy[index].isok = false;
                                    privacy_content.push(privacy[index]);
                                    if (privacy[index + 1] != null && privacy[index + 1].text != null && privacy[index + 1].text != "") {
                                        setIndex(index => index + 1);
                                    } else {
                                        setOpenDialog(false);
                                        if (props.onClose != null)
                                            props.onClose(privacy_content);
                                    }
                                }}
                                >rifiuto</Button>
                            }

                        </DialogActions>

                    }





                    {privacy[index].required !== false &&
                        <DialogActions>


                            <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"secondary"} onClick={async () => {
                                (window.document.getElementsByClassName("MuiDialog-container")[0] as any).scroll({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                                privacy[index].isok = true;
                                privacy_content.push(privacy[index]);
                                if (privacy[index + 1] != null && privacy[index + 1].text != null && privacy[index + 1].text != "") {
                                    setIndex(index => index + 1);
                                } else {
                                    setOpenDialog(false);
                                    if (props.onClose != null)
                                        props.onClose(privacy_content);
                                }
                            }}
                            >acconsenti</Button>

                        </DialogActions>

                    }
                    {privacy[index].required !== false &&

                        <DialogActions>

                            <Button sx={{ whiteSpace: "nowrap" }} variant={"outlined"} color={"primary"} onClick={() => {
                                window.location.reload();
                            }}
                            >Annulla Registrazione</Button>
                        </DialogActions>
                    }

                </Dialog>
            }
        </React.Fragment>
    )
})