import React, { useEffect } from 'react';
import CardMedia from "@mui/material/CardMedia";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HookIsMobile } from "../../utils/Helpers";
import Api from "../../Api";
import { Box } from "@mui/material";

export default React.memo(function Banner(props: any) {
    const [banners, setBanners] = React.useState<any>();
    const [item, setItem] = React.useState<number>(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state);
    let counter = 0;
    let timer: any = null;
    useEffect(() => {
        (async () => {
            setBanners((await Api.Banner.all({ banner_non_in_evidenza: true }, dispatch))?.data);
        })();
    }, [])
    useEffect(() => {
        if ((root.LoginData.role == "CLIENTE" || root.LoginData.role == "FORNITORE"))
            if (banners) {
                setTimeout(() => {
                    timer = setInterval(() => {
                        counter++;
                        if (counter > banners.length - 1) {
                            counter = 0;
                            setItem(0);
                        } else {
                            setItem(item => item + 1);
                        }
                    }, 4000);
                }, 0);
                return () => {
                    clearInterval(timer);
                };
            }
    }, [banners])
    return (
        <React.Fragment>
            {(root.LoginData.role == "CLIENTE" || root.LoginData.role == "FORNITORE") &&
                <Box {...props}>
                    {!im && banners &&
                        <CardMedia
                            onClick={async () => {
                                await Api.Banner.click({ guid: banners[item]?.guid }, dispatch);
                                window.open(banners[item]?.link);
                            }}
                            sx={{ cursor: "pointer" }}
                            component="img"
                            height="120"
                            image={banners[item]?.guid_immagine ? `/db/media/get?guid=${banners[item]?.guid_immagine}` : banners[item]?.image_url}
                        />
                        /* <img style={{width: "100%"}} src={banners[item]?.image_url}/>*/
                    }
                    {im && banners &&
                        <img
                            src={banners[item]?.guid_immagine ? `/db/media/get?guid=${banners[item]?.guid_immagine}` : banners[item]?.image_url}
                            style={{ width: "100vw" }} />
                    }
                </Box>
            }
        </React.Fragment>
    )
})