import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import { HookIsMobile } from "../../utils/Helpers";

function HeaderFilters(props) {
	const { filters, onFilterChange } = props;

	const im = HookIsMobile();
	const [filtroValues, setFiltroValues] = useState(() => {
		const initialFilterValues = {};
		filters.forEach((filter) => {
			initialFilterValues[filter.key] = "";
		});
		return initialFilterValues;
	});

	const handleFilterChange = (key, value) => {
		const newFilterValues = { ...filtroValues, [key]: value };
		setFiltroValues(newFilterValues);
		onFilterChange(newFilterValues);
	};

	return (
		<Stack
			spacing={2}
			direction={im ? "column" : "row"}
			justifyContent='start'
			sx={{ width: "100%", padding: 1 }}
			alignItems='start'>
			{filters.map((filter, index) => (
				<TextField
					fullWidth
					name={filter.key}
					key={index}
					label={filter.label}
					value={filtroValues[filter.key]}
					onChange={(e) => handleFilterChange(filter.key, e.target.value)}
					variant='outlined'
				/>
			))}
		</Stack>
	);
}

export default HeaderFilters;
