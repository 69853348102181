import React, { useEffect } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useDispatch, } from "react-redux";
import { unescape } from 'querystring';
import Api from "../../../Api";
import {BaseGrid} from "../../../components/BaseGrid";

export default function Videos(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function getVideosAsync() {
        const videosData = await Api.Video.all(null, dispatch);

        const videosConNotifica = videosData.data.map(video => ({
            ...video,
            notification: {
                'ANY': 'ENTRAMBI',
                'NONE': 'NESSUNA'
            }[video.notification] || video.notification,
            nomi_settori_list: video.nomi_settori_list && video.nomi_settori_list.length > 0
                ? video.nomi_settori_list.map((settore, index) => index === 0 ? settore : ' - ' + settore )
                : video.nomi_settori_list
        }));

        return {
            data: videosConNotifica,
            tot: videosData.tot,
            time: videosData.time,
        };
    }
    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={getVideosAsync}
                onRowClick={(row) => {
                    navigate(`/videos/edit/${row.guid}/${row.youtube_id}`);
                }}
                onNew={() => {
                    navigate(`/videos/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'youtube_id',
                        label: 'Youtube ID',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                    {
                        id: 'is_default',
                        label: 'Default',
                        check: true,
                    },
                    {
                        id: 'nomi_settori_list',
                        label: 'Settore',
                    },
                    {
                        id: 'notification',
                        label: 'Notifiche',
                    },
                    {
                        id: 'data_ins',
                        label: 'Data di inserimento',
                        date: true,
                    },
                ]}
            />
        </React.Fragment>)
}