import React from 'react';
import {useNavigate} from "react-router-dom";
import {BaseGrid} from "../../../../components/BaseGrid";
import Api from "../../../../Api";



export default function NewsMacroCategorie(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.NewsMacroCategoria.all}
                onRowClick={(row) => {
                    navigate(`/news-macro-categorie/edit/${row.guid}/${encodeURIComponent(row.nome)}`);
                }}
                onNew={() => {
                    navigate(`/news-macro-categorie/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                ]}
            />
        </React.Fragment>)
}