import React, { useState, useEffect } from 'react';

import Api from "../../Api";

import { Box, Button, InputAdornment, Modal } from "@mui/material";

import { PageHeader, UiContainer, UiFrmControl, UiPaper } from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VisaIcon from '../../images/Visa_Inc.png';
import MastercardIcon from '../../images/Mastercard-logo.png';
import AmericanExpressIcon from '../../images/American_Express.png';

import { setDialogMessage, setLoadingMessage } from "../../reducers";
import { useDispatch } from "react-redux";

import { Grid } from "@mui/material";


export default function Ricarica(props) {

    function getCreditCardType(cardNumber: string): string {
        // Rimuovi eventuali spazi bianchi e trattini dal numero della carta
        const cleanedCardNumber = cardNumber.replace(/[\s-]/g, '');

        // Regex per il riconoscimento dei tipi di carta
        const cardTypes = [
            { type: 'Visa', pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },
            { type: 'Mastercard', pattern: /^5[1-5][0-9]{14}$/ },
            { type: 'American Express', pattern: /^3[47][0-9]{13}$/ },
            { type: 'Discover', pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ },
        ];

        // Verifica il numero della carta con i pattern dei tipi di carta
        for (const cardType of cardTypes) {
            if (cardType.pattern.test(cleanedCardNumber)) {
                return cardType.type;
            }
        }

        // Se non viene riconosciuto nessun tipo di carta, restituisci una stringa vuota
        return '';
    }


    const [importo, setImporto] = React.useState<any>(0);
    const [cardNumber, setCardNumber] = React.useState<any>('');
    const [cvc, setCvc] = React.useState<any>('');
    const [expMonth, setExpMonth] = React.useState<any>('');
    const [expYear, setExpYear] = React.useState<any>('');
    const cardType = getCreditCardType(cardNumber);

    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumber(event.target.value);
    };

    const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCvc(event.target.value);
    };

    const handleExpMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpMonth(event.target.value);
    };

    const handleExpYear = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpYear(event.target.value);
    };

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [importoError, setImportoError] = useState<string>('');

    useEffect(() => {

        const validateImporto = () => {
            if (importo < 1) {
                setImportoError("L'importo deve essere maggiore o uguale a 1");
            } else {
                setImportoError('');
            }
        };


        validateImporto();
    }, [importo]);

    const handleModalOpen = (message: string) => {
        setModalMessage(message);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);

    };

    return (
        <React.Fragment>
            <UiContainer>
                <Box sx={{ mt: 4 }}>
                    <PageHeader title={"Ricarica"} />
                    <UiPaper padding>
                        <UiFrmControl first>
                            <CurrencyTextField
                                label="Importo"
                                variant="outlined"
                                value={importo}
                                currencySymbol="€"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) => setImporto(value)}
                                error={!!importoError}
                                helperText={importoError}
                            />
                        </UiFrmControl>
                        <Grid container spacing={2} sx={{ mt: 4 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="NumeroCarta"
                                    fullWidth
                                    required
                                    label="Numero Carta"
                                    type="CardNumber"
                                    inputProps={{ style: { fontWeight: 700 } }}
                                    value={cardNumber}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {/* Aggiungi l'icona del tipo di carta in base al risultato di cardType */}
                                                {cardType === 'Visa' && <img src={VisaIcon} alt="Visa" />}
                                                {cardType === 'Mastercard' && <img src={MastercardIcon} alt="Mastercard" />}
                                                {cardType === 'American Express' && <img src={AmericanExpressIcon} alt="AmericanExpress" />}
                                                {/* Aggiungi altre icone per i tipi di carta supportati */}
                                                {cardType === 'CreditCard' && <CreditCardIcon />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="CvCCard"
                                    fullWidth
                                    required
                                    label="CvC"
                                    type="CvC"
                                    inputProps={{ style: { fontWeight: 700 } }}
                                    value={cvc}
                                    onChange={handleChange1}

                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 4 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="expireMonth"
                                    fullWidth
                                    required
                                    label="Mese Scadenza"
                                    type="ExpMonth"
                                    inputProps={{ style: { fontWeight: 700 } }}
                                    value={expMonth}
                                    onChange={handleExpMonth}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="expireYear"
                                    fullWidth
                                    required
                                    label="Anno Scadenza"
                                    type="ExpYear"
                                    inputProps={{ style: { fontWeight: 700 } }}
                                    value={expYear}
                                    onChange={handleExpYear}

                                />
                            </Grid>
                        </Grid>
                    </UiPaper>
                    <Button
                        sx={{ mt: 3 }}
                        variant="contained"
                        size="large"
                        endIcon={<CreditCardIcon />}

                        onClick={async (e) => {
                            if (importoError === '') {
                                dispatch(setLoadingMessage("ricarica in corso..."));
                                try {
                                    var res = await Api.Bilancio.ricarica(
                                        {
                                            importo: importo,
                                            cardNumber: cardNumber,
                                            cvc: cvc,
                                            expMonth: expMonth,
                                            expYear: expYear,
                                        },
                                        dispatch
                                    );
                                    handleModalOpen("Ricarica effettuata correttamente.");
                                    setImporto('');
                                    setCardNumber('');
                                    setCvc('');
                                    setExpMonth('');
                                    setExpYear('');
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 1000);

                                } catch (error: any) {
                                    const errorMessage = error.response.data.error;
                                    if (errorMessage) {
                                        handleModalOpen(
                                            `Errore durante la ricarica: ${errorMessage}`
                                        );
                                    } else {
                                        handleModalOpen("Errore durante la ricarica.");
                                    }
                                    console.error(error);
                                } finally {
                                    dispatch(setLoadingMessage(null));
                                }
                            }
                        }}
                    >
                        Ricarica
                    </Button>
                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            <h2 id="modal-title">Esito Operazione</h2>
                            <p id="modal-description">{modalMessage}</p>
                            <Button
                                onClick={handleModalClose}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                }}
                            >
                                x
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </UiContainer>
        </React.Fragment>
    );
}
