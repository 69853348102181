import React from 'react';
import {useNavigate} from "react-router-dom";
import {BaseGrid} from "../../../components/BaseGrid";
import Api from "../../../Api";


export default React.memo(function Privacy(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.Privacy.all}
                onRowClick={(row) => {
                    navigate(`/privacy/edit/${row.guid}/${row.nome}`);
                }}
                onNew={() => {
                    navigate(`/privacy/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                    {
                        id: 'ruolo',
                        label: 'ruolo',
                    },
                    {
                        id: 'posizione',
                        label: 'posizione',
                    },
                    {
                        id: 'obbligatorio',
                        label: 'obbligatorio',
                        check: true,
                    },
                    {
                        id: 'predefinito',
                        label: 'predefinito',
                        check: true,
                    },
                ]}
            />
        </React.Fragment>)
})