import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	List,
	ListItemButton,
	ListItemIcon,
	Paper,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HookIsMobile } from "../../utils/Helpers";
import { RootStateOrAny, useSelector } from "react-redux";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@mui/icons-material";

export interface SettoriFilters {
	settori: string[];
	onSettoreToggle: (settoreId: string) => void;
}

export default function SettoriFilter(props: SettoriFilters) {
	const [settoriMap, setSettoriMap] = useState<any>({});
	const [checkedAccordions, setCheckedAccordions] = useState<number[]>([]);
	const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
	const im = HookIsMobile();
	const root = useSelector((state: RootStateOrAny) => state);

	useEffect(() => {
		const appoggioMapping = {};
		props.settori.forEach((settore) => {
			const primaLettera = settore.charAt(0).toUpperCase();
			if (!appoggioMapping[primaLettera]) {
				appoggioMapping[primaLettera] = [];
			}
			appoggioMapping[primaLettera].push(settore);
		});
		setSettoriMap(appoggioMapping);
	}, [props.settori]); //Apertura di tutti gli Accordion

	useEffect(() => {
		setCheckedAccordions(Array.from({ length: props.settori.length }, (_, i) => i));
	}, [props.settori]); //Selezione di tutte le checkBox

	useEffect(() => {
		const initialCheckedItems: { [key: string]: boolean } = {};
		props.settori.forEach((settore: any) => {
			initialCheckedItems[settore] = true;
		});
		setCheckedItems(initialCheckedItems);
	}, [props.settori]);

	const allAccordionsOpen = checkedAccordions.length === props.settori.length;

	const toggleAccordion = () => {
		if (allAccordionsOpen) {
			setCheckedAccordions([]);
		} else {
			setCheckedAccordions(Array.from({ length: props.settori.length }, (_, i) => i));
		}
	};

	const handleAccordionToggle = (index: number) => () => {
		const currentIndex = checkedAccordions.indexOf(index);
		const newCheckedAccordions = [...checkedAccordions];

		if (currentIndex === -1) {
			newCheckedAccordions.push(index);
		} else {
			newCheckedAccordions.splice(currentIndex, 1);
		}

		setCheckedAccordions(newCheckedAccordions);
	};

	const handleItemToggle = (settore: string) => () => {
		const updatedCheckedItems = { ...checkedItems };
		updatedCheckedItems[settore] = !updatedCheckedItems[settore];
		setCheckedItems(updatedCheckedItems);
		props.onSettoreToggle(settore);
	};

	const toggleAllCheckboxes = () => {
		const updatedCheckedItems: { [key: string]: boolean } = {};
		const allCheckboxesSelected = Object.values(checkedItems).every((value) => value);

		props.settori.forEach((settore) => {
			updatedCheckedItems[settore] = !allCheckboxesSelected;
			if (updatedCheckedItems[settore] !== checkedItems[settore]) {
				props.onSettoreToggle(settore);
				console.log("Cambio");
			}
		});

		setCheckedItems(updatedCheckedItems);
	};

	const allCheckboxesSelected = Object.values(checkedItems).every((value) => value);

	return (
		<Paper
			sx={{
				p: 0,
				borderRadius: 2,
				border: `1px solid #ccc`,
				boxShadow: "none",
				marginTop: 1,
				height: "100%",
				overflow: "auto",
			}}>
			<Box bgcolor={root?.GlobalTheme?.color_secondary} padding={1}>
				<Typography sx={{ textAlign: "center", color: "#fff" }}>FILTRI SETTORE</Typography>
			</Box>
			<Box
				display={"flex"}
				flexDirection={im ? "column" : "row"}
				justifyContent={"space-around"}
				marginTop={"8px"}>
				<>
					<Button
						variant='outlined'
						sx={{
							padding: "5px",
							fontSize: "12px",
							borderColor: root?.GlobalTheme?.color_secondary,
							"&:hover": { borderColor: root?.GlobalTheme?.color_secondary },
						}}
						onClick={toggleAllCheckboxes}>
						{allCheckboxesSelected ? "Rimuovi filtri" : "Seleziona tutti"}
					</Button>
				</>
				<>
					<Button
						variant='outlined'
						sx={{
							padding: "5px",
							fontSize: "12px",
							borderColor: root?.GlobalTheme?.color_secondary,
							"&:hover": { borderColor: root?.GlobalTheme?.color_secondary },
						}}
						onClick={toggleAccordion}>
						{allAccordionsOpen ? "Minimizza" : "Espandi"}
					</Button>
				</>
			</Box>
			<List sx={{ width: "100%" }}>
				{Object.keys(settoriMap).map((lettera, index: number) => (
					<div key={lettera}>
						<Accordion
							expanded={checkedAccordions.includes(index)}
							onChange={handleAccordionToggle(index)}
							sx={{ boxShadow: "none", border: "none" }}>
							<AccordionSummary
								sx={{
									"&.Mui-expanded": {
										margin: 0,
									},
								}}
								expandIcon={<ExpandMore />}>
								<Typography
									sx={{
										color: `${root?.GlobalTheme?.color_secondary}`,
									}}>
									{lettera}
								</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{ paddingBottom: 0, paddingTop: 0 }}>
								{settoriMap[lettera].map((settore) => (
									<ListItemButton
										key={settore}
										onClick={handleItemToggle(settore)}
										sx={{
											padding: 0,
											borderRadius: "0",
											"&:hover": {
												backgroundColor: "rgba(0, 0, 0, 0.04)",
											},
										}}>
										<ListItemIcon>
											{checkedItems[settore] ? <CheckBox /> : <CheckBoxOutlineBlank />}
										</ListItemIcon>
										<span style={{ fontSize: "12px" }}>{settore}</span>
									</ListItemButton>
								))}
							</AccordionDetails>
						</Accordion>
					</div>
				))}
			</List>
		</Paper>
	);
}
