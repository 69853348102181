import React, { Component, useEffect } from 'react';
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { currencyFormat, Log, reloadCredito } from "../../utils/Functions";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { HookIsMobile } from "../../utils/Helpers";
import { useNavigate } from "react-router-dom";

export default function Credito() {

    const [cartAnchor, setCartAnchor] = React.useState<any>(null);
    const cartOpen = Boolean(cartAnchor);
    const root = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const im = HookIsMobile();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await reloadCredito(root, dispatch);
        })();
    }, [])

    return (
        <React.Fragment>
            <Button
                sx={{ fontWeight: 700 }}
                onClick={(event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
                    setCartAnchor(event.currentTarget)
                }}
                aria-controls="basic-menu"
                aria-haspopup="true"
                variant="contained"
                disableElevation
                startIcon={<MonetizationOnIcon />}>
                {currencyFormat(root?.Credito)}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={cartAnchor}
                open={cartOpen}
                onClose={() => {
                    setCartAnchor(null);
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Button variant={"contained"} color={"primary"} onClick={() => {
                        setCartAnchor(null);
                        navigate("/ricarica");
                    }}>ricarica</Button>
                </Box>
            </Menu>
        </React.Fragment>
    )
}
