import React, {useEffect} from 'react';
import {
    Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle,
    ListItemIcon,
    ListSubheader, Menu, MenuItem,
    Stack, ThemeProvider
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import Chip from "@mui/material/Chip";
import {
    setIsLogged,
    setLabels,
    setMenuToOpen,
    SetNumbers,
    setRootFilters,
    setShowLoading,
    setTipologia
} from "../../reducers";
import {currencyFormat, getLabel, Log, reloadNumbers} from "../../utils/Functions";
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {HookIsMobile} from "../../utils/Helpers";
import {theming} from "../../Theming";
import Avatar from "@mui/material/Avatar";
import {TextNormaBold, TextNormal} from "../../utils/Text";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import Cart from "../cart/Cart";
import Api from "../../Api";
import {DbDorsaleTipologia, DbIsLogged} from "../../Db";

export default function Menu_WOLF(props: any) {
    const [theme, setTheme] = React.useState<any>(null);
    const [itemVisibility, setItemVisibility] = React.useState<any>({});
    const [menuState, setMenuState] = React.useState<any>({});
    const [scaricaCiclope, setScaricaCiclope] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [avatar, setAvatar] = React.useState<any>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const location = useLocation();
    const im = HookIsMobile();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    let lastisNull = false;
    useEffect(() => {
        (async () => {
            setAvatar(await Api.Anagrafica.avatar(null, dispatch));
        })();
    }, [])
    useEffect(() => {
        if (root.GlobalTheme)
            setTheme(theming(root.GlobalTheme));
    }, [root.GlobalTheme])
    useEffect(() => {
        if (root.Numbers) {
            let jasper = Object.assign({}, itemVisibility);
            root.Numbers.map((n, i) => {
                if (n.value <= 0) {
                    jasper[n.id] = false;
                }
            });
            setItemVisibility(jasper);
        }
    }, [root.Numbers])

    function canHide(obj: any) {
        let hide = 0;
        obj?.items?.map((o, i) => {
            if (!hasRole(o?.role))
                hide++;
            else if (o?.canHide && hasRole(o?.canHide) && itemVisibility[o?.id] == false)
                hide++;
        });
        return hide == obj?.items?.length;
    }

    /*    useEffect(() => {
     console.log(itemVisibility);
     }, [itemVisibility])*/
    function hasRole(roles) {
        if (roles == null)
            return true;
        const indexOf = roles.indexOf(root.LoginData?.role?.toUpperCase());
        var ok = indexOf.toString() != "-1";
        return ok;
    }

    function Item(props: any) {
        const obj = props.obj;
        const selected = location.pathname.includes(obj?.route);
        return (
            <React.Fragment>
                <ListItem sx={{
                    borderLeftStyle: selected ? "solid" : "none",
                    borderLeftWidth: "2px",
                    borderLeftColor: root?.GlobalTheme?.color_secondary
                }} button selected={selected}
                          onClick={() => {
                              if (obj.items == null) {
                                  if (props.onClose)
                                      props.onClose();
                                  dispatch(setMenuToOpen(null));
                                  dispatch(setRootFilters(null));
                                  dispatch(setShowLoading(false));
                                  navigate((obj.route));
                              } else {
                                  if (props.onOpen)
                                      props.onOpen();
                              }
                          }}>
                    <ListItemIcon>
                        {obj.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Box component={"span"}
                                 style={{fontWeight: 600, fontSize: 13, textTransform: "uppercase"}}>
                                {getLabel(obj.name, root)}
                            </Box>
                        }
                    />
                    {theme != null &&
                        <ThemeProvider theme={theme}>
                            {root.Numbers.filter(x => x.id == obj.id).length > 0 &&
                                <Chip sx={{ml: 1, fontWeight: 700}} size={"small"} color={"secondary"}
                                      label={root.Numbers.filter(x => x.id == obj.id)[0].value}/>
                            }
                        </ThemeProvider>
                    }
                    {obj.items &&
                        <React.Fragment>
                            {(props.open) ? <KeyboardArrowLeftOutlinedIcon/> :
                                <KeyboardArrowRightOutlinedIcon/>}
                        </React.Fragment>
                    }
                </ListItem>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                    <List className={"SubMenu"} component="div" disablePadding>
                        {obj.items && obj.items.map((o, i) => {
                            if (!hasRole(o?.role))
                                return;
                            if (o?.canHide && hasRole(o?.canHide) && itemVisibility[o?.id] == false)
                                return;
                            const selected = location.pathname.includes(o?.route);
                            return (
                                <ListItemButton sx={{
                                    borderLeftStyle: selected ? "solid" : "none",
                                    borderLeftWidth: "2px",
                                    borderLeftColor: root?.GlobalTheme?.color_secondary,
                                    pl: 2
                                }} selected={selected} key={i}
                                                onClick={(e) => {
                                                    if (props.onClose)
                                                        props.onClose();
                                                    dispatch(setRootFilters(null));
                                                    dispatch(setShowLoading(false));
                                                    navigate((o.route));
                                                    dispatch(setMenuToOpen(obj.name));
                                                }}
                                >
                                    <ListItemIcon>
                                        <KeyboardArrowRightIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        secondary={""}
                                        primary={
                                            <Box component={"span"}
                                                 style={{fontWeight: 500, fontSize: 13, textTransform: "capitalize"}}>
                                                {getLabel(o.name, root).toLowerCase()}
                                            </Box>
                                        }
                                    />
                                    {theme != null && <ThemeProvider theme={theme}>
                                        {root.Numbers.filter(x => x.id == o.id).length > 0 &&
                                            <Chip sx={{ml: 1, fontWeight: 700}} size={"small"} color={"secondary"}
                                                  label={root.Numbers.filter(x => x.id == o.id)[0].value}/>
                                        }
                                    </ThemeProvider>
                                    }
                                </ListItemButton>
                            )
                        })}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    };
    return (
        <React.Fragment>
            <Box sx={{position: "relative", height: "100%", backgroundColor: root.GlobalTheme.menu_bgcolor}}>
                <Box sx={{m: 2}} className={"box"}>
                    {theme != null && <ThemeProvider theme={theme}>
                        {/*                    <Paper elevation={0} sx={{zIndex: 100000}} square>*/}
                        <Stack spacing={0}
                               direction="column"
                               justifyContent="cener"
                               alignItems="center"
                               sx={{
                                   py: root.GlobalTheme.logo_py,
                                   px: root.GlobalTheme.logo_px,
                                   fontWeight: 700,
                                   textTransform: "uppercase",
                                   backgroundColor: (theme) => root.GlobalTheme.logo_bgcolor ?? theme.palette.primary.main
                               }}
                        >
                            <img width={"100%"} src={"/db/dorsale/logoimage"} />
                            <Avatar
                                src={`/db/media/get?guid=${avatar}`}
                                onClick={() => {
                                    let input = document.createElement('input');
                                    input.type = 'file';
                                    input.onchange = async _ => {
                                        if (input != null && input.files != null) {
                                            await Api.Anagrafica.saveavatar({}, [
                                                {
                                                    name: "avatar",
                                                    value: input.files[0],
                                                },
                                            ], dispatch);
                                            window.location.reload();
                                        }
                                    };
                                    input.click();
                                }}
                                sx={{width: 70, height: 70, my: 1, cursor: "pointer"}}>
                                {!avatar ? "+" : ""}
                            </Avatar>
                            <Box sx={{pt: 1, whiteSpace: "nowrap", textTransform: "capitalize"}}>
                                <TextNormaBold sx={{opacity: .8}}>
                                    {root?.LoginData?.name?.toLowerCase()}
                                </TextNormaBold>
                            </Box>
                            {!im &&
                                <Stack sx={{mt: 1}} direction={"row"} justifyContent={"center"} spacing={0}>
                                    <IconButton
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl(event.currentTarget);
                                        }}
                                        size="large">
                                        <PermIdentityOutlinedIcon/>
                                    </IconButton>
                                    <Cart/>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={() => {
                                            setAnchorEl(null);
                                        }}
                                        onClick={() => {
                                            setAnchorEl(null);
                                        }}
                                    >
                                        {root?.isLogged === true && root.LoginData?.name?.trim() != "" &&
                                            <MenuItem onClick={() => {
                                                navigate("/miei-dati");
                                            }}>I Miei Dati</MenuItem>
                                        }
                                        {root?.isLogged === true && root?.Tipologia != "ENTERPRISE" &&
                                            <MenuItem onClick={() => {
                                                navigate("/bilancio/edit/");
                                            }}>Ricarica ({currencyFormat(root?.Credito)})</MenuItem>
                                        }
                                        {root?.isLogged === true &&
                                            <MenuItem onClick={() => {
                                                localStorage.removeItem("CartItems");
                                                document.location.href = "/db/logout";
                                            }}>Esci</MenuItem>
                                        }
                                    </Menu>
                                </Stack>
                            }
                        </Stack>
                        {root.GlobalTheme.guid_logo2 &&
                            <Box style={{textAlign: "center"}}>
                                <img style={{marginBottom: "12px"}} width={"100px"} src={"/db/dorsale/logoimage2"}/>
                            </Box>
                        }
                    </ThemeProvider>
                    }
                </Box>
                <Box sx={{ m: 2, zIndex: 99999, pt: 3 }} className={"box"}>
                    <List
                        sx={{
                            zIndex: 99999,
                            width: '100%',
                            maxWidth: 400,
                        }}
                        className={"myDrawer"}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {/* opzioni a disposizione*/}
                            </ListSubheader>
                        }
                    >
                        {props.menu && props.menu.map((obj, index) => {
                            // @ts-ignore
                            if (!hasRole(obj?.role))
                                return;
                            if (obj == null)
                                lastisNull = true;
                            if (obj && obj.visible == false)
                                return;
                            return (<div key={index}>
                                {obj != null && !canHide(obj) &&
                                    <Item onOpen={() => {
                                        setMenuState({ [index]: !menuState[index] });
                                    }} open={menuState[index]} key={index} onClose={props.onclose} obj={obj} />
                                }
                            </div>);
                        })}
                    </List>
                    {!im &&
                        <ThemeProvider theme={theme}>
                            <Box sx={{
                                zIndex: 0,
                                textAlign: "center",
                                width: "100%",
                                pb: 3,
                                pt: 2,
                            }}>
                                <Button sx={{}} size={"small"} color={"secondary"} variant={"contained"}
                                    onClick={() => {
                                        setScaricaCiclope(true);
                                    }}>
                                    Scarica Ciclope Viewer
                                </Button>
                            </Box>
                        </ThemeProvider>
                    }
                    <Box sx={{
                        zIndex: 0,
                        textAlign: "center",
                        width: "100%",
                        pb: 0,
                        pt: 0,
                    }}>
                        <Box>
                            <img style={{ maxWidth: 150 }} onError={e => (e.target as any).style.display = 'none'}
                                src={"/db/dorsale/logoimage3"} />

                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                fullScreen={im}
                open={scaricaCiclope}
                onClose={() => {
                    setScaricaCiclope(false);
                }}
                scroll={scroll}
            >
                <DialogContent sx={{ backgroundColor: "white" }} dividers={scroll === 'paper'}>
                    <img
                        src={"ciclope.jpg"} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}