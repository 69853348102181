import React, { useEffect } from 'react';
import {
    showInfo,
} from "../../../reducers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer, UiFormInfo
} from "../../../utils/Helpers";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, MenuItem } from "@mui/material";
import Api from "../../../Api";
import { getLabel } from "../../../utils/Functions";
import CardMedia from "@mui/material/CardMedia";

export default React.memo(function SettoreEdit() {

    let { guid } = useParams<any>();

    const [item, setItem] = React.useState<any>(null);
    const [banners, setbanners] = React.useState<any>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        (async () => {
            setbanners(await Api.Banner.all(null, dispatch));
            await reload();
        })();
    }, [])

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        if (guid != null)
            setItem(await Api.Settore.all({ guid: guid }, dispatch));
        else
            setItem({});
    }

    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"gestione " + getLabel("settore", root)}>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.guid ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    error={root?.ValidationName == "nome"}
                                    helperText={root?.ValidationName == "nome" ? root?.ValidationText : null}
                                    name="nome"
                                    label="nome"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.nome ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>

                            <UiFrmControl>
                                {banners &&
                                    <TextField
                                        name="banner_guid"
                                        disabled={false}
                                        select
                                        fullWidth
                                        value={item?.banner_guid ?? ""}
                                        label="banner"
                                        onChange={handleChange}
                                    >
                                        {banners.data.map((option) => (
                                            <MenuItem key={option.guid} value={option.guid}>
                                                <CardMedia
                                                    sx={{ cursor: "pointer" }}
                                                    component="img"
                                                    height="120"
                                                    image={option.guid_immagine ? `/db/media/get?guid=${option.guid_immagine}` : option.image_url}
                                                />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            </UiFrmControl>

                        </UiPaper>

                        <UiFormInfo item={item} />

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                onClick={async () => {
                                    if (!window.confirm("confermi"))
                                        return false;
                                    if (await Api.Settore.del(item, dispatch)) {
                                        dispatch(showInfo("il record è stato eliminato correttamente"));
                                        navigate(-1);
                                    }
                                }}>
                                <DeleteIcon />
                            </Fab>
                            <Fab color="secondary"
                                onClick={async (e) => {
                                    if (await Api.Settore.save(item, dispatch)) {
                                        dispatch(showInfo("il record è stato modificato correttamente"));
                                        navigate(-1);
                                    }
                                    return false;
                                }}
                            >
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})