import React, { useEffect } from 'react';
import {
    Autocomplete,
    Box,
    Stack, TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HookIsMobile, UiContainer } from "../../utils/Helpers";
import Fab from "@mui/material/Fab";
import ClearIcon from "@mui/icons-material/Clear";
import Api from "../../Api";

export default function CartProdottiFilters(props) {

    let { tipologia } = useParams<any>();

    const [filters, setfilters] = React.useState<any>({});
    const [sottocategorie, setSottocategorie] = React.useState<any>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();

    useEffect(() => {
        props.onFilter(filters);
    }, [filters])

    useEffect(() => {
        (async () => {
            setSottocategorie((await Api.SottoCategoria.all({
                guid: props.categoria,
                tipologia: tipologia,
                available: true
            }, dispatch))?.data);
        })();
    }, [])

    const handleChange = (event: any) => {
        setfilters({
            ...filters,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <React.Fragment>
            <UiContainer hideBox>
                <Box sx={{ mb: 2 }}>
                    <Stack spacing={2}
                        direction="row"
                        justifyContent="start"
                        alignItems="start">
                        <TextField
                            fullWidth
                            name="global"
                            value={filters.global}
                            onChange={handleChange}
                            label="nome"
                            variant="outlined" />
                    </Stack>
                </Box>
            </UiContainer>
        </React.Fragment>
    )
}