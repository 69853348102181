import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams} from "react-router-dom";
import {BaseGrid} from "../../../components/BaseGrid";
import Api from "../../../Api";


export default function Prodotti(props) {

    const navigate = useNavigate();
    let tipologia: any = null;
    const location = useLocation();

    useEffect(() => {
    }, [tipologia])

    if (location.pathname.includes("/prodotti"))
        tipologia = "prodotto";
    if (location.pathname.includes("/servizi"))
        tipologia = "servizio";
    if (location.pathname.includes("/logistica"))
        tipologia = "logistica";

    return (
        <React.Fragment>
            {tipologia &&
                <BaseGrid
                    key={tipologia}
                    id={"prodotti"}
                    exportUrl={"/db/prodotto/export"}
                    getDataSourceAsync={Api.Prodotto.all}
                    onRowClick={(row) => {
                        navigate(`${location.pathname}/edit/${row.sku}`);
                    }}
                    onNew={() => {
                        navigate(`${location.pathname}/nuovo/inserisci`)
                    }}
                    filtersDefault={{ tipologia: tipologia }}
                    headers={[
                        {
                            id: 'sku',
                            label: 'Sku',
                            chip: true,
                            bold: true,
                            mobile: true,
                        },
                        {
                            id: 'nome',
                            label: 'Nome',
                            mobile: true,
                        },
                        {
                            id: 'descrizione',
                            label: 'Descrizione',
                            width: "30%"
                        },
                        {
                            id: 'nome_fornitore',
                            label: 'Fornitore',
                            width: "30%"
                        },
                        {
                            id: 'quantita',
                            label: 'Quantit\u00E0',
                            chip: true,
                        },
                        {
                            id: 'preferito',
                            label: 'In vetrina',
                            check: true,
                        },
                        {
                            id: 'nascosto',
                            label: 'Nascosto',
                            check: true,
                        },
                        {
                            id: 'notification',
                            label: 'Notifica',
                            chip: true,
                        },
                    ]}
                    filters={[[
                        {
                            id: 'global',
                            label: 'Cerca',
                        },
                        {
                            id: 'nome_fornitore',
                            label: 'Cerca fornitore',
                        },
                        {
                            id: 'tipologia',
                            label: 'Tipologia',
                            options: ["Prodotto", "Servizio", "Logistica"],
                            disabled: true,
                        },
                    ]]}
                />
            }
        </React.Fragment>)
}

