import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Stack } from "@mui/material";
import Box from '@mui/material/Box';
import { validate as isValidUUID } from 'uuid';
import Chip from "@mui/material/Chip";
import { HookIsMobile } from "../utils/Helpers";
import { SearchAll } from "./SearchAll";
import { getLabel, truncate } from "../utils/Functions";
import { RootStateOrAny, useSelector } from "react-redux";

export function BreadCrumb() {
    const navigate = useNavigate();
    const im = HookIsMobile();
    const root = useSelector((state: RootStateOrAny) => state);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    let i: number = 0;
    return (
        <Stack sx={{
            backgroundColor: root?.GlobalTheme?.main_bgcolor,
            px: {
                xs: 2,
                md: 3,
            },
            py: 1.5,
        }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
                    <Chip sx={{fontWeight: 700, cursor: "pointer"}} size={"small"}
                          label={"home"} onClick={() => {
                        navigate("/dashboard");
                    }}/>

                    {im && pathnames.length > 1 &&
                        <span>...</span>
                    }

                    {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        if (isValidUUID(value))
                            return null;
                        if (im && !last)
                            return null;
                        i = i + 1;
                        if (value == "edit")
                            return null;
                        if (value == "nuovo")
                            return null;
                        if (value == "anagrafiche")
                            return null;
                        if (value == "categorie")
                            return null;
                        if (value == "catalogo")
                            return null;
                        if (value == "default")
                            return null;
                        if (last)
                            return (
                                <Chip sx={{fontWeight: 700}} size={"small"}
                                      label={truncate(decodeURIComponent(getLabel(value, root)).replaceAll("-", " "), 25)}
                                      color={"secondary"}
                                />
                            )
                        return (
                            <Chip sx={{fontWeight: 700, cursor: "pointer"}} size={"small"}
                                  label={truncate(decodeURIComponent(getLabel(value, root)).replaceAll("-", " "), 25)}
                                  onClick={() => {
                                      
                                      navigate(to);
                                  }}/>
                        )
                    })}
                </Breadcrumbs>
            </Box>
            {!im &&
                <SearchAll/>
            }
        </Stack>
    );
}