import React, {useEffect} from 'react';

import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";


import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, CircularProgress, MenuItem} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Api } from '../../../../Api';
import {setLoadingMessage, showInfo} from "../../../../reducers";
import {
    PageHeader,
    UiColorPicker,
    UiContainer,
    UiFabContainer,
    UiFrmControl,
    UiPaper,
    UiSkeletonForm
} from "../../../../utils/Helpers";
import {validationError, validationMessage, validationState} from "../../../../utils/Functions";
import {DbNewsDel} from "../../../../Db";

export default React.memo(function NewsCategoriaEdit() {
    let { guid } = useParams<any>();
    let { conferma } = useParams<any>();
    const [item, setItem] = React.useState<any>(null);
    const [macrocategorie, setmacrocategorie] = React.useState<any>(null);
    const [validation, setValidation] = React.useState<Array<any>>([
        {
            id: "nome",
            required: true,
        },
    ]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        var dbNews = (await Api.NewsCategoria.all({ guid: guid }, dispatch));
        if (guid != "")
            setItem(dbNews);
        else
            setItem({});
    }

    useEffect(() => {
        (async () => {
            dispatch(setLoadingMessage("caricamento in corso..."))
            await reload();
            setmacrocategorie(await Api.NewsMacroCategoria.all(null, dispatch));
            dispatch(setLoadingMessage(null));
        })();
    }, [])
    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"categoria"}>
                            <React.Fragment>
                                <UiFrmControl first>
                                    <TextField
                                        disabled
                                        name="guid"
                                        label="guid"
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.guid ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="nome"
                                        label="nome"
                                        error={validationError(validation, "nome")}
                                        helperText={validationMessage(validation, "nome")}
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.nome ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <UiColorPicker
                                        name="colore"
                                        label="colore"
                                        defaultValue={item?.colore ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="guid_macrocategoria"
                                        select
                                        fullWidth
                                        defaultValue={item?.guid_macrocategoria ?? ""}
                                        label="macro categoria"
                                        onBlur={handleChange}
                                    >
                                        {macrocategorie?.data?.map((option) => (
                                            <MenuItem key={option.guid} value={option.guid}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </UiFrmControl>
                            </React.Fragment>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <React.Fragment>
                                <Fab color="primary" size="small"
                                     onClick={async () => {
                                         if (!window.confirm("Confermi?"))
                                             return false;
                                         if (await DbNewsDel(item, dispatch)) {
                                             dispatch(showInfo("il record è stato eliminato correttamente"));
                                             navigate(-1);
                                         }
                                     }}>
                                    <DeleteIcon/>
                                </Fab>
                                <Fab color="secondary"
                                     onClick={async (e) => {
                                         if (validationState(item, validation, setValidation) == false)
                                             return false;
                                         dispatch(setLoadingMessage("salvataggio in corso..."))
                                         if (await Api.NewsCategoria.save(item, dispatch)) {
                                             dispatch(showInfo("il record è stato modificato correttamente"));
                                             navigate(-1);
                                             dispatch(setLoadingMessage(null))
                                             return false;
                                         } else {
                                             dispatch(setLoadingMessage(null))
                                             await reload();
                                         }
                                         dispatch(setLoadingMessage(null))
                                         return false;
                                     }}>
                                    <SaveIcon/>
                                </Fab>
                            </React.Fragment>
                            <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm />}
            </UiContainer>
        </ React.Fragment>
    );
})