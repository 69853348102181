import React, { useEffect } from 'react';
import {
    setLoadingMessage,
    showInfo,
} from "../../../reducers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer
} from "../../../utils/Helpers";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, CircularProgress, FormControlLabel, MenuItem } from "@mui/material";
import Api from "../../../Api";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import StarOutline from "@mui/icons-material/StarOutline";
import StarRate from "@mui/icons-material/StarRate";


export default React.memo(function VideoEdit() {

    let { guid } = useParams<any>();

    const [item, setItem] = React.useState<any>(null);

    const [settori, setSettori] = React.useState<any>(null);
    const [settoriSelected, setSettoriSelected] = React.useState<any>(null);
    const [settoriOpen, setSettoriOpen] = React.useState(false);
    const [settoriLoading, setSettoriLoading] = React.useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (settori == null && settoriOpen == true) {
                setSettoriLoading(true);
                setSettori((await Api.Settore.all(null, dispatch)).data);
                setSettoriLoading(false);
            }
        })();
    }, [settoriOpen]);

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };


    async function reload() {
        const dbItem = await Api.Video.all({ guid: guid }, dispatch);
        setSettoriSelected((await Api.Video.settore({ guid: dbItem.guid }, dispatch)).data);
        if (guid != null)
            setItem(dbItem);
        else
            setItem({});
    }

    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper first padding title={"gestione video"}>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    defaultValue={item?.guid ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="youtube_id"
                                    label="youtube id"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 11,
                                    }}
                                    required
                                    type="text"
                                    defaultValue={item?.youtube_id ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <FormControlLabel control={<Checkbox checked={item?.is_default ?? false}
                                    name="is_default"
                                    onChange={handleChangeCheckbox} />} label="default" />
                            </UiFrmControl>
                            <UiFrmControl>
                                <Autocomplete
                                    multiple
                                    options={settori ?? []}
                                    getOptionLabel={(option: any) => option?.nome as any}
                                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                    onOpen={() => {
                                        setSettoriOpen(true);
                                    }}
                                    onClose={() => {
                                        setSettoriOpen(false);
                                    }}
                                    loading={settoriLoading}
                                    value={settoriSelected ?? []}
                                    onChange={(_, newValue) => {
                                        setSettoriSelected(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            name={"settori"}
                                            label="categorie"
                                            placeholder="categorie"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {settoriLoading ?
                                                            <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </UiFrmControl>
                            <UiFrmControl>

                                <TextField
                                    select
                                    name={"notification"}
                                    label={"notifica"}
                                    defaultValue={item.notification ?? "NONE"}
                                    onBlur={handleChange}
                                >
                                    <MenuItem value={"PUSH"}>PUSH</MenuItem>
                                    <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
                                    <MenuItem value={"ANY"}>ENTRAMBE</MenuItem>
                                    <MenuItem value={"NONE"}>NESSUNA</MenuItem>
                                </TextField>
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                onClick={async () => {
                                    if (!window.confirm("confermi"))
                                        return false;
                                    if (await Api.Video.del(item, dispatch)) {
                                        dispatch(showInfo("il record è stato eliminato correttamente"));
                                        navigate(-1);
                                    }
                                }}>
                                <DeleteIcon />
                            </Fab>
                            <Fab color="secondary"
                                onClick={async (e) => {
                                    item.settori = settoriSelected;
                                    dispatch(setLoadingMessage("salvataggio in corso..."))
                                    if (await Api.Video.save(item, dispatch)) {
                                        dispatch(showInfo("il record è stato modificato correttamente"));
                                        navigate(-1);
                                        dispatch(setLoadingMessage(null))
                                    } else {
                                        dispatch(setLoadingMessage(null))
                                        await reload();
                                    }
                                    dispatch(setLoadingMessage(null))
                                    return false;
                                }}
                            >
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})