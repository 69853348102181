import React, { useEffect } from 'react';
import {
    Box,
    Stack,
} from "@mui/material";
import {useDispatch} from "react-redux";
import {DbCategoria} from "../../Db";
import {useNavigate, useParams} from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import { isSmallScreen, Log } from "../../utils/Functions";
import { HookIsMobile, UiContainer, UiFabContainer } from "../../utils/Helpers";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Banner from '../../components/banner/Banner';

export default function CartCategorieItem_WOLF({ data, tipologia, onClick }) {

    const navigate = useNavigate();
    const im = HookIsMobile();

    return (
        <React.Fragment>
            <Box
                onClick={() => {
                    onClick();
                }}
                sx={{
                    backgroundImage: `url(/db/media/get?guid=${data?.guid_immagine})`,
                    backgroundSize: "cover",
                    borderRadius: 5,
                    position: "relative",
                    visibility: data == null ? "hidden" : "visible",
                    px: 2,
                    display: "flex",
                    width: "100%",
                    height: 200,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "cen\ter",
                    cursor: "pointer",
                }}>
                <Box sx={{
                    p: .5,
                    pr: 1,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    width: "auto",
                    textAlign: "left",
                    display: "block",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "70%",
                    left: "0%",
                    transform: "translate(0%, -0%)"
                }}>
                    <Typography sx={{ textTransform: "uppercase", fontWeight: 700 }}
                        variant={"caption"}>{data?.nome}</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}
