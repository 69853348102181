import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BaseGrid } from '../BaseGrid';
import Api from "../../Api";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { PageHeader, UiContainer, UiFrmControl, UiPaper } from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import { Box, Button, Checkbox, FormControlLabel, MenuItem, Stack } from "@mui/material";
import { ClearCartItems, setDialogMessage, setLoadingMessage, showInfo } from "../../reducers";
import { currencyFormat, reloadCredito, reloadNumbers } from "../../utils/Functions";
import TextEllipsis from 'react-text-ellipsis';
import StripeSpedizione from "./StripeSpedizione";
import { DbComune } from "../../Db";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { TextNormal, TextTitleSmall } from "../../utils/Text";

export default function StripePaymentForm(props) {

    const navigate = useNavigate();
    const [success, setSuccess] = React.useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [prodotti, setProdotti] = React.useState<any>(null);
    const [spedizione, setSpedizione] = React.useState<any>(null);
    const [tipoPagamento, setTipoPagamento] = React.useState<any>("-1");
    const [fatturazione, setFatturazione] = React.useState<any>(null);
    const root = useSelector((state: RootStateOrAny) => state);
    const [tot, setTot] = React.useState<any>(0);
    const [dorsale, setDorsale] = React.useState<any>(null);
    let cartProd = Array<any>();

    useEffect(() => {

        (async () => {
            setDorsale(await Api.Dorsale.main(null, dispatch));
            var dbAnagrafica = await Api.Anagrafica.all({ mine: true }, dispatch);
            setSpedizione(dbAnagrafica);
            setFatturazione(dbAnagrafica);
        })();

        const cats = root.CartItems.reduce((catsSoFar, { sku }) => {
            if (!catsSoFar[sku]) catsSoFar[sku] = [];
            catsSoFar[sku].push(sku);
            return catsSoFar;
        }, {});

        var prodotti = Object.keys(cats).map(function (key) {
            return cats[key]
        });

        setProdotti(prodotti);

        setTot(root.CartItems.map((n) => n.offerta != null ? n.offerta : n.prezzo).reduce((a, b) => {
            return a + b;
        }, 0));

        cartProd = new Array<any>();

        if (prodotti.length == 0)
            navigate(-1);

    }, [root.CartItems])

    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={"Prodotti"} />
                <UiPaper padding>
                    <UiFrmControl first>
                        <Box sx={{ width: { xs: "90vw", lg: "100%" }, overflowX: { xs: "scroll", lg: "hidden" } }}>
                            <table style={{ width: "100%" }} cellPadding={6}>
                                <tr style={{
                                    fontWeight: "bold",
                                }}>
                                    <td style={{ width: "50%" }}>Nome</td>
                                    <td style={{ width: "100%" }}>Descrizione</td>
                                    <td>Quantità</td>
                                    <td style={{
                                        textAlign: "right",
                                        whiteSpace: "nowrap"
                                    }}>Prezzo
                                    </td>
                                </tr>
                                {prodotti && prodotti.length > 0 && prodotti.sort().map((row, index) => {
                                    const prod = root.CartItems.filter(x => x.sku == row[0])[0];
                                    if (prod == null)
                                        return (null);
                                    cartProd.push({ prodotto: prod, qty: row.length });
                                    return (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td style={{
                                                    width: "50%",
                                                }}>
                                                    <TextEllipsis
                                                        lines={1}>
                                                        {prod.nome}
                                                    </TextEllipsis>
                                                </td>
                                                <td style={{ width: "100%" }}>
                                                    <TextEllipsis
                                                        lines={2}>
                                                        {prod.descrizione}
                                                    </TextEllipsis>
                                                </td>
                                                <td style={{
                                                    whiteSpace: "nowrap"
                                                }}>{row.length}</td>
                                                <td style={{
                                                    textAlign: "right",
                                                    whiteSpace: "nowrap"
                                                }}>{prod.offerta == null ? currencyFormat(prod.prezzo) : currencyFormat(prod.offerta)}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                <tr>
                                    <td colSpan={3}>

                                    </td>
                                    <td style={{
                                        fontWeight: "bold",
                                        textAlign: "right",
                                        whiteSpace: "nowrap"
                                    }} colSpan={1}>
                                        {currencyFormat(tot)}
                                    </td>
                                </tr>
                            </table>
                        </Box>
                    </UiFrmControl>
                </UiPaper>
                <Stack mt={3} direction={"row"} alignItems={"stretch"}
                    justifyContent={"space-between"}
                    alignContent={"space-between"} spacing={3}
                    sx={{ width: "100%" }}>


                    <Box sx={{ width: "100%" }}>
                        <PageHeader title={"Dati Fatturazione"} />
                        <form action="#" id="form_fatturazione">
                            <StripeSpedizione
                                readOnly={true}
                                setData={setFatturazione}
                                data={fatturazione}
                            />
                        </form>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <PageHeader title={"Dati Spedizione"} />
                        <form action="#" id="form_spedizione">
                            <StripeSpedizione
                                setData={setSpedizione}
                                data={spedizione}
                            />
                        </form>
                    </Box>
                </Stack>
                {dorsale?.tipologia == "ENTERPRISE" &&
                    <React.Fragment>
                        <Box mt={3}>
                            <PageHeader title={"Informazioni di pagamento"} />
                        </Box>
                        <UiFrmControl>
                            <form action="#" id="form_pagamento">
                                <TextField
                                    name={"tipo_pagamento"}
                                    select
                                    disabled={dorsale?.paga_offline == true}
                                    required
                                    value={dorsale?.paga_offline == false ? tipoPagamento : "OFFLINE"}
                                    onChange={(e) => {
                                        setTipoPagamento(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"-1"}>-- SELEZIONA PAGAMENTO --</MenuItem>
                                    <MenuItem value={"CARTA"}>CON CARTA DI CREDITO</MenuItem>
                                    <MenuItem value={"OFFLINE"}>OFFLINE</MenuItem>
                                </TextField>
                            </form>
                            {tipoPagamento == "CARTA" &&
                                <TextTitleSmall sx={{ mt: 1 }}>* Testo Spiegazione Carta</TextTitleSmall>
                            }
                            {tipoPagamento == "OFFLINE" &&
                                <TextTitleSmall sx={{ mt: 1 }}>* Testo Spiegazione Offline</TextTitleSmall>
                            }
                        </UiFrmControl>
                    </React.Fragment>
                }
                <UiFrmControl style={{ display: tipoPagamento == "CARTA" ? "block" : "none" }} component="form"
                    id={"myForm"}
                    autoComplete="off">
                    <UiPaper padding>
                        <UiFrmControl first>
                            <CardElement />
                        </UiFrmControl>
                    </UiPaper>
                </UiFrmControl>
                <Box mt={4}>
                    <Button sx={{ mb: 3 }} variant={"contained"}
                        size={"large"}
                        endIcon={<CreditCardIcon />}
                        onClick={async (e) => {
                            let reportVal = true;
                            let paymentId = null;

                            const form = document.querySelector("#form_spedizione") as HTMLFormElement;
                            const form_pagamento = document.querySelector("#form_pagamento") as HTMLFormElement;
                            const ragione_sociale = document.querySelector('#form_spedizione input[name="ragione_sociale"]') as HTMLInputElement;
                            const nome = document.querySelector('#form_spedizione input[name="nome"]') as HTMLInputElement;
                            const cognome = document.querySelector('#form_spedizione input[name="cognome"]') as HTMLInputElement;
                            const tipo_pagamento = document.querySelector('#form_pagamento input[name="tipo_pagamento"]') as HTMLSelectElement;

                            if ((nome.value === "" || cognome.value === "") && ragione_sociale.value === "") {
                                ragione_sociale?.setCustomValidity("Inserire la Ragione Sociale o Nome e Cognome");
                                reportVal = form.reportValidity();
                                return false;
                            }
                            ragione_sociale?.setCustomValidity("");

                            if (dorsale?.tipologia == "ENTERPRISE") {
                                if (tipo_pagamento.value === "-1" || tipo_pagamento.value == null) {
                                    tipo_pagamento?.setCustomValidity("Inserire la tipologia di pagamento");
                                    reportVal = form_pagamento.reportValidity();
                                    return false;
                                }
                                tipo_pagamento?.setCustomValidity("");
                            }

                            if (reportVal)
                                reportVal = form.reportValidity();

                            if (!reportVal)
                                return false;

                            if (dorsale?.tipologia == "ENTERPRISE" && tipoPagamento == "CARTA") {
                                // @ts-ignore
                                const { error, paymentMethod } = await stripe?.createPaymentMethod({
                                    type: "card",
                                    // @ts-ignore
                                    card: elements.getElement(CardElement)
                                });

                                if (error) {
                                    dispatch(setDialogMessage(error.message));
                                    return;
                                }

                                if (error)
                                    return false;

                                if (!error) {
                                    paymentId = paymentMethod.id;
                                }
                            }

                                dispatch(setDialogMessage(null));
                                dispatch(setLoadingMessage("sto processando l'ordine, attendi...."));
                                const res = await Api.Ordine.checkout({
                                    cart: cartProd,
                                    spedizione: spedizione,
                                    paymentId: paymentId,
                                }, dispatch);
                                if (res != true && res != false) {
                                    dispatch(setDialogMessage(res));
                                }
                                if (res == true) {
                                    localStorage.removeItem("CartItems");
                                    dispatch(showInfo("l'ordine è stato registrato"));
                                    dispatch(ClearCartItems(null));
                                    await reloadNumbers(dispatch);
                                    await reloadCredito(root, dispatch);
                                    dispatch(setDialogMessage(null));
                                    if (tipoPagamento == "CARTA")
                                        navigate("/pagamento-ok-carta")
                                    else
                                        navigate("/pagamento-ok-offline")
                                }
                                dispatch(setLoadingMessage(null));


                        }}>
                        {tipoPagamento == "OFFLINE" ? "INVIA L'ORDINE" : "PAGA E INVIA L' ORDINE"}
                    </Button>
                </Box>
            </UiContainer>
        </React.Fragment>
    )
}