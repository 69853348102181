import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {setLoadingMessage, showInfo} from "../../../reducers";
import Api from "../../../Api";
import {
    PageHeader,
    UiContainer,
    UiFabContainer,
    UiFileUploader,
    UiFrmControl,
    UiPaper,
    UiSkeletonForm
} from "../../../utils/Helpers";
import Box from "@mui/material/Box";
import {validationError, validationMessage, validationState} from "../../../utils/Functions";
import RefreshIcon from "@mui/icons-material/Refresh";


export default React.memo(function CondizioniEdit() {

    let { guid } = useParams<any>();

    const [item, setItem] = React.useState<any>(null);
    const [file, setFile] = React.useState<any>(null);
    const [fileDelete, setFileDelete] = React.useState<any>(null);
    const [media, setMedia] = React.useState<any>(null);
    const [validation, setValidation] = React.useState<Array<any>>([
        {
            id: "nome",
            required: true,
        },
        {
            id: "text",
            required: true,
        }
    ]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            dispatch(setLoadingMessage("caricamento in corso..."));
            await reload();
            dispatch(setLoadingMessage(null));
        })();
    }, [])

    async function reload() {
        if (guid != null) {
            const dbCondizione = await Api.Condizione.all({ guid: guid }, dispatch);
            setMedia(await Api.Media.all({ guid: dbCondizione.guid_file }, dispatch));
            setItem(dbCondizione);
        }
        else
            setItem({});

    }

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <React.Fragment>
            {/*            {JSON.stringify(item)}*/}
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"gestione condizioni prodotto"}>
                            <UiFrmControl first>
                                <TextField
                                    disabled
                                    name="guid"
                                    label="guid"
                                    fullWidth
                                    required
                                    type="text"
                                    value={item?.guid ?? ""}
                                    onChange={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="nome"
                                    label="nome"
                                    error={validationError(validation, "nome")}
                                    helperText={validationMessage(validation, "nome")}
                                    fullWidth
                                    required
                                    type="text"
                                    value={item?.nome ?? ""}
                                    onChange={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    name="text"
                                    label="testo"
                                    error={validationError(validation, "text")}
                                    helperText={validationMessage(validation, "text")}
                                    fullWidth
                                    required
                                    multiline
                                    maxRows={10}
                                    type="text"
                                    defaultValue={item?.text ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <UiFileUploader
                                    media={media}
                                    onDelete={(e) => {
                                        setFileDelete(e);
                                    }}
                                    onChange={(e) => {
                                        setFile(e);
                                    }} />
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            <Fab color="primary" size="small"
                                onClick={async () => {
                                    if (!window.confirm("confermi"))
                                        return false;
                                    if (await Api.Condizione.del(item, dispatch)) {
                                        dispatch(showInfo("il record è stato eliminato correttamente"));
                                        navigate(-1)
                                    }
                                }}>
                                <DeleteIcon />
                            </Fab>
                            <Fab color="secondary"
                                onClick={async (e) => {
                                    if (validationState(item, validation, setValidation) == false)
                                        return false;
                                    dispatch(setLoadingMessage("salvataggio cin corso..."));
                                    if (await Api.Condizione.save(item, [
                                        {
                                            name: "file",
                                            value: file,
                                        },
                                        {
                                            name: "fileDelete",
                                            guid: fileDelete
                                        }
                                    ], dispatch)) {
                                        dispatch(setLoadingMessage(null));
                                        navigate(-1)
                                    }
                                    dispatch(setLoadingMessage(null));
                                }}
                            >
                                <SaveIcon />
                            </Fab>
                            <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                        </UiFabContainer>


                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})