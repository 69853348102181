import { Avatar, Box, Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import TextEllipsis from "react-text-ellipsis";
import { getNewImmagine } from "../../src/utils/Functions";
import { HookIsMobile } from "../utils/Helpers";
import { TextDescription, TextTitle } from "../utils/Text";

export interface BaseDisplayCardProps {
    titolo?: string;
    sottotitolo?: string;
    descrizione?: string;
    guid_immagine?: any;
    onClickRedirect?: any;
    isCardClickable: boolean;
}

export default function BaseCardDisplay(props: BaseDisplayCardProps) {
    // const imageSizeH = 300;
    const im = HookIsMobile();
    const imageSizeW = im ? window.innerWidth : 200;
    const root = useSelector((state: RootStateOrAny) => state);
    const navigate = useNavigate();
    const imageCard = props.guid_immagine
        ? getNewImmagine(props.guid_immagine)
        : "https://biellaup.it/static-content/images/distretto_commercio_logo.png";

    return (
        <Card
            elevation={props.isCardClickable ? 2 : 0}
            onClick={props.onClickRedirect}
            sx={{
                height: 330,
                width: im ? "100%" : 280,
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                border: "solid 1px #1B1B1B",
                borderColor: root?.GlobalTheme?.menu_bgcolor,
                marginBottom: 3,
                marginRight: 3,
                borderRadius: 4,
                cursor: props.isCardClickable ? "pointer" : "auto",
            }}>
            <Avatar
                variant='rounded'
                src={imageCard}
                imgProps={{ style: { borderRadius: "16px", maxHeight: '148px', maxWidth: '264px' } }}
                sx={{
                    width: 280,
                    margin: "0 auto",
                    height: "50%",
                    padding: '8px'
                }}
            />
            <CardHeader
                sx={{ height: 74, padding: "0px 16px" }}
                title={
                    <TextEllipsis lines={2}>
                        <TextTitle>{props.titolo}</TextTitle>
                    </TextEllipsis>
                }
                subheader={props.sottotitolo !== undefined && props.sottotitolo}
            />
            <CardContent sx={{ padding: "0px 16px 16px" }}>
                <TextDescription>
                    <TextEllipsis ellipsisChars={"..."} lines={3}>
                        {props.descrizione || "Questo fornitore si affida a Dorsale!"}
                    </TextEllipsis>
                </TextDescription>
            </CardContent>
        </Card>
    );
}
