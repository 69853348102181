import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {v4 as uuidv4} from 'uuid';
import Moment from "moment";
import Grid from "@mui/material/Grid";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import {Button, Stack} from "@mui/material";
import Chip from "@mui/material/Chip";
import {UiContainer} from "../../../../utils/Helpers";
import Api from "../../../../Api";

export default function RichiestePrezzo(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>(null);
    const [filters, setFilters] = React.useState<any>(null);
    const [key, setKey] = React.useState<any>(uuidv4());

    useEffect(() => {
        (async () => {
            console.log(filters);
            setItems((await Api.RichiestaPrezzo.head(filters, dispatch)));
        })();
    }, [filters])

    function Filters(props) {
        return (
            <React.Fragment>
                <Grid sx={{ mb: 2 }} container alignItems={"stretch"} justifyContent={"stretch"}
                    alignContent={"stretch"}
                    spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="cliente"
                            label="cliente"
                            fullWidth
                            type="text"
                            defaultValue={filters?.cliente ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    cliente: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="fornitore"
                            label="fornitore"
                            fullWidth
                            type="text"
                            defaultValue={filters?.fornitore ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    fornitore: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            name="sku"
                            label="prodotto"
                            fullWidth
                            type="text"
                            defaultValue={filters?.sku ?? ""}
                            onBlur={(e) => {
                                setFilters({
                                    ...filters,
                                    sku: e?.target.value,
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DatePicker
                            label="data inizio"
                            value={filters?.dataInizio ?? null}
                            onChange={(newValue) => {
                                setFilters({
                                    ...filters,
                                    dataInizio: Moment(newValue).format(),
                                });
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <DatePicker
                            label="data fine"
                            value={filters?.dataFine ?? null}
                            onChange={(newValue) => {
                                setFilters({
                                    ...filters,
                                    dataFine: Moment(newValue).format(),
                                });
                            }}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </Grid>
                </Grid>
                <Stack sx={{ mb: 2 }} spacing={2}
                    direction={"row"}
                    justifyContent="start"
                    alignItems="center">
                    <Button variant={"contained"} color={"secondary"}>cerca</Button>
                    <Button variant={"contained"} color={"secondary"} onClick={() => {
                        setFilters(null);
                        setKey(uuidv4());
                    }}>azzera filtri</Button>
                </Stack>
            </React.Fragment>
        )
    }

    const tableCellStyle = {
        fontWeight: 700,
    };

    const tableRowStyleNoBorder = {
        fontWeight: 700,
        borderBottomWidth: 0,
    };

    const tableCellStyleNoBorder = {
        borderBottomWidth: 0,
    };

    function Row(props: { row: any }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow onClick={() => setOpen(!open)} hover={true} selected={open}
                    sx={{
                        cursor: "pointer",
                        borderBottomStyle: "hidden",
                        '& > *': { borderBottom: 'unset' }
                    }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Chip label={row.sku}></Chip>
                    </TableCell>
                    <TableCell align="left">{row.nome}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ p: 0 }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{
                                p: 1,
                            }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Richieste
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">data</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">nome</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">ragione
                                                sociale</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">tipologia</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="left">contatto</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="right">inviato</TableCell>
                                            <TableCell sx={tableRowStyleNoBorder} align="right">visualizzato</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.history.map((historyRow) => (
                                            <TableRow onClick={() => {
                                                /*                  history.push(`/ordini/edit/${navigateRow.guid}/${historyRow.prodotto_nome}`);*/
                                            }} hover={true} sx={{cursor: "pointer"}} key={uuidv4()}>
                                                <TableCell sx={tableCellStyleNoBorder} align="left" component="th"
                                                    scope="row">
                                                    {Moment(historyRow.data).format('DD/MM/YY HH:mm')}
                                                </TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.nome}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.ragione_sociale}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.tipo_contatto}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="left">{historyRow.contatto}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="right">{Moment(historyRow.data).format('DD/MM/YY HH:mm')}</TableCell>
                                                <TableCell sx={tableCellStyleNoBorder}
                                                    align="right">{Moment(historyRow.data_lettura).format('DD/MM/YY HH:mm')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <UiContainer>
                {/*                <Filters key={key}/>*/}
                {items &&
                    <Paper elevation={0}
                        sx={{ width: { xs: "90vw", lg: "100%" }, overflowX: { xs: "scroll", lg: "hidden" } }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box sx={{ textAlign: "left", pt: 1, px: 2 }}>
                                {items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati{items.tot} risultati in {items.time} millisecondi
                                    </Typography>
                                }
                                {!items.time &&
                                    <Typography variant={"caption"} sx={{ fontStyle: "italic" }}>
                                        Trovati{items.tot} risultati
                                    </Typography>
                                }
                            </Box>
                        </Stack>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell sx={tableCellStyle} align="left">sku</TableCell>
                                    <TableCell sx={tableCellStyle} align="left">prodotto</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.data &&
                                    items.data.map((row) => (
                                        <Row key={uuidv4()} row={row} />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                }
            </UiContainer>
        </React.Fragment>
    );
}

