import React from 'react';

interface Tab3Props {
    // Inserire le prop necessarie per il contenuto della Tab1
}

const Tab3: React.FC<Tab3Props> = (props) => {
    // Implementare il contenuto della Tab1
    return (
        <div>
            <p><strong>ALLEGATO 1 – INFORMATIVA PRIVACY RESA AI CLIENTI DELLA DORSALE DIGITALE (ALLEGATO 1.A) E INFORMATIVA PRIVACY RESA AL VENDITORE (ALLEGATO 1.B)</strong></p>

            <p>
                <strong>1. Titolare del trattamento</strong><br />
                Titolare del trattamento dei dati personali è ARXIT s.r.l.., società, con sede legale in Torino, Italia, Via Villar Focchiardo 31, Partita IVA/codice fiscale 05476950968 iscritta al Registro delle Imprese al numero TO-1172567 (“ARXIT” o “Titolare”), tel: +39 011 198.638.74 e-mail info@arxit.it.
            </p>

            <p>
                Solo nel caso di acquisto di un prodotto/servizio venduto - nell’ambito della DORSALE DIGITALE - da un Venditore Terzo, come di volta in volta individuato nella scheda del prodotto/servizio prescelto dall’utente, il Venditore Terzo assumerà il ruolo di titolare autonomo dei dati personali dell’utente inseriti nel modulo d’ordine nonché nella eventuale corrispondenza, connessa all’acquisto online, o alla qualificazione dell’opportunità quando non possibile dichiarare prima il prezzo (consulenze o servizi complessi) intercorsa attraverso il sistema di messaggistica interna della DORSALE DIGITALE (in particolare, attraverso le sezioni “Contatta il Venditore”, “Valuta il Venditore” e “Chiedi un reso”, accessibili all’utente registrato al Sito). In tale caso, troveranno applicazione, anche con riferimento al Venditore Terzo, le seguenti disposizioni della presente informativa:
            </p>

            <ul>
                <li>
                    punto 2 (Finalità del trattamento) solo per le finalità di cui alle lettere (b) – conclusione del contratto e assistenza post-vendita - e (c) - attività amministrativo-contabili - e nel paragrafo “dati sensibili”;
                </li>
                <li>
                    punto 3 (Conferimento dei dati e conseguenze in caso di mancato assenso al trattamento) solo per le finalità di cui alle lettere (b) e (c) del punto 2;
                </li>
                <li>
                    punto 4 (Modalità del trattamento dei dati);
                </li>
                <li>
                    punto 5 (Ambito di comunicazione dei dati) limitatamente ai punti da (v) a (viii);
                </li>
                <li>
                    punto 6 (Conservazione dei dati), ultimo paragrafo;
                </li>
                <li>
                    punto 7 (Diritto di accesso ai dati personali).
                </li>
            </ul>

            <p>
                <strong> 2.    Finalità del trattamento</strong><br />
            ARXIT s.r.l. tratterà i dati personali forniti dagli utenti per le seguenti finalità:
            </p>

            <ul>
                <li>
            a.    per consentire la registrazione al Sito e di usufruire dei servizi riservati agli utenti registrati,  ivi inclusa la possibilità di effettuare un acquisto online anche nell’ambito della DORSALE DIGITALE, sulla quale i valori economici e i servizi resi sono per lo più dedicati all’ambito in cui agisce la Dorsale Digitale e a condizioni economiche di assoluto favore .
                </li> <li>
                    b.    per consentire la conclusione del contratto di acquisto e la corretta esecuzione delle operazioni  connesse al medesimo, ivi incluse le attività di assistenza post-vendita. Inoltre, gli utenti, anche se non registrati, potranno inviare richieste di informazioni o chiarimenti sui prodotti in vendita  sulla DORSALE DIGITALE e, per dare corso a tale richiesta, ARXIT  richiederà il conferimento di alcuni dati personali  (es. nome, cognome e indirizzo e-mail);
                </li><li>
                    c.    per finalità amministrativo-contabili nonché per adempiere a obblighi di legge;
                </li><li>
                    d.    previo consenso espresso dell’utente, per l’invio di  comunicazioni informative e promozionali (inclusa  la “newsletter”), buoni sconto e offerte speciali, in relazione a prodotti e servizi offerti sulla DORSALE DIGITALE o di terzi, nonché per lo svolgimento      di ricerche di mercato, a mezzo di email e del servizio postale (c.d. finalità di “marketing”);

                </li><li>
                    e.    limitatamente alle coordinate di posta elettronica   fornite dall’utente nel contesto dell’acquisto di un prodotto o di un servizio offerto e venduto sul Sito direttamente da ARXIT o da terzi, per consentire la vendita diretta di prodotti o servizi analoghi senza necessità del consenso  espresso e preventivo dell’interessato e a condizione che l’utente non eserciti il diritto di opposizione nei modi descritti al punto 3  che segue (c.d. “soft spamming”);
                </li><li>
                    f.    previo consenso espresso dell’utente, per la elaborazione delle sue scelte di consumo e delle sue abitudini di acquisto da parte di  ARXIT             attraverso la rilevazione della tipologia e della frequenza degli acquisti effettuati sul Sito, al fine    dell’invio di materiale informativo e/o pubblicitario di specifico interesse dell’utente a mezzo di e-mail e del miglioramento delle proposte inviate da ARXIT s.r.l. o da terzi; mediante l’utilizzo di dati aggregati in forma anonima, per analizzare le attività sul Sito e fornire un resoconto circa le vendite, i clienti, i modelli di traffico e altre informazioni a partner attuali e potenziali, inserzionisti, investitori e altre terze parti (c.d. “profilazione”).
                </li>
            </ul>
            <p>
            Si precisa, inoltre, che:



            Il trattamento dei Dati, secondo principi di necessità, liceità, correttezza, proporzionalità e trasparenza, per la finalità indicata al punto (a) e (b) del paragrafo che precede è effettuato per consentire agli utenti la corretta navigazione sul Sito, nonché di fruire della Newsletter e dei Servizi, e non necessita dunque del  consenso espresso (ai sensi dell’articolo 6.1 lettera (b) del Regolamento). Il trattamento dei Dati per la finalità indicata al punto (c) del paragrafo che precede è effettuato per adempiere a obblighi di legge, e non necessita dunque del consenso espresso (ai sensi dell’articolo 6.1 lettera (c) del regolamento).



            Il trattamento dei Dati per le finalità indicate ai punti (d) e (e) è effettuato sulla base del legittimo interesse del Titolare (ai sensi dell’articolo 6.1 lettera (f) del Regolamento), salvo opposizione al momento dell’iscrizione alla Newsletter o ai Servizi o successivamente. In particolare, il Titolare potrà svolgere attività di marketing diretto, mostrarti pubblicità online, ed effettuare analisi sull’utilizzo dei Siti in assenza del consenso espresso (in virtù di una valutazione di prevalenza del proprio interesse a svolgere tali attività, in difetto di reale pregiudizio agli interessi, diritti e libertà fondamentali degli utenti e come previsto del resto nel Considerando 47 del Regolamento che qualifica come “legittimo interesse” trattare dati personali per finalità di marketing diretto), ma solo fino a una opposizione dell’utente a tale trattamento. Il trattamento dei Dati per le finalità indicate al punto (f) del paragrafo che precede è effettuato solo in presenza del preventivo consenso espresso e specifico dell’utente e sulla base del medesimo.



            Il conferimento dei Dati per le finalità di cui ai punti (a) e (b) del paragrafo che precede è dunque necessario ai fini della navigazione sul Sito, dell’iscrizione alla Newsletter e per consentire all’utente di fruire dei Servizi: la loro mancata comunicazione comporterà l’impossibilità, rispettivamente, di fruire correttamente del Sito e della Piattaforma, di iscriversi e ricevere la Newsletter e/o di fruire dei Servizi. Il conferimento dei Dati per le finalità di cui ai punti da (d) a (f) è invece facoltativo, sicché l’eventuale rifiuto comporterà l’impossibilità per il Titolare di svolgere le attività ivi descritte.



            Dati relativi alla carta di credito



            Per effettuare un pagamento sul Sito mediante carta  di credito, l’utente dovrà inserire i dati riservati della  carta di credito (numero della carta, intestatario, data di scadenza, codici di sicurezza). Tali dati verranno acquisiti  dal fornitore del servizio di pagamento (Payment authority) che agirà in qualità di autonomo titolare del trattamento, senza transitare dal       server di ARXIT che, quindi, non tratterà in alcun modo tali dati. I dati verranno acquisiti in formato criptato e secondo i requisiti di sicurezza previsti dalla certificazione     PCI. Il servizio di pagamento si avvale del Protocollo SSL (Secure Sockets Layer). L’utente potrà richiedere, tramite      il Sito, il salvataggio di tali dati ma i dati verranno salvati   direttamente dal fornitore del servizio di pagamento e non verranno acquisiti da ARXIT che manterrà traccia solamente delle ultime quattro cifre che compongono il numero della carta di credito solo ed esclusivamente per prevenire eventuali frodi nei pagamenti online. Si precisa che anche nel caso in cui il pagamento riguardi l’acquisto di prodotto di un Venditore Terzo, nell’ambito del DORSALE DIGITALE, i dati della carta di credito non saranno comunicati al Venditore.





            Dati di navigazione



            È possibile accedere al Sito (tramite mail e password) e visualizzare i prodotti  / servizi offerti senza che sia richiesto il conferimento  di alcun dato personale. Tuttavia, i sistemi informatici e i  software preposti al funzionamento del Sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di internet.



            Tali informazioni non sono raccolte per essere associate      a interessati identificati, ma, anche per la loro stessa natura, potrebbero, attraverso elaborazioni o associazioni,   permettere l’identificazione dell’utente.



            In questa categoria rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che accedono al Sito, gli indirizzi in notazione URL delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, ecc. Questi dati vengono utilizzati per ricavare informazioni statistiche anonime sull’uso del Sito (quale, in particolare, il numero di accessi) e per controllarne il corretto funzionamento. In questo processo non è previsto il trattamento di informazioni direttamente identificative.



            I dati di navigazione saranno acquisiti, con le modalità sopra indicate, solo da ARXIT o dall’eventuale Responsabile del Trattamento che all’uopo verrà espressamente incaricato.
                </p>

            <p>
                <strong>Cookies</strong><br />
            </p>

            <p>
            Quando utilizza il nostro sito, i cookies vengono conservati sul suo computer. I cookies sono costituiti da piccoli file di testo che vengono salvati sul tuo computer e ci forniscono determinate informazioni. Sono ampiamente utilizzati al fine di far funzionare o di far funzionare in modo più efficiente i siti Web per migliorare l’esperienza dell’utente, nonché per fornire determinate informazioni ai proprietari del sito. Il nostro sito usa Cookies che rimangono sul tuo computer per tempi diversi. Alcuni scadono alla fine di ogni sessione e alcuni rimangono più a lungo in modo che quando ritorni sul nostro Sito, puoi beneficiare di una migliore esperienza quale utente. I browser web ti consentono di esercitare un certo controllo sui Cookies attraverso le impostazioni del browser. La maggior parte dei browser consente di bloccare i Cookies o bloccare i Cookies di determinati siti. I browser possono anche aiutarla a eliminare i Cookies quando chiude il browser. Tuttavia, dovrebbe tenere a mente che ciò potrebbe significare che eventuali opt-out o preferenze che ha impostato sul sito andranno persi. La invitiamo a consultare le informazioni tecniche relative al tuo browser per le istruzioni. Se sceglie di disabilitare l’impostazione dei Cookies o se rifiuta di accettare un cookie, alcune parti del servizio potrebbero non funzionare correttamente o potrebbero essere notevolmente più lente.



            Il consenso all’utilizzo dei cookie di profilazione viene prestato in modalità espressa e libera dall’utente selezionando l’apposito tasto dal banner contenente l’informativa breve o mediante l’apposita sezione di gestione delle preferenze.
            Il consenso può essere revocato in ogni momento tramite le apposite funzionalità indicate al successivo paragrafo.
            Tutti i cookie tecnici e quelli analitici/statistici anonimi non richiedono consenso, pertanto vengono installati automaticamente a seguito della chiusura del banner contenente l’informativa breve o della navigazione nella sezione delle preferenze.
            I cookie possono essere completamente disattivati dal browser utilizzando l’apposita funzione prevista nella maggior parte dei programmi di navigazione.
            È bene sapere però che disattivando i cookie alcune delle funzionalità di Subito potrebbero non essere utilizzabili.
            Con riferimento ai cookie di profilazione finalizzati ad offrirti pubblicità personalizzata, ti informiamo che, qualora esercitassi l’opt-out, continuerai in ogni caso a ricevere pubblicità di tipo generico.
            E’ possibile disabilitare e gestire anche granularmente in ogni momento il consenso o l’opt-out per i cookie non tecnici mediante le impostazioni che trovi qui.



            Per esercitare l’opt-out e disattivare gli annunci pubblicitari personalizzati cambiando le impostazioni dei tuoi dispositivi mobile, entra nell’area riservata e modifica le tue preferenze sulla raccolta dati  e/o segui le indicazioni che trovi qui di seguito:

            </p>

            <p>
                <strong> 3. Conferimento dei dati e conseguenze  in caso di mancato assenso al trattamento</strong><br />
            Il conferimento dei dati per le finalità di cui alle lettere (a) e (c) del punto 2 che precede è facoltativo. Tuttavia, poiché tale trattamento è necessario per consentire la registrazione al DORSALE DIGITALE e la prestazione dei servizi riservati, incluso il servizio di acquisto online, l’eventuale rifiuto di conferire i dati in questione comporterà l’impossibilità di registrarsi al Sito e/o di concludere un acquisto online. Con riferimento alle finalità del trattamento di cui alle lettere (d) (“marketing”) ed (f) (“profilazione”) del punto 2  che precede, l’assenso al trattamento dei dati personali   è meramente facoltativo e potrà essere manifestato attraverso la selezione di una apposita casella, per ciascuna distinta finalità, presente in calce al form di registrazione al Sito. Il mancato assenso non comporterà alcuna conseguenza sulla possibilità di registrarsi al Sito e/o di effettuare acquisti sullo stesso e implicherà esclusivamente le conseguenze di seguito descritte:
            </p>
            <ul>

                <li>
            ■    il mancato assenso al trattamento dei dati personali per le finalità di cui al punto 2, lett. (d) (“marketing”)   che precede, comporterà l’impossibilità di ricevere comunicazioni informative e promozionali (inclusa la “newsletter”), buoni sconto e offerte speciali, da  parte di ARXIT, in relazione a prodotti e servizi  di Venditori o di terzi, nonché per lo svolgimento di ricerche di mercato, a mezzo di email e del servizio postale;
            </li><li>
                ■    il mancato assenso al trattamento dei dati personali per le finalità di cui al punto 2, lett. (f) (“profilazione”) che precede comporterà l’impossibilità per ARXIT di effettuare l’analisi delle scelte di consumo dell’utente attraverso la rilevazione della tipologia e della frequenza degli acquisti effettuati sul Sito al fine dell’invio di materiale informativo e/o pubblicitario di specifico interesse per l’utente.
                </li>
            </ul>

            <p>
            L’utente potrà, in ogni caso, revocare il consenso  eventualmente prestato per le finalità descritte alle lettere
            (d)    (“marketing”) ed (f) (“profilazione”) del punto 2 ovvero opporsi al trattamento per le finalità di cui alla lettera (“soft spamming”) contattando ARXIT all’indirizzo riportato al punto 1 che precede .



            Inoltre, limitatamente alle finalità descritte alle lettere (d) (“marketing”) ed (e) (“soft spamming”) del punto 2 che precede, l’utente potrà opporsi al trattamento dei dati che lo riguardano anche tramite apposito link presente in  calce a qualsiasi e-mail a contenuto promozionale inviata  da ARXIT. L’opposizione manifestata con tali modalità  si estende anche all’invio di comunicazioni mediante il servizio postale.

            </p>
            <p>
                <strong>4. Modalità del trattamento dei dati</strong><br/>
            Il trattamento dei dati sarà principalmente effettuato per mezzo delle operazioni indicate all’art. 4, n. 2), GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. Il trattamento  sarà improntato ai principi di correttezza, liceità e trasparenza e potrà essere effettuato anche attraverso modalità automatizzate atte a memorizzarli, gestirli e trasmetterli ed avverrà mediante strumenti idonei, per quanto di ragione e allo stato della tecnica, a garantire la sicurezza e la riservatezza tramite l’utilizzo di idonee procedure che evitino il rischio di perdita, accesso non autorizzato, uso illecito e diffusione. In particolare, saranno adottate tutte le misure tecniche, informatiche, organizzative, logistiche e procedurali di sicurezza, in modo che sia garantito il livello minimo di protezione dei dati previsto dalla legge, consentendo l’accesso alle sole persone incaricate del trattamento da parte del Titolare o  dei responsabili dallo stesso designati.

            </p>

            <p>
                <strong>5. Ambito di comunicazione dei dati</strong><br/>
            I dati personali conferiti dall’utente, per le finalità descritte  al punto 2 che precede, potranno essere portati a conoscenza di o comunicati ai seguenti soggetti:
            </p>

            <ul><li>
            a)    a società del gruppo, a cui ARXIT appartiene, e/o dipendenti e/o collaboratori di ARXIT e/o del gruppo, per lo svolgimento    di attività di amministrazione, contabilità e supporto informatico e logistico che agiscono  in qualità, rispettivamente, di responsabili e  incaricati del trattamento;
            </li><li>
                b)    a società o consulenti incaricati dell’installazione,   della manutenzione, dell’aggiornamento   e, in generale, della gestione degli hardware e software di ARXIT (ivi inclusa la Piattaforma);
                </li><li>
                    c)    a società incaricate da ARXIT dell’invio di comunicazioni online;
                </li><li>
                    d)    ai soggetti preposti alla riparazione dei prodotti  / servizi acquistati in esecuzione della garanzia legale di conformità;
                </li><li>
                    e)    a tutti quei soggetti pubblici e/o privati, persone fisiche e/o giuridiche (studi di consulenza legale, amministrativa e fiscale), qualora la comunicazione risulti necessaria o funzionale al corretto adempimento degli obblighi contrattuali assunti in relazione ai servizi prestati tramite il Sito, nonché degli obblighi derivanti dalla legge;
                </li><li>
                    f)    a spedizionieri e ai soggetti incaricati della consegna e/o del ritiro dei prodotti acquistati;
                </li><li>
                    g)    a tutti quei soggetti (ivi incluse le Pubbliche Autorità) che hanno accesso ai dati in forza di provvedimenti normativi o amministrativi. Senza il Suo espresso consenso art. 6 lett. b), c), GDPR), il Titolare potrà comunicare i Suoi dati per le finalità indicate a Organismi di vigilanza, Autorità giudiziarie nonché a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per legge per l’espletamento delle finalità dette;
                </li><li>
                    h)    a soggetti, diversi da quelli sopraelencati, nominati responsabili o incaricati del trattamento dei dati personali dal Venditore, per la esecuzione  di attività connesse alla conclusione  del contratto di acquisto in tutti i casi in cui l’utente acquisti un prodotto venduto da un Venditore Terzo sul DORSALE DIGITALE.
                </li>
            </ul>

            <p>
            Tutti i dati personali conferiti dagli utenti in relazione alla  registrazione al Sito e/o all’acquisto tramite il Sito non sono soggetti a diffusione. L’elenco aggiornato dei responsabili e degli incaricati del trattamento è consultabile presso la sede del Titolare.
            </p>

            <p>
            
                <strong>6. Conservazione dei dati</strong><br/>
            I dati personali saranno conservati per il solo tempo necessario a garantire la corretta prestazione dei servizi offerti, nel rispetto dei principi di minimizzazione e limitazione della conservazione di cui all’art. 5, comma 1, lettere c), e), GDPR. I dati personali, comunque, saranno trattati per non oltre 10 anni dalla cessazione del rapporto contrattuale per le finalità contrattuali di cui all’art. 2. Decorso tale termine di conservazione, i dati saranno distrutti o resi anonimi e, comunque, saranno resi inutilizzabili per le finalità relativamente alle quali sono decorsi i termini di conservazione. Il Titolare tratterà i dati personali per il tempo necessario per adempiere alle finalità di cui sopra e per non oltre 2 anni dalla raccolta dei dati per le Finalità di Marketing. Decorso tale termine di conservazione, i dati saranno distrutti o resi anonimi.

            </p>
            <p>
                <strong> 7. Trasferimento dei dati</strong><br/>
            </p>

            <p>
            La gestione e la conservazione dei dati personali avverrà su server del Titolare e/o di società terze incaricate e debitamente nominate quali Responsabili del trattamento, ubicati all’interno dell’Unione Europea, ovvero in conformità di quanto previsto dagli artt. 45 e seg., GDPR. Attualmente i server sono situati nell’Unione Europea. I dati non saranno oggetto di trasferimento al di fuori dell’Unione Europea. Resta in ogni caso inteso che, qualora si rendesse necessario trasferire l’ubicazione dei server, in Italia e/o Unione Europea e/o Paesi extra-UE, tale spostamento avverrà sempre in ossequio agli artt. 45 e seg., GDPR. In tal caso, comunque, il Titolare assicura sin d’ora che il trasferimento dei dati extra-UE avverrà in conformità alle disposizioni di legge applicabili stipulando, se necessario, accordi che garantiscano un livello di protezione adeguato e/o adottando le clausole contrattuali standard previste dalla Commissione Europea
            </p>



            <p>
            
                <strong>8. Diritti dell’interessato</strong><br/>
            </p>

            <p>
            Nella Sua qualità di interessato, ha i diritti di cui art. 15, GDPR e precisamente i diritti di:
            </p>

            <ul>
            <li>
            1.    ottenere la conferma dell’esistenza o meno di dati personali che la riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;
                </li><li>
                    2.    ottenere l’indicazione: a) dell’origine dei dati personali; b) delle finalità e modalità del trattamento; c) della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’art. 3, comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;
                </li><li>
                    3.    ottenere: a) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati; c) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;
                </li><li>
                    4.    opporsi, in tutto o in parte: a) per motivi legittimi al trattamento dei dati personali che la riguardano, ancorché pertinenti allo scopo della raccolta; b) al trattamento di dati personali che la riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante l’uso di sistemi automatizzati di chiamata senza l’intervento di un operatore mediante email e/o mediante modalità di marketing tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell’interessato, esposto al precedente punto b), per finalità di marketing diretto mediante modalità automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilità per l’interessato di esercitare il diritto di opposizione anche solo in parte. Pertanto, l’interessato può decidere di ricevere solo comunicazioni mediante modalità tradizionali ovvero solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione.
            Ove applicabili, ha altresì i diritti di cui agli artt. 16 – 21, GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché il diritto di reclamo all’Autorità Garante.
                </li>
            </ul>

            <p>
                <strong> 9. Modalità di esercizio dei diritti</strong><br/>

            </p>
            <p>
            L’utente ha diritto di chiedere al Titolare l’accesso ai Dati che lo riguardano, la loro rettifica o la cancellazione, l’integrazione dei Dati incompleti, la limitazione del trattamento; di ricevere i Dati in un formato strutturato, di uso comune e leggibile da dispositivo automatico; di revocare il consenso eventualmente prestato relativamente al trattamento dei dati sensibili in qualsiasi momento ed opporsi in tutto od in parte, all’utilizzo dei Dati; di proporre reclamo all’Autorità, nonché di esercitare gli altri diritti allo stesso riconosciuti dalla disciplina applicabile.
            


            Potrà in qualsiasi momento esercitare i diritti inviando:
            una raccomandata A/R a: ARXIT s.r.l.., società, con sede legale in Torino, Italia, Via Villar Focchiardo 31, Partita IVA/codice fiscale 05476950968 iscritta al Registro delle Imprese al numero TO-1172567 (“ARXIT” o “Titolare”), tel:  +39 011 198.638.74; e una e-mail all’indirizzo: info@arxit.it.
            </p>

            <p>
            <strong>ALLEGATO 5.B
                    INFORMATIVA AI SENSI DELL’ ART. 13 GDPR</strong><br/>
            </p>

            <p>
            La presente informativa è resa da ARXIT s.r.l.., per tutti  i Venditori che aderiscono alla DORSALE DIGITALE. Salvo se diversamente previsto, le parole con lettera maiuscola   qui di seguito utilizzate hanno lo stesso significato alle medesime attribuito nel Contratto.
            </p>

            <p>
            
                <strong>1.    Titolare del trattamento</strong><br/>
            Titolare del trattamento dei dati personali è ARXIT s.r.l.., società, con sede legale in Torino, Italia, Via Villar Focchiardo 31, Partita IVA/codice fiscale 05476950968 iscritta al Registro delle Imprese al numero TO-1172567 (“ARXIT” o “Titolare”), tel:  +39 011 198.638.74 e-mail info@arxit.it.
            </p>

            <p>
                <strong>2.    Finalità del trattamento</strong><br/>
            In qualità di gestore del DORSALE DIGITALE, ARXIT tratterà i dati personali conferiti dal Venditore nel Modulo di   Sottoscrizione nonché le Credenziali di Autenticazione utilizzate dal Venditore per la registrazione alla Piattaforma Transazionale e, più in generale, i dati conferiti  dal Venditore nell’ambito del rapporto contrattuale con ARXIT, in relazione all’esecuzione degli obblighi derivanti  dalla prestazione del Servizio di Accesso e Utilizzo della Piattaforma Transazionale, del Servizio di Adesione alla DORSALE DIGITALE, del Servizio di Vendita e, ove richiesto dal Venditore, del Servizio di Logistica prestati da ARXIT e descritti nel Contratto, nonché per finalità amministrativo- contabili e per consentire a ARXIT di adempiere a norme  di legge e a disposizioni regolamentari nonché a richieste della pubblica autorità.
            Previo consenso del Venditore, ARXIT potrà, inoltre, trattare i dati personali ai fini dell’invio di comunicazioni commerciali relative a prodotti o a servizi, propri o di terzi, che potrebbero essere di interesse per il Venditore a mezzo di sistemi automatizzati (es. e-mail, fax) o del servizio postale.

            </p>
            <p>
            
                <strong>3.    Conferimento dei dati e conseguenze   in caso di mancato assenso al trattamento</strong><br/>
            Il conferimento dei dati per le finalità descritte al primo paragrafo del punto 2 che precede è facoltativo e nonchè subordinato alla prestazione del preventivo consenso dell’interessato. Tuttavia, il mancato conferimento di tali dati potrebbe non consentire a ARXIT  di concludere il Contratto con il Venditore ovvero di adempiere, in tutto o  in parte, agli obblighi derivanti dal Contratto.
            Il conferimento dei dati per le finalità descritte al secondo paragrafo del punto 2 che precede è facoltativo ed è subordinato alla prestazione del preventivo consenso dell’interessato. Il mancato assenso non comporterà alcuna conseguenza sulla possibilità di concludere il Contratto e/o di registrarsi alla Piattaforma Transazionale e implicherà esclusivamente l’impossibilità per ARXIT di inviare comunicazioni a contenuto commerciale non strettamente connesse alla esecuzione del Contratto.
            </p>

            <p>

                <strong>4.    Modalità del trattamento dei dati</strong><br/>
            Il trattamento dei dati sarà principalmente effettuato con l’ausilio di strumenti elettronici o comunque automatizzati, secondo le modalità e con mezzi idonei a garantire la sicurezza e la riservatezza dei dati stessi, in conformità di quanto previsto dal Codice della Privacy. In particolare, saranno adottate tutte le misure tecniche, informatiche, organizzative, logistiche e procedurali di sicurezza, in modo che sia garantito il livello minimo di protezione dei dati previsto dalla legge, consentendo l’accesso alle sole persone incaricate del trattamento da parte del Titolare o dei responsabili dallo stesso designati.
            </p>
            <p>
                <strong>  5.    Ambito di comunicazione dei dati </strong><br/>
            I dati personali conferiti dal Venditore, per le finalità descritte al punto 2 che precede, potranno essere portati a conoscenza di o comunicati ai seguenti soggetti:

            </p>
            <ul>
            <li>
            (i)    a Società del Gruppo e/o dipendenti e/o collaboratori di ARXIT e/o   delle   Società del Gruppo, per lo svolgimento di attività di amministrazione, contabilità e supporto informatico e logistico che agiscono in qualità, rispettivamente, di responsabili e incaricati del trattamento;
                </li> <li>
                    (ii)    a società o consulenti incaricati dell’installazione, della manutenzione,   dell’aggiornamento   e, in generale, della gestione degli hardware e software di ARXIT (ivi inclusa la Piattaforma Transazionale);
                </li> <li>
                    (iii)    a società incaricate da ARXIT dell’invio di comunicazioni online;
                </li> <li>
                    (iv)    a tutti quei soggetti pubblici e/o privati, persone fisiche e/o giuridiche (studi di consulenza legale, amministrativa e fiscale), qualora la comunicazione risulti necessaria o funzionale al corretto adempimento degli obblighi derivanti dalla gestione della DORSALE DIGITALE e, più in generale, del Contratto nonché’ degli obblighi derivanti dalla legge;
                </li> <li>
                    (v)    a spedizionieri e ai soggetti incaricati della consegna e/o del ritiro dei prodotti acquistati nell’ambito del Servizio di Logistica, ove applicabile;
                </li> <li>
                    (vi)    a tutti quei soggetti (ivi incluse le pubbliche autorità) che hanno accesso ai dati in forza di provvedimenti normativi o amministrativi.
                </li>
            </ul>

            <p>
            Tutti i dati personali non sono soggetti a diffusione.
            L’elenco aggiornato dei responsabili e degli incaricati del trattamento è consultabile presso la sede del Titolare.

            </p>
            <p>
                <strong>6.    Conservazione dei dati</strong><br/>
            I dati del Venditore saranno conservati per il solo tempo necessario a garantire la corretta prestazione dei servizi offerti in base al Contratto nonché per il tempo previsto dalle disposizioni sulla conservazione della documentazione contrattuale e contabile o per finalità di pubblica sicurezza.

            </p>
            <p>
                <strong>7. Diritti dell’interessato</strong><br/>
            </p>
            <p>            In ogni momento, Lei potrà esercitare, ai sensi degli articoli dal 15 al 22 del Regolamento UE n. 2016/679, il diritto di:
            </p>
            <ul>
            <li>
                    a) chiedere la conferma dell’esistenza o meno di propri dati personali;
                </li><li>
            b) ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati personali, i destinatari o le categorie di
                    destinatari a cui i dati personali sono stati o saranno comunicati e, quando possibile, il periodo di conservazione;
                </li><li>
                    c) ottenere la rettifica e la cancellazione dei dati;
                </li><li>
                    d) ottenere la limitazione del trattamento;
                </li><li>
            e) ottenere la portabilità dei dati, ossia riceverli da un titolare del trattamento, in un formato strutturato, di uso comune e
                    leggibile da dispositivo automatico, e trasmetterli ad un altro titolare del trattamento senza impedimenti;
                </li><li>
                    f) opporsi al trattamento in qualsiasi momento ed anche nel caso di trattamento per finalità di marketing diretto;
                </li><li>
                    g) opporsi ad un processo decisionale automatizzato relativo alle persone fisiche, compresa la profilazione.
                </li><li>
            h) chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione
                    del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati;
                </li><li>
            i) revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato
                    prima della revoca;
                </li><li>
                    j) proporre reclamo a un’autorità di controllo.
                </li>
            </ul>

            <p>
            I diritti di cui sopra potranno essere esercitati con richiesta al Titolare, ai recapiti riportati al punto 1 della  presente informativa.
            </p>





        </div>
    );
};

export default Tab3;
