import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiContainer, UiFileUploader
} from "../../../utils/Helpers";
import { Box, Button, FormControlLabel, MenuItem } from "@mui/material";
import axios from "axios";
import { styled } from '@mui/material/styles';
import { setLoadingMessage, setTipologia, showInfo } from "../../../reducers";
import Stack from "@mui/material/Stack";
import { DbCondizione, DbDorsale, DbDorsaleTipologia } from "../../../Db";
import { DisabledByDefaultOutlined } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import Logs from "../../../components/Logs";
import Api from "../../../Api";
import Checkbox from "@mui/material/Checkbox";

export default React.memo(function DorsaleEdit() {
    const [item, setItem] = React.useState<any>(null);
    const [fileLogin, setFileLogin] = React.useState<any>(null);
    const [mediaLogin, setMediaLogin] = React.useState<any>(null);
    const [fileLogo, setFileLogo] = React.useState<any>(null);
    const [mediaLogo, setMediaLogo] = React.useState<any>(null);
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    useEffect(() => {
        (async () => {
            dispatch(setLoadingMessage("caricamento in corso..."));
            await reload();
            dispatch(setLoadingMessage(null));
        })();
    }, [])

    async function reload() {
        var dbDorsale = await Api.Dorsale.all(null, dispatch);
        setItem(dbDorsale);
        setMediaLogin(await Api.Media.all({ guid: dbDorsale.guid_login }, dispatch));
        setMediaLogo(await Api.Media.all({ guid: dbDorsale.guid_logo }, dispatch));
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };

    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));
    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    return (
        <React.Fragment>
            {/*            {JSON.stringify(mediaLogin)}*/}
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: 700 }}>Generali</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiContainer>
                            <PageHeader title={""} />
                            <UiPaper padding title={""}>
                                <UiFrmControl first>
                                    <TextField
                                        select
                                        label="tipologia"
                                        name="tipologia"
                                        SelectProps={{ style: { fontWeight: 700 } }}
                                        value={item?.tipologia ?? "ENTERPRISE"}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"ENTERPRISE"}>ENTERPRISE</MenuItem>
                                        <MenuItem value={"TERRITORIO"}>TERRITORIO</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="nome"
                                        label="nome"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.nome ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        select

                                        label="pagamento"
                                        name="paga_offline"
                                        SelectProps={{ style: { fontWeight: 700 } }}
                                        value={item?.paga_offline ?? "false"}

                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"true"}>SOLO OFFLINE</MenuItem>
                                        <MenuItem value={"false"}>ABILITA PAGA CON CARTA</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                            </UiPaper>
                        </UiContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                <AccordionSummary>
                    <Typography sx={{ fontWeight: 700 }}>Login</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UiPaper padding title={""}>
{/*                        <UiFrmControl first>
                            <UiFileUploader
                                image={true}
                                media={mediaLogin}
                                onDelete={(e) => {
                                }}
                                onChange={(e) => {
                                    setFileLogin(e);
                                }} />
                        </UiFrmControl>*/}
                        <UiFrmControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={item?.change_pwd ?? false}
                                        name={"change_pwd"}
                                        onChange={handleChangeCheckbox}
                                    />}
                                label={"richiedi cambio password"} />
                        </UiFrmControl>
                    </UiPaper>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panelLogs'} onChange={handleChangeAccordion('panelLogs')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={{ fontWeight: 700 }}>Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button variant={"contained"} size={"medium"} onClick={() => {
                        navigate("/logs");
                    }}>Visualizza i Log</Button>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'cookie'} onChange={handleChangeAccordion('cookie')}>
                <AccordionSummary aria-controls="cookie-content" id="cookie-header">
                    <Typography sx={{ fontWeight: 700 }}>Cookies</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiContainer>
                            <PageHeader title={""} />
                            <UiPaper padding title={""}>
                                <UiFrmControl first>
                                    <TextField
                                        name="cookie_testo"
                                        label="disclaimer"
                                        fullWidth
                                        multiline
                                        maxRows={10}
                                        type="text"
                                        defaultValue={item?.cookie_testo ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="cookie_informativa"
                                        label="informativa"
                                        fullWidth
                                        multiline
                                        maxRows={10}
                                        type="text"
                                        defaultValue={item?.cookie_informativa ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            </UiPaper>
                        </UiContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'stripe'} onChange={handleChangeAccordion('stripe')}>
                <AccordionSummary aria-controls="stripe-content" id="stripe-header">
                    <Typography sx={{ fontWeight: 700 }}>Stripe</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiContainer>
                            <PageHeader title={""} />
                            <UiPaper padding title={""}>
                                <UiFrmControl first>
                                    <TextField
                                        name="stripe"
                                        label="Stripe Key"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.stripe ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            </UiPaper>
                        </UiContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'social'} onChange={handleChangeAccordion('social')}>
                <AccordionSummary aria-controls="social-content" id="social-header">
                    <Typography sx={{ fontWeight: 700 }}>Social</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiContainer>
                            <PageHeader title={""} />
                            <UiPaper padding title={""}>
                                <UiFrmControl first>
                                    <TextField
                                        name="yt"
                                        label="YouTube"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.yt ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="_in"
                                        label="LinkedIn"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?._in ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="fb"
                                        label="Facebook"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.fb ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            </UiPaper>
                        </UiContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'urls'} onChange={handleChangeAccordion('urls')}>
                <AccordionSummary aria-controls="urls-content" id="urls-header">
                    <Typography sx={{ fontWeight: 700 }}>URLs Registrazione Utenti</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiContainer>
                            <PageHeader title={""} />
                            <UiPaper padding title={""}>
                                <UiFrmControl first>
                                    <TextField
                                        name=""
                                        label="registrazione cliente"
                                        fullWidth
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        defaultValue={`https://${window.location.host}/registrati/cliente`}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name=""
                                        label="registrazione cittadino"
                                        fullWidth
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        defaultValue={`https://${window.location.host}/registrati/cittadino`}
                                    />
                                </UiFrmControl>

                            </UiPaper>
                        </UiContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <UiContainer hideBox>
                <UiFabContainer>
                    <Fab color="secondary"
                        onClick={async (e) => {
                            dispatch(setLoadingMessage("salvataggio in corso..."));
                            if (await Api.Dorsale.save(item, [
                                {
                                    name: "fileLogin",
                                    value: fileLogin,
                                },
                                {
                                    name: "fileLogo",
                                    value: fileLogo,
                                },
                            ], dispatch)) {
                                dispatch(setTipologia((await DbDorsaleTipologia(dispatch))));
                                dispatch(setLoadingMessage(null));
                                await reload();
                            }
                            dispatch(setLoadingMessage(null));
                        }}
                    >
                        <SaveIcon />
                    </Fab>
                    <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                </UiFabContainer>
            </UiContainer>
        </React.Fragment>
    );
})