import React, { useState } from 'react';
import { DialogActions, Checkbox, Tooltip } from '@material-ui/core';

interface Tab2Props {
    // Inserire le prop necessarie per il contenuto della Tab2
}

const Tab2: React.FC<Tab2Props> = (props) => {


    return (
        <>
           
            <div>
                <>
                    <div><p><strong>Marketing Profilato</strong></p></div>
                    <div>
                        Letta l'informativa privacy di questo sito, presto il mio consenso per l'invio di comunicazioni promozionali (compresa la newsletter) da parte del sito a mezzo mail e riferito a prodotti propri"
                    </div>
                
                    
                    <div><p><strong>Marketing Profilato Da Terzi</strong></p></div>
                    <div>
                       Letta l'informativa privacy di questo sito, presto il mio consenso per l'analisi delle mie scelte di acquisto su questo sito e finalizzata all'invio di comunicazioni promozionali da parte di terzi soggetti a mezzo mail e riferite a prodotti propri di mio specifico interesse"
                    </div>
                     
                   
                
                </>
            </div>
        </>
    );
};

export default Tab2;
