import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from "react-router-dom";
import {DbRuolo} from "../../../Db";
import {BaseGrid} from "../../../components/BaseGrid";
import Api from "../../../Api";


export default function CambioPasswordReport(props) {
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const [ruoli, setRuoli] = React.useState<any>(null);
    useEffect(() => {
        (async () => {
            setRuoli(await DbRuolo(dispatch));
        })();
    }, [])
    return (
        <React.Fragment>
            {ruoli &&
                <BaseGrid
                    id={"cambiopasswordreport"}
                    exportUrl={"/db/anagrafica/cambiopasswordreport"}
                    disableAdd={true}
                    filtersDefault={{ ruolo: "temporaneo" }}
                    onRowClick={(row) => {
                    }}
                    getDataSourceAsync={Api.Anagrafica.cambiopasswordreport}
                    headers={[
                        {
                            id: 'email',
                            label: 'email',
                            chip: true,
                            bold: true,
                            mobile: true
                        },
                        {
                            id: 'ragione_sociale',
                            label: 'ragione_sociale',
                        },
                        {
                            id: 'nome',
                            label: 'nome',
                        },
                        {
                            id: 'changed',
                            label: 'password cambiata',
                            check: true,
                        },
                    ]}
                    filters={[[
                        {
                            id: 'global',
                            label: 'cerca',
                        },
                        {
                            id: 'changed',
                            label: 'password cambiata',
                            options: ['sì', 'no'],
                        },
                        {
                            id: 'ruolo',
                            label: 'ruolo',
                            options: ruoli.map(x => x.nome),
                        },
                    ]]}
                />
            }
        </React.Fragment>)
}