import React, {useEffect} from 'react';
import {
    setCredito,
    showInfo,
} from "../../reducers";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer, UiFormInfo
} from "../../utils/Helpers";
import {
    DbCredito,
} from "../../Db";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Autocomplete, Box, FormControl, FormHelperText, MenuItem} from "@mui/material";
import {validationError, validationMessage, validationState} from "../../utils/Functions";
import DatePicker from "@mui/lab/DatePicker";
import Api from "../../Api";

export default React.memo(function BilancioEdit() {

    //let {guid} = useParams<any>();

    //const [item, setItem] = React.useState<any>(null);
    //const [anagrafica, setAnagrafica] = React.useState<any>(null);
    //const [validation, setValidation] = React.useState<Array<any>>([
    //    {
    //        id: "email",
    //        required: true
    //    },
    //    {
    //        id: "data",
    //        required: true
    //    },
    //    {
    //        id: "importo",
    //        required: true
    //    },
    //]);

    //const dispatch = useDispatch();
    //const navigate = useNavigate();
    //const root = useSelector((state: RootStateOrAny) => state);

    //useEffect(() => {
    //    (async () => {
    //        setAnagrafica((await Api.Anagrafica.all({flat: true, take: 200}, dispatch))?.data);
    //        await reload();
    //    })();
    //}, [])

    //const handleChange = (event: any) => {
    //    setItem({
    //        ...item,
    //        [event.target.name]: event.target.value,
    //    });
    //};

    //useEffect(() => {
    //    if (item == null)
    //        return;
    //    item.data = new Date();
    //}, [item])

    //async function reload() {
    //    if (guid != null)
    //        setItem(await Api.Bilancio.all({guid: guid}, dispatch));
    //    else
    //        setItem({});
    //}

    return (
        <React.Fragment>
            {/*<UiContainer>*/}
            {/*    <PageHeader title={item?.nome}/>*/}
            {/*    {item &&*/}
            {/*    <Box component="form"*/}
            {/*         id={"myForm"}*/}
            {/*         autoComplete="off">*/}
            {/*        <UiPaper padding title={"gestione bilancio"}>*/}
            {/*            <UiFrmControl first>*/}
            {/*                <TextField*/}
            {/*                    disabled*/}
            {/*                    name="guid"*/}
            {/*                    label="guid"*/}
            {/*                    fullWidth*/}
            {/*                    required*/}
            {/*                    type="text"*/}
            {/*                    defaultValue={item?.guid ?? ""}*/}
            {/*                    onBlur={handleChange}*/}
            {/*                />*/}
            {/*            </UiFrmControl>*/}
            {/*            <UiFrmControl>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    name="email"*/}
            {/*                    select*/}
            {/*                    label="email"*/}
            {/*                    defaultValue={item?.email ?? ""}*/}
            {/*                    onBlur={handleChange}*/}
            {/*                >*/}
            {/*                    {anagrafica && anagrafica.map((option) => (*/}
            {/*                        <MenuItem key={option.email} value={option.email}>*/}
            {/*                            {option.ragione_sociale} ({option.email})*/}
            {/*                        </MenuItem>*/}
            {/*                    ))}*/}
            {/*                </TextField>*/}
            {/*            </UiFrmControl>*/}
            {/*            <UiFrmControl>*/}
            {/*                <TextField*/}
            {/*                    name="importo"*/}
            {/*                    label="importo"*/}
            {/*                    error={validationError(validation, "importo")}*/}
            {/*                    helperText={validationMessage(validation, "importo")}*/}
            {/*                    fullWidth*/}
            {/*                    required*/}
            {/*                    type="text"*/}
            {/*                    defaultValue={item?.importo ?? ""}*/}
            {/*                    onBlur={handleChange}*/}
            {/*                />*/}
            {/*            </UiFrmControl>*/}
            {/*            <UiFrmControl>*/}
            {/*                <FormControl fullWidth>*/}
            {/*                    <DatePicker*/}
            {/*                        label="data"*/}
            {/*                        value={item?.data ?? new Date()}*/}
            {/*                        onChange={(newValue) => {*/}
            {/*                            setItem({*/}
            {/*                                ...item,*/}
            {/*                                data: newValue,*/}
            {/*                            });*/}
            {/*                        }}*/}
            {/*                        renderInput={(params) => <TextField fullWidth {...params} />}*/}
            {/*                    />*/}
            {/*                    {validationError(validation, "data") &&*/}
            {/*                    <FormHelperText*/}
            {/*                        sx={{color: "error.main"}}>{validationMessage(validation, "data")}*/}
            {/*                    </FormHelperText>*/}
            {/*                    }*/}
            {/*                </FormControl>*/}
            {/*            </UiFrmControl>*/}
            {/*        </UiPaper>*/}

            {/*        <UiFormInfo item={item}/>*/}

            {/*        <UiFabContainer>*/}
            {/*            <Fab color="default" size="small"*/}
            {/*                 onClick={async () => {*/}
            {/*                     await reload();*/}
            {/*                 }}>*/}
            {/*                <RefreshIcon/>*/}
            {/*            </Fab>*/}
            {/*            <Fab color="primary" size="small"*/}
            {/*                 onClick={async () => {*/}
            {/*                     if (!window.confirm("confermi"))*/}
            {/*                         return false;*/}
            {/*                     if (await Api.Bilancio.del(item, dispatch)) {*/}
            {/*                         dispatch(showInfo("il record è stato eliminato correttamente"));*/}
            {/*                         dispatch(setCredito((await DbCredito(dispatch))));*/}
            {/*                         navigate(-1);*/}
            {/*                     }*/}
            {/*                 }}>*/}
            {/*                <DeleteIcon/>*/}
            {/*            </Fab>*/}
            {/*            <Fab color="secondary"*/}
            {/*                 onClick={async (e) => {*/}
            {/*                     if (validationState(item, validation, setValidation) == false)*/}
            {/*                         return false;*/}
            {/*                     if (await Api.Bilancio.save(item, dispatch)) {*/}
            {/*                         dispatch(showInfo("il record è stato modificata correttamente"));*/}
            {/*                         dispatch(setCredito((await DbCredito(dispatch))));*/}
            {/*                         navigate(-1);*/}
            {/*                     }*/}
            {/*                     return false;*/}
            {/*                 }}*/}
            {/*            >*/}
            {/*                <SaveIcon/>*/}
            {/*            </Fab>*/}
            {/*        </UiFabContainer>*/}

            {/*    </Box>*/}
            {/*    }*/}

            {/*    {!item && <UiSkeletonForm></UiSkeletonForm>}*/}

            {/*</UiContainer>*/}
        </ React.Fragment>
    );
})