import React from 'react';
import {
    baseCall,
    baseCallPost2Files, deleteEntity,
    get,
    getCallExternalById,
    getExternal, getFile,
    post,
    post2,
    postExternalWithoutFile, saveProdotto
} from "./Db";
import {
    Params_Anagrafica_All,
    Params_Anagrafica_Save, Params_Bilancio_All, Params_Numbers_Numbers,
    Params_Ordine_All, Params_Ordine_Checkout, Params_Ordine_Del, Params_Ordine_Head,
    Params_Ordine_Save
} from "../Generated/Generated";

export const Api = {
    Anagrafica: {
        vetrina: (filters: any, dispatch: any) =>
            get('/anagrafica/vetrina', filters, dispatch),
        checkemail: (filters: any, dispatch: any) =>
            get('/anagrafica/checkemail', filters, dispatch),
        all: (filters: Params_Anagrafica_All, dispatch: any) =>
            get('/anagrafica/all', filters, dispatch),
        cambiopasswordreport: (filters: Params_Anagrafica_All, dispatch: any) =>
            get('/anagrafica/cambiopasswordreport', filters, dispatch),
        allreduced: (filters: any, dispatch: any) =>
            get('/anagrafica/allreduced', filters, dispatch),
        prodotto: (filters: any, dispatch: any) =>
            get('/anagrafica/prodotto', filters, dispatch),
        save: (entity: Params_Anagrafica_Save, dispatch: any) =>
            post('/anagrafica/save', entity, dispatch),                
        saveImage: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            saveProdotto('/anagrafica/saveImage', entity, files, dispatch),
        allimages: (filters: any, dispatch: any) =>
            get('/anagrafica/allimages', filters, dispatch),
        saveconsensi: (entity: any, dispatch: any) =>
            post('/anagrafica/saveconsensi', entity, dispatch),
        validate: (entity: Params_Anagrafica_Save, dispatch: any) =>
            post('/anagrafica/validate', entity, dispatch),
        avatar: (filters: any, dispatch: any) =>
            get('/anagrafica/avatar', filters, dispatch),
        rivenditore: (filters: any, dispatch: any) =>
            get('/anagrafica/rivenditore', filters, dispatch),
        saveavatar: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/anagrafica/saveavatar', entity, files, dispatch),
    },
    public: {
        relogin: (filters: any, dispatch: any) =>
            get('/relogin', filters, dispatch),
        switchLogin: (nomeAttivita: string, dispatch: any) =>
            post('/switch-login', nomeAttivita, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/ordine/head', filters, dispatch),
        save: (entity: Params_Ordine_Save, dispatch: any) =>
            post('/ordine/save', entity, dispatch),
        del: (entity: Params_Ordine_Del, dispatch: any) =>
            post('/ordine/del', entity, dispatch),
        checkout: (entity: any, dispatch: any) =>
            post('/ordine/checkout', entity, dispatch),
        schedulatore_csvincassi_url: (filter, dispatch) => getFile('api/schedulatore_csvincassi', filter, false,dispatch),
        schedulatore_csvanagraficafornitore_url: (filter, dispatch) => getFile('api/schedulatore_csvanagraficafornitore', filter,false, dispatch),
        schedulatore_csvfatturedaemettere_url: (filter, dispatch) => getFile('api/schedulatore_csvfatturedaemettere', filter,false, dispatch),
        schedulatore_csvfatturedariceverecogestori_url: (filter, dispatch) => getFile('api/schedulatore_csvfatturedariceverecogestori', filter,false, dispatch),
    },
    Ordine: {
        all: (filters: Params_Ordine_All, dispatch: any) =>
            get('/ordine/all', filters, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/ordine/head', filters, dispatch),
        save: (entity: Params_Ordine_Save, dispatch: any) =>
            post('/ordine/save', entity, dispatch),
        pagato: (entity: Params_Ordine_Save, dispatch: any) =>
            post('/ordine/pagato', entity, dispatch),
        del: (entity: Params_Ordine_Del, dispatch: any) =>
            post('/ordine/del', entity, dispatch),
        checkout: (entity: any, dispatch: any) =>
            post('/ordine/checkout', entity, dispatch),
    },
    RichiestaPrezzo: {
        all: (filters: any, dispatch: any) =>
            get('/richiestaprezzo/all', filters, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/richiestaprezzo/head', filters, dispatch),
        richiediprezzo: (filters: any, dispatch: any) =>
            get('/richiestaprezzo/richiediprezzo', filters, dispatch),
        dettagli: (filters: any, dispatch: any) =>
            get('/richiestaprezzo/dettagli', filters, dispatch),
        conferma: (filters: any, dispatch: any) =>
            get('/richiestaprezzo/conferma', filters, dispatch),
    },
    NewsLettura: {
        all: (filters: any, dispatch: any) =>
            get('/newslettura/all', filters, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/newslettura/head', filters, dispatch),
        conferma: (filters: any, dispatch: any) =>
            get('/newslettura/conferma', filters, dispatch),
    },
    Numbers: {
        all: (dispatch: any) =>
            get('/numbers/all', null, dispatch),
    },
    Label: {
        all: (filters: any, dispatch: any) =>
            get('/label/all', filters, dispatch),
    },
    Log: {
        all: (filters: any, dispatch: any) =>
            get('/log/all', null, dispatch),
    },
    Privacy: {
        all: (filters: any, dispatch: any) =>
            get('/privacy/all', filters, dispatch),
        getconsensi: (filters: any, dispatch: any) =>
            get('/privacy/getconsensi', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/privacy/save', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/privacy/del', entity, dispatch),
        getbyruolo: (filters: any, dispatch: any) =>
            get('/privacy/getbyruolo', filters, dispatch),
        check: (dispatch: any) =>
            get('/privacy/check', null, dispatch),
    },
    Bilancio: {
        //all: (filters: Params_Bilancio_All, dispatch: any) =>
        //    get('/bilancio/all', filters, dispatch),
        //save: (entity: any, dispatch: any) =>
        //    post('/bilancio/save', entity, dispatch),
        ricarica: (entity: any, dispatch: any) =>
            post('/bilancio/ricarica', entity, dispatch),
        //del: (entity: any, dispatch: any) =>
        //    post('/bilancio/del', entity, dispatch),
    },
    Settore: {
        all: (filters: any, dispatch: any) =>
            get('/settore/all', filters, dispatch),
        prodotto: (filters: any, dispatch: any) =>
            get('/settore/prodotto', filters, dispatch),
        anagrafica: (filters: any, dispatch: any) =>
            get('/settore/anagrafica', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/settore/save', entity, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/settore/del', entity, dispatch),
    },
    Video: {
        all: (filters: any, dispatch: any) =>
            get('/video/all', filters, dispatch),
        settore: (filters: any, dispatch: any) =>
            get('/video/settore', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/video/save', entity, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/video/del', entity, dispatch),
    },
    Condizione: {
        all: (filters: any, dispatch: any) =>
            get('/condizione/all', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/condizione/save', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/condizione/del', entity, dispatch),
    },
    News: {
        all: (filters: any, dispatch: any) =>
            get('/news/all', filters, dispatch),
        settore: (filters: any, dispatch: any) =>
            get('/news/settore', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/news/save', entity, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/news/del', entity, dispatch),
    },
    Banner: {
        all: (filters: any, dispatch: any) =>
            get('/banner/all', filters, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/banner/head', filters, dispatch),
        click: (filters: any, dispatch: any) =>
            get('/banner/click', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/banner/save', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/banner/del', entity, dispatch),
    },
    Schedulatore: {
        all: (filters: any, dispatch: any) =>
            get('/schedulatore/all', filters, dispatch),
        history: (filters: any, dispatch: any) =>
            get('/schedulatore/history', filters, dispatch),
        head: (filters: any, dispatch: any) =>
            get('/schedulatore/head', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/schedulatore/save', entity, dispatch),
    },
    Prodotto: {
        all: (filters: any, dispatch: any) =>
            get('/prodotto/all', filters, dispatch),
        allVetrina: (filters: any, dispatch: any) =>
            get('/prodotto/allVetrina', filters, dispatch),
        allreduced: (filters: any, dispatch: any) =>
            get('/prodotto/allreduced', filters, dispatch),
        findBySku: (filters: any, dispatch: any) =>
            get('/prodotto', filters, dispatch),
        export: (filters: any, dispatch: any) =>
            get('/prodotto/export', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            saveProdotto('/prodotto/saveWithImages', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/prodotto/del', entity, dispatch),
    },
    Manifest: {
        all: (filters: any, dispatch: any) =>
            get('/manifest/all', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/manifest/save', entity, files, dispatch),
    },
    Dorsale: {
        all: (filters: any, dispatch: any) =>
            get('/dorsale/all', filters, dispatch),
        cookie: (filters: any, dispatch: any) =>
            get('/dorsale/cookie', filters, dispatch),
        theme: (filters: any, dispatch: any) =>
            get('/dorsale/theme', filters, dispatch),
        main: (filters: any, dispatch: any) =>
            get('/dorsale/main', filters, dispatch),
        getUsers: (filters: any, dispatch: any) =>
            get('/dorsale/getUsers', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/dorsale/save', entity, files, dispatch),
        changepassword: (entity: any, dispatch: any) =>
            post('/dorsale/changepassword', entity, dispatch),   
        ResetPassword: (entity: any, dispatch: any) =>
            post('/dorsale/reset-password', entity, dispatch),
        needchangepassword: (filters: any, dispatch: any) =>
            get('/dorsale/needchangepassword', filters, dispatch),
    },
    MailTemplateMicroservice: {
        findAllTemplates: (filters: any, dispatch: any) =>
            getExternal('/mail-streamer/api/findAllDorsale', filters, dispatch),
        del: (entity: any, dispatch: any) =>
            deleteEntity('/mail-streamer/api/delete', entity, dispatch),

        findById: (id: string, dispatch: any) =>
            getCallExternalById('/mail-streamer/api/findById', id, dispatch),
        saveTemplate: (entity: any, dispatch: any) =>
            postExternalWithoutFile('/mail-streamer/api/save', entity, dispatch),
    },
    MailTemplate: {
        all: (filters: any, dispatch: any) =>
            get('/mailtemplate/all', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/mailtemplate/save', entity, files, dispatch),
    },
    Categoria: {
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/categoria/save', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/categoria/del', entity, dispatch),
    },
    SottoCategoria: {
        all: (filters: any, dispatch: any) =>
            get('/sottocategoria/all', filters, dispatch),
        save: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/sottocategoria/save', entity, files, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/sottocategoria/del', entity, dispatch),
    },
    NewsCategoria: {
        all: (filters: any, dispatch: any) =>
            get('/newscategoria/all', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/newscategoria/save', entity, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/newscategoria/del', entity, dispatch),
    },
    NewsMacroCategoria: {
        all: (filters: any, dispatch: any) =>
            get('/newsmacrocategoria/all', filters, dispatch),
        save: (entity: any, dispatch: any) =>
            post('/newsmacrocategoria/save', entity, dispatch),
        del: (entity: any, dispatch: any) =>
            post('/newsmacrocategoria/del', entity, dispatch),
    },
    Media: {
        all: (filters: any, dispatch: any) =>
            get('/media/all', filters, dispatch),
    },
    Search: {
        all: (filters: any, dispatch: any) =>
            get('/search/all', filters, dispatch),
    },
    Import: {
        anagraficatracciato_url: () => '/db/import/anagraficatracciato',
        anagraficatracciatoimport: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/import/anagraficatracciatoimport', entity, files, dispatch),
        prodottotracciato_url: () => '/db/import/prodottotracciato',
        prodottotracciatoimport: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/import/prodottotracciatoimport', entity, files, dispatch),
        settoretracciato_url: () => '/db/import/settoretracciato',
        settoretracciatoimport: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/import/settoretracciatoimport', entity, files, dispatch),
        invitomassivotracciato_url: () => '/db/import/invitomassivotracciato',
        invitomassivo: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/import/invitomassivo', entity, files, dispatch),
        invitomassivodipendente: (entity: any, files: Array<baseCallPost2Files>, dispatch: any) =>
            post2('/import/invitomassivocittadino', entity, files, dispatch),
        downloadTemplate: (url: string, dispatch: any) => getFile(url, null,false, dispatch),
        downloadReport: (url: string, filters: any, dispatch: any) => getFile(url, filters,true, dispatch)
    },
};
export default Api;
