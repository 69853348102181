import React from 'react';
import {useNavigate} from "react-router-dom";
import {BaseGrid} from "../../../../components/BaseGrid";
import Api from "../../../../Api";


export default function TemplateOrdini(props) {

    const navigate = useNavigate(); 

    return (
        <React.Fragment>
            <BaseGrid hideMod={true}
                getDataSourceAsync={Api.MailTemplateMicroservice.findAllTemplates}
                onRowClick={(row) => {
                    navigate(`/template-ordini/edit/${row.id}`);
                }}
                onNew={() => {
                    navigate(`/template-ordini/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'categoria',
                        label: 'Categoria',
                        chip: true,
                        hide: true
                        //primary: true,
                        //mobile: true,
                    },
                    {
                        id: 'sottocategoria',
                        label: 'Sottocategoria',
                        chip: true,
                        hide: true
                    },
                    {
                        id: 'defaultEmail',
                        label: 'Default Email',
                        chip: true,
                        hide: true
                    },
                    {
                        id: 'subject',
                        label: 'Oggetto',
                        mobile: true
                    },
                    {
                        id: 'body',
                        label: 'Contenuto',
                        mobile: true
                    }
                ]}
            />
        </React.Fragment>
    )
}