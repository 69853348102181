import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Api from "../../Api";
import {Box, Button, MenuItem, Modal} from "@mui/material";
import TextField from "@mui/material/TextField";
import {setDialogMessage, setLoadingMessage, setMessage} from "../../reducers";
import {HookIsMobile, UiFrmControl} from "../../utils/Helpers";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch } from "react-redux";

export default React.memo(function ChangePassword(props) {
    const im = HookIsMobile();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = React.useState<any>({});
    const [open, setopen] = React.useState<boolean>(false);
    const handleChange = (event: any) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(() => {
        (async () => {
            var res = await Api.Dorsale.needchangepassword(null, dispatch);
            if (res == true)
                setopen(true);
        })();
    }, [])
    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => {
                    /*              setRichiediPrezzo(null);*/
                }}
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: im ? "90vw" : "auto",
                    bgcolor: 'background.paper',
                    p: 2,
                }}>
                    <Box>
                        Devi impostare una password per poter continuare!
                    </Box>
                    <form action="#" id="form">
                        <UiFrmControl>
                            <TextField
                                defaultValue={""}
                                name="password"
                                fullWidth
                                required={true}
                                helperText={"la password deve essere di almeno 8 caratteri, contenere almeno un numero e un carattere speciale"}
                                autoComplete={"current-password"}
                                label="password"
                                type="password"
                                onBlur={handleChange}
                            />
                        </UiFrmControl>
                        <UiFrmControl>
                            <TextField
                                defaultValue={""}
                                name="password2"
                                required={true}
                                fullWidth
                                autoComplete={"current-password"}
                                label="conferma password"
                                type="password"
                                onBlur={handleChange}
                            />
                        </UiFrmControl>
                    </form>
                    <Box sx={{ mt: 4 }}>
                        <Button onClick={async () => {
                            const form = document.querySelector("#form") as HTMLFormElement;
                            const password2 = document.querySelector('input[name="password2"]') as HTMLInputElement;
                            const password = document.querySelector('input[name="password"]') as HTMLInputElement;
                            password2?.setCustomValidity("");
                            password?.setCustomValidity("");
                            const res = form.reportValidity();
                            if (!res)
                                return false;
                            if (password?.value != null && password?.value != "") {
                                const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
                                const result = regex.test(password?.value);
                                if (!result) {
                                    password?.setCustomValidity("la password deve essere di almeno 8 caratteri, contenere almeno un numero e un carattere speciale");
                                    form.reportValidity();
                                    return false;
                                }
                            }
                            if (password?.value != password2?.value) {
                                password2?.setCustomValidity("le password inserite non coincidono");
                                form.reportValidity();
                                return false;
                            }
                            dispatch(setLoadingMessage("impostazione password in corso..."));
                            if (await Api.Dorsale.changepassword(data, dispatch)) {
                                dispatch(setDialogMessage("Password impostata correttamente"));
                                setopen(false);
                            }
                            dispatch(setLoadingMessage(null));
                        }} variant={"contained"} color={"primary"}>
                            conferma password
                        </Button>
                        <Button sx={{ ml: 2 }} onClick={async () => {
                            document.location.href = "/db/logout";
                        }} variant={"contained"} color={"primary"}>
                            esci
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    )
})