import React, {useState} from 'react';
import {UiPaper} from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {Box, Button, Grid, InputAdornment} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import {DbLogin} from "../../Db";
import {useDispatch} from "react-redux";

function SwitchAttivita(props) {
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const {email_switch_attivita} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <UiPaper title={"Cambia attività"}>
            <Box sx={{}}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12}>
                        <TextField
                            name="password"
                            fullWidth
                            required
                            autoComplete={"current-password"}
                            label="password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={handlePasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '90%' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={async () => {
                                //chiamo lo switch api
                                
                                const data = {
                                    email: email_switch_attivita,
                                    password: password
                                }
                                let switchLoginResponse = await DbLogin(data, dispatch);
                                if (switchLoginResponse != null) {
                                    window.location.replace("/dashboard")
                                }
                            }}
                            sx={{
                                textAlign: "left",
                                justifyContent: "center",
                                padding: 1,
                            }}
                        >
                            Cambia Attività
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </UiPaper>
    );


}

export default SwitchAttivita;