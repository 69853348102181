import React from 'react';
import {DbCredito} from "./Db";
import {setCredito, SetNumbers} from "./reducers";
import Api from "./Api";

export function getCacheData() {
    const data = localStorage.getItem("cache");
    if (data != null)
        return JSON.parse(data as string);
}

export function setCacheData(key, data) {
    var cache = getCacheData();
    if (cache == null)
        cache = {};
    cache[key] = data;
    localStorage.setItem("cache", JSON.stringify(cache));
}

export function clearCache() {
    localStorage.removeItem("cache");
}