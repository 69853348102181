import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import {useNavigate, useParams} from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, CircularProgress, MenuItem} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Api from "../../../Api";
import {DbNews, DbNewsDel} from "../../../Db";
import {setLoadingMessage, showInfo} from "../../../reducers";
import {PageHeader, UiContainer, UiFabContainer, UiFrmControl, UiPaper, UiSkeletonForm} from "../../../utils/Helpers";
import {TextTitle} from "../../../utils/Text";
import {validationError, validationMessage, validationState} from "../../../utils/Functions";

export default React.memo(function NewsEdit() {
    let { guid } = useParams<any>();
    let { conferma } = useParams<any>();
    const [settori, setSettori] = React.useState<any>([]);
    const [settoriSelected, setSettoriSelected] = React.useState<any>([]);
    const [settoriOpen, setSettoriOpen] = React.useState(false);
    const [settoriLoading, setSettoriLoading] = React.useState(false);
    const [settoriLabel, setSettoriLabel] = React.useState<any>(null);
    const [categorie, setcategorie] = React.useState<any>([]);
    const [macrocategorie, setmacrocategorie] = React.useState<any>([]);
    const [item, setItem] = React.useState<any>(null);
    const [validation, setValidation] = React.useState<Array<any>>([
        {
            id: "titolo",
            required: true,
        },
        {
            id: "contenuto",
            required: true,
        },
    ]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);
    useEffect(() => {
        (async () => {
            if (settori.length == 0 && settoriOpen) {
                setSettoriLoading(true);
                setSettori((await Api.Settore.all(null, dispatch)).data);
                setSettoriLoading(false);
            }
        })();
    }, [settoriOpen]);
    useEffect(() => {
        if (settoriSelected) if (settoriSelected.length > 0)
            setSettoriLabel("categorie selezionate");
        else
            setSettoriLabel("settori");
    }, [settoriSelected]);
    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };


    async function reload() {
        const dbNews = (await DbNews({ guid: guid }, dispatch));
        if (guid != "")
            setItem(dbNews);
        else
            setItem({});
        setSettoriSelected((await Api.News.settore({ guid: dbNews.guid }, dispatch)).data);
    }

    useEffect(() => {
        (async () => {
            setcategorie(await Api.NewsCategoria.all(null, dispatch));
            setmacrocategorie(await Api.NewsMacroCategoria.all(null, dispatch));
            dispatch(setLoadingMessage("caricamento in corso..."))
            await reload();
            dispatch(setLoadingMessage(null));
            if (conferma == "conferma") {
                await Api.NewsLettura.conferma({ guid_news: guid }, dispatch);
            }
        })();
    }, [])

    function canEdit() {
        return root.LoginData.role == "ADMIN" || root.LoginData.role == "COGESTORE";
    }

    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.nome} />
                {item &&
                    <Box component="form"
                        id={"myForm"}
                        autoComplete="off">
                        <UiPaper padding title={"notizia"}>
                            {!canEdit() &&
                                <React.Fragment>
                                    <UiFrmControl first>
                                        <TextTitle>
                                            {item.titolo}
                                        </TextTitle>
                                    </UiFrmControl>
                                    <UiFrmControl>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: item.contenuto.replaceAll("\n", "<BR>") }} />
                                    </UiFrmControl>
                                </React.Fragment>
                            }
                            {canEdit() &&
                                <UiFrmControl first>
                                    <TextField
                                        disabled
                                        name="guid"
                                        label="guid"
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.guid ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            }
                            {canEdit() &&
                                <UiFrmControl>
                                    <TextField
                                        name="titolo"
                                        label="titolo"
                                        error={validationError(validation, "titolo")}
                                        helperText={validationMessage(validation, "titolo")}
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.titolo ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            }
                            {canEdit() &&
                                <UiFrmControl>
                                    <TextField
                                        name="link"
                                        label="link"
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.link ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            }
                            {canEdit() && macrocategorie && macrocategorie.data &&
                                <UiFrmControl>
                                    <TextField
                                        name="macrocategoria_guid"
                                        select
                                        fullWidth
                                        defaultValue={item?.macrocategoria_guid ?? ""}
                                        label="macro categoria"
                                        onBlur={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>
                                            NESSUNA
                                        </MenuItem>
                                        {macrocategorie.data.map((option) => (
                                            <MenuItem key={option.nome} value={option.guid}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </UiFrmControl>
                            }
                            {canEdit() && categorie && categorie.data &&
                                <UiFrmControl>
                                    <TextField
                                        name="categoria_guid"
                                        select
                                        fullWidth
                                        defaultValue={item?.categoria_guid ?? ""}
                                        label="categoria"
                                        onBlur={handleChange}
                                    >
                                        <MenuItem key={""} value={""}>
                                            NESSUNA
                                        </MenuItem>
                                        {categorie.data.filter(x => x.guid_macrocategoria == item?.macrocategoria_guid).map((option) => (
                                            <MenuItem key={option.nome} value={option.guid}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </UiFrmControl>
                            }
                            {canEdit() &&
                                <UiFrmControl>
                                    <Autocomplete
                                        multiple
                                        options={settori ?? []}
                                        getOptionLabel={(option: any) => option?.nome as any}
                                        isOptionEqualToValue={(option, value) => option.guid === value.guid}
                                        onOpen={() => {
                                            setSettoriOpen(true);
                                        }}
                                        onClose={() => {
                                            setSettoriOpen(false);
                                        }}
                                        loading={settoriLoading}
                                        value={settoriSelected ?? []}
                                        onChange={(_, newValue) => {
                                            setSettoriSelected(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name={"settori"}
                                                label={settoriLabel}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {settoriLoading ?
                                                                <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </UiFrmControl>
                            }
                            {canEdit() &&
                                <UiFrmControl>
                                    <TextField
                                        name="contenuto"
                                        label="contenuto"
                                        error={validationError(validation, "contenuto")}
                                        helperText={validationMessage(validation, "contenuto")}
                                        multiline
                                        maxRows={10}
                                        fullWidth
                                        required
                                        type="text"
                                        defaultValue={item?.contenuto ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                            }
                            <UiFrmControl>
                                <TextField
                                    select
                                    name={"notification"}
                                    label={"notifica"}
                                    defaultValue={item.notification ?? "NONE"}

                                    onBlur={handleChange}
                                >
                                    <MenuItem value={"PUSH"}>PUSH</MenuItem>
                                    <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
                                    <MenuItem value={"ANY"}>ENTRAMBE</MenuItem>
                                    <MenuItem value={"NONE"}>NESSUNA</MenuItem>
                                </TextField>
                            </UiFrmControl>
                        </UiPaper>

                        <UiFabContainer>
                            <Fab color="default" size="small"
                                onClick={async () => {
                                    await reload();
                                }}>
                                <RefreshIcon />
                            </Fab>
                            {canEdit() &&
                                <React.Fragment>
                                    <Fab color="primary" size="small"
                                         onClick={async () => {
                                             if (!window.confirm("Confermi?"))
                                                 return false;
                                             if (await DbNewsDel(item, dispatch)) {
                                                 dispatch(showInfo("il record è stato eliminato correttamente"));
                                                 navigate(-1);
                                             }
                                         }}>
                                        <DeleteIcon/>
                                    </Fab>
                                    <Fab color="secondary"
                                         onClick={async (e) => {
                                             if (validationState(item, validation, setValidation) == false)
                                                 return false;
                                             dispatch(setLoadingMessage("salvataggio in corso..."))
                                             item.settoriSelected = settoriSelected;                                         
                                             if (await Api.News.save(item, dispatch)) {
                                                 dispatch(showInfo("il record è stato modificato correttamente"));
                                                 navigate(-1);
                                                 dispatch(setLoadingMessage(null))
                                                 return false;
                                             } else {
                                                 dispatch(setLoadingMessage(null))
                                                 await reload();
                                             }
                                             dispatch(setLoadingMessage(null))
                                             return false;
                                         }}>
                                        <SaveIcon/>
                                    </Fab>
                                </React.Fragment>
                            }
                            <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                        </UiFabContainer>

                    </Box>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}
            </UiContainer>
        </ React.Fragment>
    );
})