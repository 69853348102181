import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import React from "react";
import {HookIsMobile} from "../../utils/Helpers";
import {TextDescription, TextTitle} from "../../utils/Text";

export function NewsDialog(props: any) {
    const [scroll] = React.useState<DialogProps['scroll']>('body');
    const im = HookIsMobile();
    return <Dialog
        fullScreen={im}
        open={props.currNews != null}
        onClose={() => {
            props.setCurrNews(null);
        }}
    >
        <DialogTitle>
            <TextTitle>
                {props.currNews.titolo}
            </TextTitle>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
            <TextDescription>
                <div dangerouslySetInnerHTML={{ __html: props.currNews.contenuto?.replaceAll("\n", "<BR>") }} />
            </TextDescription>
            {props.currNews?.link &&
                <Box sx={{
                    mt: 2,
                    textDecoration: "underline",
                    fontSize: 13
                }}>
                    <a target={"_blank"} href={props.currNews.link}>clicca qui per saperne di più</a>
                </Box>
            }
        </DialogContent>
        <DialogActions>
            <Button variant={"outlined"} size={"large"} color={"primary"}
                onClick={() => {
                    props.setCurrNews(null);
                }}>
                chiudi
            </Button>
        </DialogActions>
    </Dialog>;
}