import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {BaseGrid} from "../../../components/BaseGrid";
import Api from "../../../Api";


export default function Settori(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.Settore.all}
                onRowClick={(row) => {
                    navigate(`edit/${row.guid}/${encodeURIComponent(row.nome)}`);
                }}
                onNew={() => {
                    navigate(`nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                ]}
            />
        </React.Fragment>)
}