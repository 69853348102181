import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HookIsMobile } from "../../utils/Helpers";
import { Box, Button, MenuItem, Modal } from "@mui/material";
import TextField from "@mui/material/TextField";
import { setLoadingMessage } from "../../reducers";
import Api from "../../Api";
import { isSmallScreen } from "../../utils/Functions";
import { DbAnagrafica } from "../../Db";

export function ModalRichiediPrezzo(props: any) {
    const [richiediPrezzoTipologia, setRichiediPrezzoTipologia] = React.useState<any>(null);
    const [richiediPrezzoDettaglio, setRichiediPrezzoDettaglio] = React.useState<any>(null);
    const [anagrafica, setAnagrafica] = React.useState<any>({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    useEffect(() => {
        (async () => {
            if (props.richiediPrezzo != null) {
                setAnagrafica(await Api.Anagrafica.all({ mine: true }, dispatch));
            }
        })();
    }, [props.richiediPrezzo])
    return <Modal
        open={props.richiediPrezzo != null}
        onClose={() => {
            props.setRichiediPrezzo(null);
        }}
    >
        {anagrafica &&
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: im ? "90vw" : "auto",
                bgcolor: 'background.paper',
                p: 2,
            }}>
                <Box>
                    Vorrei essere ricontattato per avere maggiori info rispetto a questo prodotto!
                </Box>
                <Box sx={{ my: 3 }}>
                    <TextField
                        select
                        label="contatto"
                        value={richiediPrezzoTipologia ?? "-1"}
                        onChange={(event) => {
                            if (event.target.value == "EMAIL")
                                setRichiediPrezzoDettaglio(anagrafica.email);
                            if (event.target.value == "TELEFONO")
                                setRichiediPrezzoDettaglio(anagrafica.telefono);
                            setRichiediPrezzoTipologia(event.target.value);
                        }}
                    >
                        <MenuItem value={"-1"}>SELEZIONA METODO DI CONTATTO</MenuItem>
                        <MenuItem value={"EMAIL"}>EMAIL</MenuItem>
                        <MenuItem value={"TELEFONO"}>TELEFONO</MenuItem>
                    </TextField>
                    <Box sx={{ mt: 1, fontWeight: 700, fontSize: 12 }}>
                        *Ti ricordiamo che sarai contattato al numero di telefono o alla mail che hai inserito
                        nel
                        tuo profilo.
                    </Box>
                </Box>
                {(richiediPrezzoTipologia == "EMAIL" || richiediPrezzoTipologia == "TELEFONO") &&
                    <TextField
                        label="contatto"
                        fullWidth
                        type="text"
                        value={richiediPrezzoDettaglio ?? ""}
                        onChange={(e) => {
                            setRichiediPrezzoDettaglio(e.target.value);
                        }}
                    />
                }
                <Button sx={{ mt: 2 }} variant={"contained"} onClick={async () => {
                    if (richiediPrezzoTipologia == null || richiediPrezzoTipologia == "-1") {
                        alert("Seleziona la tipologia di contatto");
                        return false;
                    }
                    dispatch(setLoadingMessage("invio in corso..."));
                    const res = await Api.RichiestaPrezzo.richiediprezzo({
                        sku: props.richiediPrezzo?.sku,
                        tipo_contatto: richiediPrezzoTipologia,
                        richiediPrezzoDettaglio: richiediPrezzoDettaglio
                    }, dispatch);
                    alert("Grazie per la tua richiesta verrai ricontattato nel più breve tempo possibile");
                    props.setRichiediPrezzo(null);
                    dispatch(setLoadingMessage(null));
                }}>
                    Invia richiesta
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} variant={"outlined"} color={"primary"}
                    onClick={(e) => {
                        props.setRichiediPrezzo(null);
                    }}>
                    annulla
                </Button>
            </Box>
        }
    </Modal>;
}