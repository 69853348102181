import { useState } from "react";
import { HookIsMobile } from "../utils/Helpers";
import { Avatar, Box, Button, Divider, IconButton, Stack } from "@mui/material";
import TextEllipsis from "react-text-ellipsis";
import { currencyFormat, getNewProdottoImmagine } from "../utils/Functions";
import {
	TextTitle,
	TextDescription,
	TextErrorSmall,
	TextTitleSecondarySmall,
	TextTitleSmall,
} from "../utils/Text";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { ModalRichiediPrezzo } from "./modals/ModalRichiediPrezzo";

export default function BaseProdottoOrizzontale(props: any) {
	const [richiediPrezzo, setRichiediPrezzo] = useState<any>(null);
	const im = HookIsMobile();
	const imageSizeW = im ? "100%" : 130;
	const imageSizeH = 130;
	const prodotto = props.prodotto;
	const numeroProdotti = props.numeroProdotti;
	const pIndex = props.pIndex;

	return (
		<>
			<Stack
				style={props.style}
				sx={{ backgroundColor: "", height: im ? "auto" : 140, minWidth: 180 }}
				direction={im ? "column" : "row"}
				alignItems={"stretch"}
				justifyContent={"stretch"}
				alignContent={"stretch"}
				spacing={1}>
				<>
					<Avatar
						variant='rounded'
						sx={{ width: imageSizeW, height: imageSizeH }}
						src={
							prodotto?.ProdottoToMedia[0]
								? getNewProdottoImmagine(
										prodotto?.ProdottoToMedia[0],
										im ? Math.round(window.innerWidth) : 300
								  )
								: "https://biellaup.it/static-content/images/distretto_commercio_logo.png"
						}
					/>
				</>
				<Stack
					direction={"column"}
					alignItems={im ? "center" : "stretch"}
					justifyContent={"space-between"}
					alignContent={"space-between"}
					spacing={1}
					sx={{ width: "100%" }}>
					<TextTitle>
						<TextEllipsis lines={1}>{prodotto.nome}</TextEllipsis>
					</TextTitle>
					<TextTitleSmall>
						<TextEllipsis lines={1}>{prodotto.nome_fornitore}</TextEllipsis>
					</TextTitleSmall>
					<Box display={"flex"} height={im ? 40 : "auto"} flexGrow={1}>
						<TextDescription>
							<TextEllipsis lines={2}>{prodotto.descrizione}</TextEllipsis>
						</TextDescription>
					</Box>
					<Stack
						sx={{ width: "100%" }}
						direction={im ? "column" : "row"}
						alignItems={"center"}
						alignContent={"center"}
						justifyContent={"space-between"}
						spacing={1}>
						<Stack
							direction={"column"}
							alignItems={"center"}
							justifyContent={"center"}
							minHeight={"43px"}
							spacing={1}
							flexGrow={1}>
							{!prodotto.offerta && prodotto.prezzo > 0 && (
								<TextTitleSecondarySmall style={{ whiteSpace: "noWrap" }}>
									{currencyFormat(prodotto.prezzo)}
								</TextTitleSecondarySmall>
							)}
							{!!(prodotto.offerta && prodotto.prezzo > 0) && (
								<>
									<TextTitleSmall sx={{ textDecoration: "line-through", whiteSpace: "noWrap" }}>
										{currencyFormat(prodotto.prezzo)}
									</TextTitleSmall>
									<TextTitleSecondarySmall style={{ whiteSpace: "noWrap" }}>
										{currencyFormat(prodotto.offerta)}
									</TextTitleSecondarySmall>
								</>
							)}
						</Stack>
						<Stack direction={"row"} alignItems={"center"} spacing={0}>
							<IconButton
								color={"default"}
								onClick={async () => {
									props.onZoom(prodotto);
								}}>
								<ZoomOutMapIcon />
							</IconButton>
							{prodotto.quantita === 0 && (
								<Box>
									<TextErrorSmall>ESAURITO</TextErrorSmall>
								</Box>
							)}
							{prodotto.quantita > 0 && prodotto.prezzo > 0 && (
								<IconButton
									color={"secondary"}
									onClick={async () => {
										props.onAddToCart(prodotto);
									}}>
									<AddShoppingCartIcon />
								</IconButton>
							)}
							{prodotto.prezzo <= 0 && (
								<Button
									sx={{ whiteSpace: "nowrap" }}
									size={"small"}
									variant={"outlined"}
									onClick={() => {
										setRichiediPrezzo(prodotto);
									}}>
									{prodotto.richiedi_preventivo_t == null
										? "richiedi preventivo"
										: prodotto.richiedi_preventivo_t}
								</Button>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<ModalRichiediPrezzo setRichiediPrezzo={setRichiediPrezzo} richiediPrezzo={richiediPrezzo} />
			{numeroProdotti - 1 !== pIndex && (
				<Box sx={{ pb: 2, pt: 1 }}>
					<Divider />
				</Box>
			)}
		</>
	);
}
