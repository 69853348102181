import React, {useEffect} from 'react';
import {setLoadingMessage, showInfo} from "../../reducers";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle, Divider,
    FormControl, FormControlLabel,
    FormHelperText,
    MenuItem
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiContainer,
    UiFabContainer,
    UiFrmControl, UiFormInfo
} from "../../utils/Helpers";
import {
    DbAnagrafica,
    DbAnagraficaDel,
    DbComune,
    DbDorsale,
    DbFornitoreToCogestore,
    DbRuolo
} from "../../Db";
import RefreshIcon from "@mui/icons-material/Refresh";
import Autocomplete from "@mui/material/Autocomplete";
import {HasGrant, IsRuolo, RUOLO} from "../../utils/Functions";
import Api from "../../Api";
import Checkbox from "@mui/material/Checkbox";
import {TextTitleSecondarySmall} from "../../utils/Text";
import Grid from "@mui/material/Grid";

export default React.memo(function AnagraficaEditCliente_WOLF() {
    let { guid } = useParams<any>();
    const [anagrafica, setAnagrafica] = React.useState<any>(null);
    const [dorsale, setDorsale] = React.useState<any>(null);
    const [comuni, setComuni] = React.useState<any>(null);
    const [prov, setProv] = React.useState<any>(null);
    const [ruoli, setRuoli] = React.useState<any>(null);
    const [settori, setSettori] = React.useState<any>(null);
    const [clienti, setClienti] = React.useState<any>(null);
    const [fornitori, setFornitori] = React.useState<any>([]);
    const [fornitoreToCogestore, setFornitoreToCogestore] = React.useState<any>([]);
    const [dialogContent, setDialogContent] = React.useState<any>(null);
    const [settoriSelected, setSettoriSelected] = React.useState<any>(null);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');
    const [prodotti, setProdotti] = React.useState<any>([]);
    const [prodottiSelected, setProdottiSelected] = React.useState<any>([]);
    const [prodottiOpen, setProdottiOpen] = React.useState(false);
    const [prodottiLoading, setProdottiLoading] = React.useState(false);
    const [prodottiLabel, setProdottiLabel] = React.useState<any>(null);
    const [consensi, setConsensi] = React.useState<any>([]);
    const nazioni = [{ code: "it", label: "Italia" }, { code: "ee", label: "Estero" }];
    const root = useSelector((state: RootStateOrAny) => state);
    const im = HookIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let { ruolo } = useParams<any>();
    useEffect(() => {
        (async () => {
            if (prodotti.length == 0 && prodottiOpen) {
                setProdottiLoading(true);
                setProdotti((await Api.Prodotto.allreduced(null, dispatch)).data);
                setProdottiLoading(false);
            }
        })();
    }, [prodottiOpen]);
    React.useEffect(() => {
        if (prodottiSelected) if (prodottiSelected.length > 0)
            setProdottiLabel("prodotti selezionati");
        else
            setProdottiLabel("tutti i prodotti sono associati");
    }, [prodottiSelected]);
    useEffect(() => {
        (async () => {
            await reload();
            setRuoli((await DbRuolo(dispatch)));
            if (root?.isLogged == true)
                setSettori((await Api.Settore.all(null, dispatch))?.data);
            setDorsale((await DbDorsale(null, dispatch)));
            getData();
        })();
    }, [])

    function isDipendente() {
        return (ruolo?.toUpperCase() == "DIPENDENTE" || anagrafica?.ruolo?.toUpperCase() == "DIPENDENTE");
    }

    function isFornitore() {
        return (ruolo?.toUpperCase() == "FORNITORE" || anagrafica?.ruolo?.toUpperCase() == "FORNITORE");
    }

    async function register() {
        const required = true;
        const form = document.querySelector("form") as HTMLFormElement;
        const email = document.querySelector('input[name="email"]') as HTMLInputElement;
        const email_pec = document.querySelector('input[name="email_pec"]') as HTMLInputElement;
        const sdi = document.querySelector('input[name="sdi"]') as HTMLInputElement;
        const password = document.querySelector('input[name="password"]') as HTMLInputElement;
        let reportVal = true;
        if (form != null) {
            if (anagrafica.guid == null && email?.value != null && email?.value != "") {
                const check = await Api.Anagrafica.checkemail({ email: email?.value }, dispatch);
                if (!check) {
                    email?.setCustomValidity("Indirizzo email già registrato, sceglierne un altro!!!");
                    form.reportValidity();
                    return false;
                } else {
                    email?.setCustomValidity("");
                    reportVal = form.reportValidity();
                }
            }
            if (required && !(sdi == null) && email_pec.value === "" && sdi.value === "") {
                sdi.setCustomValidity("specificare una mail pec valido o in alternativa il campo SDI");
                form.reportValidity();
                return false;
            }
            sdi?.setCustomValidity("");
            if (required && password?.value != null && password?.value != "") {
                const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
                const result = regex.test(password?.value);
                if (!result) {
                    password?.setCustomValidity("la password deve essere di almeno 8 caaratteri, contenere almeno un numero e un carattere speciale");
                    form.reportValidity();
                    return false;
                } else
                    password?.setCustomValidity("");
            }
            if (reportVal)
                reportVal = form.reportValidity();
        }
        if (!reportVal)
            return false;
        dispatch(setLoadingMessage("salvataggio in corso..."))
        anagrafica.fornitoreToCogestore = fornitoreToCogestore;
        anagrafica.settori = settoriSelected;
        anagrafica.prodottiSelected = prodottiSelected;
        anagrafica.consensi = consensi;
        if (await Api.Anagrafica.save(anagrafica, dispatch)) {
            if (anagrafica.guid == null)
                dispatch(showInfo("Utente creato correttamente"));
            else
                dispatch(showInfo("Utente modificato correttamente"));
        } else {
            dispatch(setLoadingMessage(null))
            return false;
        }
        if ((location.pathname != "/miei-dati") && root.isLogged)
            navigate(-1);
        else if (!root.isLogged) {
            navigate("/login");
        }
        dispatch(setLoadingMessage(null))
        return false;
    }

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnagrafica({
            ...anagrafica,
            [event.target.name]: event.target.checked,
        });
    };
    const handleChange = (event: any) => {
        setAnagrafica({
            ...anagrafica,
            [event.target.name]: event.target.value,
        });
    };

    async function reload() {
        if (root.isLogged == true) {
            let dbAnagrafica: any;
            dispatch(setLoadingMessage("caricamento in corso..."));
            if (location.pathname == "/miei-dati")
                dbAnagrafica = (await Api.Anagrafica.all({ mine: true }, dispatch));
            else
                dbAnagrafica = (await Api.Anagrafica.all({ guid: guid }, dispatch));
            dispatch(setLoadingMessage(null));
            setAnagrafica(dbAnagrafica);
            setConsensi(await Api.Privacy.getconsensi({ email: dbAnagrafica.email }, dispatch));
            setClienti((await Api.Anagrafica.allreduced({ ruolo: "CLIENTE" }, dispatch))?.data);
            setFornitori((await Api.Anagrafica.allreduced({ ruolo: "FORNITORE" }, dispatch))?.data);
            if (dbAnagrafica.prov)
                setComuni(await DbComune(dbAnagrafica.prov, dispatch));
            setFornitoreToCogestore((await DbFornitoreToCogestore(dbAnagrafica.email, dispatch)));
            setSettoriSelected((await Api.Settore.anagrafica({ email: dbAnagrafica.email }, dispatch)).data);
            setProdottiSelected((await Api.Anagrafica.prodotto({ email: dbAnagrafica.email }, dispatch)).data);
        } else {
            setAnagrafica({ ruolo: ruolo });
        }
    }

    const getData = () => {
        fetch('Json/prov.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setProv((myJson))
            });
    }
    return (
        <React.Fragment>
            {anagrafica &&
                <UiContainer hideBox>
                    {anagrafica?.guid && <PageHeader title={anagrafica.nome + " " + anagrafica.cognome} />}
                    {!anagrafica?.guid && <PageHeader title={"Registrazione Nuovo Utente"} />}

                    {<Box component="form"
                        id={"myForm"}
                        autoComplete="off">

                        <Grid sx={{ mb: 2 }} container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                {HasGrant(root, RUOLO.ADMIN) &&
                                    <UiPaper className={"box box_padding"} title={"utility"}>
                                        <UiFrmControl first>
                                            <TextField
                                                disabled
                                                fullWidth
                                                label="guid"
                                                type="text"
                                                defaultValue={anagrafica.guid ?? ""}
                                            />
                                        </UiFrmControl>
                                        <UiFrmControl>
                                            {ruoli &&
                                                <TextField
                                                    name="ruolo"
                                                    disabled={false}
                                                    select
                                                    fullWidth
                                                    required
                                                    value={ruolo?.toLowerCase() ?? anagrafica.ruolo?.toLowerCase() ?? ""}
                                                    label="ruolo"
                                                    onChange={handleChange}
                                                >
                                                    {ruoli.map((option) => (
                                                        <MenuItem key={option.nome} value={option.nome}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                        </UiFrmControl>
                                        {settori &&
                                            <UiFrmControl>
                                                <Autocomplete
                                                    multiple
                                                    options={settori}
                                                    isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                                    getOptionLabel={(option: any) => option.nome}
                                                    value={settoriSelected ?? []}
                                                    onChange={(_, newValue) => {
                                                        setSettoriSelected(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            name={"settori"}
                                                            label="categorie"
                                                            placeholder="categorie"
                                                        />
                                                    )}
                                                />
                                            </UiFrmControl>
                                        }
                                        <UiFrmControl>
                                            <Autocomplete
                                                multiple
                                                options={prodotti ?? []}
                                                getOptionLabel={(option: any) => option?.nome as any}
                                                isOptionEqualToValue={(option, value) => option.sku === value.sku}
                                                onOpen={() => {
                                                    setProdottiOpen(true);
                                                }}
                                                onClose={() => {
                                                    setProdottiOpen(false);
                                                }}
                                                loading={prodottiLoading}
                                                value={prodottiSelected ?? []}
                                                onChange={(_, newValue) => {
                                                    setProdottiSelected(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        name={"prodotti"}
                                                        label={prodottiLabel}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {prodottiLoading ?
                                                                        <CircularProgress color="inherit"
                                                                            size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </UiFrmControl>

                                        {clienti && anagrafica?.ruolo?.toUpperCase() == "DIPENDENTE" &&
                                            <React.Fragment>
                                                <UiFrmControl>
                                                    <TextField
                                                        name="email_cliente"
                                                        select
                                                        fullWidth
                                                        defaultValue={anagrafica?.email_cliente ?? ""}
                                                        label="cliente"
                                                        onBlur={handleChange}
                                                    >
                                                        {clienti.map((option) => (
                                                            <MenuItem key={option.email} value={option.email}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </UiFrmControl>
                                            </React.Fragment>
                                        }

                                        {clienti && anagrafica?.ruolo?.toUpperCase() == "CLIENTE" &&
                                            <React.Fragment>
                                                <UiFrmControl>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={anagrafica?.catalogo_completo ?? false}
                                                                name={"catalogo_completo"}
                                                                onChange={handleChangeCheckbox}
                                                            />}
                                                        label={"catalogo dipendente completo"} />
                                                </UiFrmControl>
                                            </React.Fragment>
                                        }

                                        {HasGrant(root, RUOLO.ADMIN) && IsRuolo(anagrafica.ruolo, RUOLO.COGESTORE) && anagrafica.guid &&
                                            <UiFrmControl>
                                                <Autocomplete
                                                    disabled={!HasGrant(root, RUOLO.ADMIN)}
                                                    multiple
                                                    options={fornitori}
                                                    isOptionEqualToValue={(option, value) => option.email === value.email}
                                                    getOptionLabel={(option: any) => option.ragione_sociale as any}
                                                    value={fornitoreToCogestore}
                                                    onChange={(_, newValue) => {
                                                        setFornitoreToCogestore(newValue)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="fornitori"
                                                            placeholder="fornitori"
                                                        />
                                                    )}
                                                />
                                            </UiFrmControl>
                                        }

                                        <UiFrmControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={anagrafica?.rivenditore ?? false}
                                                        name={"rivenditore"}
                                                        onChange={handleChangeCheckbox}
                                                    />}
                                                label={"rivenditore"} />
                                        </UiFrmControl>

                                    </UiPaper>
                                }
                                <UiPaper className={"box box_padding box_mt"} title={"dati personali"}>
                                    <Box sx={{}}>
                                        <TextField
                                            name="email"
                                            disabled={anagrafica.guid != null}
                                            fullWidth
                                            required
                                            label="email"
                                            type="email"
                                            inputProps={{ style: { fontWeight: 700 } }}
                                            defaultValue={anagrafica.email ?? ""}
                                            onBlur={handleChange}
                                            helperText={"la mail ti servirà come utenza di login e non potrà essere modificata"}
                                        />
                                    </Box>
                                    {!isDipendente() &&
                                        <React.Fragment>
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    name="ragione_sociale"
                                                    fullWidth
                                                    required
                                                    label="ragione sociale"
                                                    type="text"
                                                    defaultValue={anagrafica.ragione_sociale ?? ""}
                                                    onBlur={handleChange}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    name="piva"
                                                    fullWidth
                                                    label="partita iva"
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    type="text"
                                                    defaultValue={anagrafica.piva ?? ""}
                                                    onBlur={handleChange}
                                                />
                                            </Box>
                                            <Divider
                                                sx={{ mt: 3 }} />
                                            <Box sx={{ mt: 3 }}>
                                                <TextField
                                                    name="email_pec"
                                                    fullWidth
                                                    label="email pec"
                                                    type="email"
                                                    defaultValue={anagrafica.email_pec ?? ""}
                                                    onBlur={handleChange}
                                                />
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <TextField
                                                    name="sdi"
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 7,
                                                    }}
                                                    label="sdi"
                                                    type="text"
                                                    defaultValue={anagrafica.sdi ?? ""}
                                                    onBlur={handleChange}
                                                />
                                            </Box>
                                            <Divider sx={{ mt: 3 }} />
                                        </React.Fragment>
                                    }
                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            name="codice_fiscale"
                                            fullWidth
                                            required
                                            label="codice fiscale"
                                            inputProps={{
                                                maxLength: 16,
                                            }}
                                            type="text"
                                            defaultValue={anagrafica.codice_fiscale ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <TextField
                                            name="telefono"
                                            fullWidth
                                            label="telefono"
                                            type="tel"
                                            defaultValue={anagrafica.telefono ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <TextField
                                            name="nome"
                                            fullWidth
                                            required
                                            label="nome referente"
                                            type="text"
                                            defaultValue={anagrafica.nome ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <TextField
                                            name="cognome"
                                            fullWidth
                                            required
                                            label="cognome referente"
                                            type="text"
                                            defaultValue={anagrafica.cognome ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <TextField
                                            name="email_referente"
                                            fullWidth
                                            label="email referente"
                                            type="email"
                                            defaultValue={anagrafica.email_referente ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <TextField
                                            name="iban"
                                            fullWidth
                                            required={isFornitore()}
                                            inputProps={{
                                                maxLength: 34,
                                                minLength: 34,
                                            }}
                                            label="iban"
                                            defaultValue={anagrafica.iban ?? ""}
                                            onBlur={handleChange}
                                        >
                                        </TextField>
                                    </Box>
                                </UiPaper>
                                {consensi &&
                                    <UiPaper className={"box box_padding box_mt"} title={"privacy"}>
                                        <Stack sx={{ mb: 2 }} direction={"column"} spacing={2} justifyContent={"start"}
                                            alignItems={"start"}>
                                            {consensi.filter(x => x.obbligatorio == true).map((o, i) => {
                                                return (
                                                    <React.Fragment>
                                                        <Button size={"small"} color={"primary"} variant={"outlined"}
                                                            onClick={() => {
                                                                setDialogContent({ nome: o.nome, testo: o.testo });
                                                            }}>{o.nome}</Button>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Stack>
                                        {consensi.filter(x => x.obbligatorio == false).map((o, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Stack direction={"row"} spacing={2} justifyContent={"start"}
                                                        alignItems={"center"}>
                                                        <UiFrmControl first>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    checked={consensi.filter(x => x.nome == o.nome)[0].isok ?? false}
                                                                    name={o.nome}
                                                                    onChange={(e) => {
                                                                        let items = [...consensi];
                                                                        let index = items.indexOf(items.filter(x => x.nome == e.target.name)[0]);
                                                                        let item = { ...items[index] };
                                                                        item.isok = e.target.checked;
                                                                        items[index] = item;
                                                                        setConsensi(items);
                                                                    }} />}
                                                                label={o.nome} />
                                                        </UiFrmControl>
                                                        <Button size={"small"} color={"primary"} variant={"outlined"}
                                                            onClick={() => {
                                                                setDialogContent({ nome: o.nome, testo: o.testo });
                                                            }}>prendi
                                                            visione</Button>
                                                    </Stack>
                                                </React.Fragment>
                                            )
                                        })}
                                    </UiPaper>
                                }
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <UiPaper className={"box box_padding"}
                                    title={isDipendente() ? "domicilio" : "sede"}>
                                    <Box sx={{}}>
                                        <Stack spacing={2}
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="start">
                                            {nazioni &&
                                                <TextField
                                                    fullWidth={im}
                                                    sx={{
                                                        width: {
                                                            lg: 300
                                                        }
                                                    }}
                                                    name="nazione"
                                                    select
                                                    required={!isDipendente()}
                                                    disabled
                                                    defaultValue={"it"}
                                                    label="Nazione"
                                                    onBlur={handleChange}
                                                >
                                                    {nazioni.map((option) => (
                                                        <MenuItem key={option.code} value={option.code}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                            {prov &&
                                                <TextField
                                                    name="prov"
                                                    fullWidth={im}
                                                    sx={{
                                                        width: {
                                                            lg: 300
                                                        }
                                                    }}
                                                    select
                                                    required={!isDipendente()}
                                                    label="Provincia"
                                                    defaultValue={anagrafica.prov ?? ""}
                                                    onChange={async (e) => {
                                                        setComuni(await DbComune(e.target.value, dispatch))
                                                    }}
                                                    onBlur={handleChange}
                                                >
                                                    {prov.map((option) => (
                                                        <MenuItem key={option.sigla} value={option.sigla}>
                                                            {option.nome}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                        </Stack>
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <Stack spacing={2}
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="start">
                                            {comuni &&
                                                <TextField
                                                    name="comune"
                                                    fullWidth={im}
                                                    sx={{
                                                        width: {
                                                            lg: 300
                                                        }
                                                    }}
                                                    select
                                                    required={!isDipendente()}
                                                    label="Comune"
                                                    defaultValue={anagrafica.comune ?? ""}
                                                    onBlur={handleChange}
                                                    onChange={(event) => {
                                                        setAnagrafica({
                                                            ...anagrafica,
                                                            cap: comuni.filter(x => x.nome == event.target.value)[0].cap[0],
                                                        });
                                                    }}
                                                >
                                                    {comuni.map((option) => (
                                                        <MenuItem key={option.nome} value={option.nome}>
                                                            {option.nome}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            }
                                            <TextField
                                                name="cap"
                                                fullWidth={im}
                                                sx={{
                                                    width: {
                                                        lg: 78
                                                    }
                                                }}
                                                required={!isDipendente()}
                                                inputProps={{
                                                    maxLength: 5,
                                                }}
                                                label="Cap"
                                                value={anagrafica.cap ?? ""}
                                                onChange={handleChange}
                                            >
                                            </TextField>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <Stack spacing={2}
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="start">
                                            <TextField
                                                name="indirizzo"
                                                fullWidth
                                                required={!isDipendente()}
                                                autoComplete={"indirizzo"}
                                                label="indirizzo"
                                                type="text"
                                                defaultValue={anagrafica.indirizzo ?? ""}
                                                onBlur={handleChange}
                                            />
                                            <TextField
                                                name="civico"
                                                sx={{
                                                    width: {
                                                        lg: 300
                                                    }
                                                }}
                                                fullWidth
                                                required={!isDipendente()}
                                                autoComplete={"civico"}
                                                label="civico"
                                                type="text"
                                                defaultValue={anagrafica.civico ?? ""}
                                                onBlur={handleChange}
                                            />
                                        </Stack>
                                    </Box>
                                </UiPaper>
                                <UiPaper className={"box box_padding box_mt"} title={"sicurezza"}>
                                    <Box sx={{}}>
                                        <TextField
                                            name="password"
                                            fullWidth
                                            required
                                            autoComplete={"current-password"}
                                            label="password"
                                            type="password"
                                            defaultValue={anagrafica.password ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                </UiPaper>
                                <UiFormInfo className={"box box_padding box_mt"} item={anagrafica} />
                            </Grid>
                        </Grid>

                        <UiFabContainer>
                            {root.isLogged &&
                                <Fab color="default" size="small"
                                    onClick={async () => {
                                        await reload();
                                    }}>
                                    <RefreshIcon />
                                </Fab>
                            }
                            {root.isLogged &&
                                <Fab color="primary" size="small" onClick={async () => {
                                    if (!window.confirm("Confermi?"))
                                        return false;
                                    if (await DbAnagraficaDel(anagrafica, dispatch)) {
                                        dispatch(showInfo("Utente eliminato correttamente"));
                                        navigate(-1);
                                        return false;
                                    }
                                    return false;
                                }}>
                                    <DeleteIcon />
                                </Fab>
                            }
                            <Fab color="secondary"
                                onClick={async () => {
                                    if (ruolo != null)
                                        anagrafica.ruolo = ruolo;
                                    await register();
                                    return false;
                                }}>
                                <SaveIcon />
                            </Fab>
                        </UiFabContainer>

                    </Box>
                    }

                    <Dialog
                        fullScreen={im}
                        open={dialogContent != null}
                        onClose={() => {
                            setDialogContent(null);
                        }}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">{dialogContent?.nome}</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            <div
                                dangerouslySetInnerHTML={{ __html: dialogContent?.testo?.replaceAll("\n", "<BR>") }} />
                        </DialogContent>
                        <Stack sx={{ p: 4 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                            spacing={2}>
                            <Button variant={"outlined"} color={"secondary"} onClick={() => {
                                setDialogContent(null);
                            }}
                            >ho capito</Button>
                        </Stack>
                    </Dialog>

                </UiContainer>
            }
        </ React.Fragment>
    )
})