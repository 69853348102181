import {useNavigate} from "react-router-dom";
import {RootStateOrAny, useSelector} from "react-redux";

import React from "react";
import {BaseGrid} from "../../../components/BaseGrid";
import Api from "../../../Api";


export default function Banners(props) {

    const navigate = useNavigate();
    const root = useSelector((state: RootStateOrAny) => state);

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.Banner.all}
                onRowClick={(row) => {
                    navigate(`/banners/edit/${row.guid}/${row.nome}`);
                }}
                onNew={() => {
                    navigate(`/banners/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                    {
                        id: 'banner_in_evidenza',
                        label: 'in evidenza',
                        mobile: true,
                        check: true
                    },
                ]}
            />
        </React.Fragment>
    )
}