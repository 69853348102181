import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { BaseGrid } from '../BaseGrid';
import Api from "../../Api";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { Box, MenuItem } from "@mui/material";
import { HookIsMobile, UiFrmControl } from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import { DbComune } from "../../Db";
import { useDispatch } from "react-redux";

export default function StripeSpedizione(props: any) {

    const navigate = useNavigate();
    const [comuni, setComuni] = React.useState<any>(null);
    const nazioni = [{ code: "it", label: "Italia" }, { code: "ee", label: "Estero" }];
    const dispatch = useDispatch();
    const [prov, setProv] = React.useState<any>(null);

    const handleChange = (event: any) => {
        props.setData({
            ...props.data,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        (async () => {
            fetch('Json/prov.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (myJson) {
                    setProv((myJson))
                });
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (props.data?.prov)
                setComuni(await DbComune(props.data.prov, dispatch));
        })();
    }, [props.data])

    return (
        <React.Fragment>
            {props.data &&
                <Box>
                    <UiFrmControl>
                        <TextField
                            disabled={props.readOnly}
                            size={"medium"}
                            name="ragione_sociale"
                            fullWidth
                            label="ragione sociale"
                            type="text"
                            defaultValue={props.data?.ragione_sociale ?? ""}
                            onBlur={handleChange}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            disabled={props.readOnly}
                            size={"medium"}
                            name="nome_attivita"
                            fullWidth
                            label="attivita'"
                            type="text"
                            defaultValue={props.data?.nome_attivita ?? ""}
                            onBlur={handleChange}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            disabled={props.readOnly}
                            size={"medium"}
                            name="nome"
                            fullWidth
                            label="nome"
                            type="text"
                            defaultValue={props.data?.nome ?? ""}
                            onBlur={handleChange}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            disabled={props.readOnly}
                            size={"medium"}
                            name="cognome"
                            fullWidth
                            label="cognome"
                            type="text"
                            defaultValue={props.data?.cognome ?? ""}
                            onBlur={handleChange}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            size={"medium"}
                            fullWidth
                            disabled={props.readOnly}
                            defaultValue={props.data?.nazione ?? "it"}
                            name="nazione"
                            select
                            required
                            label="Nazione"
                            onChange={handleChange}
                        >
                            {nazioni.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </UiFrmControl>
                    {props.data?.nazione == "it" &&
                        <React.Fragment>
                            <UiFrmControl>
                                <TextField
                                    disabled={props.readOnly}
                                    size={"medium"}
                                    defaultValue={props.data?.prov ?? ""}
                                    name="prov"
                                    fullWidth
                                    select
                                    required
                                    label="Provincia"
                                    onChange={async (e) => {
                                        setComuni(await DbComune(e.target.value, dispatch))
                                    }}
                                    onBlur={handleChange}
                                >
                                    {prov != null && prov.map((option) => (
                                        <MenuItem key={option.sigla} value={option.sigla}>
                                            {option.nome}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    disabled={props.readOnly}
                                    size={"medium"}
                                    defaultValue={props.data?.comune ?? ""}
                                    name="comune"
                                    select
                                    fullWidth
                                    required
                                    label="Comune"
                                    onBlur={handleChange}
                                    onChange={(event) => {
                                        props.setData({
                                            ...props.data,
                                            cap: comuni.filter(x => x.nome == event.target.value)[0].cap[0],
                                        });
                                    }}
                                >
                                    {comuni == null &&
                                        <MenuItem />
                                    }
                                    {comuni != null && comuni.map((option) => (
                                        <MenuItem key={option.nome} value={option.nome}>
                                            {option.nome}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    disabled={props.readOnly}
                                    size={"medium"}
                                    required
                                    defaultValue={props.data?.cap ?? ""}
                                    name="cap"
                                    fullWidth
                                    value={props.data?.cap ?? ""}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                    label="Cap"
                                    onChange={handleChange}
                                >
                                </TextField>
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    size={"medium"}
                                    name="indirizzo"
                                    fullWidth
                                    autoComplete={"indirizzo"}
                                    label="indirizzo"
                                    type="text"
                                    defaultValue={props.data?.indirizzo ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                            <UiFrmControl>
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    size={"medium"}
                                    name="numero civico"
                                    fullWidth
                                    autoComplete={"civico"}
                                    label="numero civico"
                                    type="text"
                                    defaultValue={props.data?.civico ?? ""}
                                    onBlur={handleChange}
                                />
                            </UiFrmControl>
                        </React.Fragment>
                    }
                </Box>
            }
        </React.Fragment>
    )
}