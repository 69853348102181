import React from 'react';
import {useNavigate} from "react-router-dom";
import { BaseGrid } from '../../../../components/BaseGrid';
import Api from "../../../../Api";


export default function SottoCategorie(props) {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <BaseGrid
                getDataSourceAsync={Api.SottoCategoria.all}
                onRowClick={(row) => {
                    navigate(`/sottocategoria/edit/${row.guid}/${encodeURIComponent(row.nome)}`);
                }}
                onNew={() => {
                    navigate(`/sottocategoria/nuovo/inserisci`);
                }}
                headers={[
                    {
                        id: 'nome',
                        label: 'nome',
                        chip: true,
                        primary: true,
                        mobile: true,
                    },
                    {
                        id: 'categoria',
                        label: 'menù',
                    },
                    {
                        id: 'posizione',
                        label: 'posizione',
                    },
                ]}
            />
        </React.Fragment>)
}