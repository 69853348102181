import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { HookIsMobile } from "../utils/Helpers";
import { Box, Button } from "@mui/material";
import { setDorsale } from "../reducers";
import Api from "../Api";

export default function OpenInBrowser(props: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const im = HookIsMobile();
    const [token, settoken] = React.useState<any>(null);
    const [dorsale, setdorsale] = React.useState<any>(null);
    useEffect(() => {
        (async () => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            settoken(params.token);
            setdorsale(await Api.Dorsale.main(null, dispatch));
        })();
    }, [])
    return (
        <React.Fragment>
            <Box {...props} sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 9999999999,
                backgroundColor: "white",
                display: "flex",
            }}>
                <Box sx={{
                    display: "table",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100vw",
                }}>
                    <Box sx={{ textAlign: "center" }}>
                        <Box>
                            <img className={"responsive"} src={"/db/dorsale/logoimage2"} />
                        </Box>
                        <Box sx={{
                            mt: 2,
                            fontSize: 28,
                            opacity: .7,
                            fontWeight: 600,
                            textAlign: "center",
                            width: "100%",
                            lineHeight: 1
                        }}>
                            ti da il benvenuto nella sua
                        </Box>
                        <Box sx={{
                            lineHeight: 1,
                            mt: 2,
                            fontSize: 28,
                            opacity: .7,
                            fontWeight: 600,
                            textAlign: "center",
                            /*   width: "100vw",*/
                        }}>
                            dorsale digitale
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <img className={"responsive"} src={"/db/dorsale/logoimage"} />
                        </Box>
                        <Button sx={{ mt: 3 }} variant={"contained"} onClick={() => {
                            window.open(`/api/autologin_final?token=${token}`, "_blank");
                        }}>entra in {dorsale?.nome}</Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}
