import React, {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {Box, FormControlLabel, MenuItem} from "@mui/material";
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { currencyFormat, downloadMedia, formatNumOrdine } from "../../utils/Functions";
import {useNavigate, useParams} from "react-router-dom";
import {
    UiPaper,
    HookIsMobile,
    PageHeader,
    UiFrmControl,
    UiFabContainer,
    UiSkeletonForm,
    UiContainer, UiFormInfo
} from "../../utils/Helpers";
import {
    DbCategoria,
    DbProdottotosottocategoria
} from "../../Db";
import RefreshIcon from '@mui/icons-material/Refresh';
import DatePicker from "@mui/lab/DatePicker";
import Api from "../../Api";
import {getLabel} from "../../utils/Functions";
import Checkbox from "@mui/material/Checkbox";

export default React.memo(function OrdineEdit() {
    let { guid } = useParams<any>();
    const [item, setItem] = React.useState<any>(null);
    const [categorie, setCategorie] = React.useState<any>(null);
    const [categoria, setCategoria] = React.useState<any>(null);
    const [sottoCategorie, setSottoCategorie] = React.useState<any>(null);
    const [sottoCategorieFlat, setSottoCategorieFlat] = React.useState<any>(null);
    const [prodottoSottoCategorie, setProdottoSottoCategorie] = React.useState<any>([]);
    const root = useSelector((state: RootStateOrAny) => state);
    const dispatch = useDispatch();
    const im = HookIsMobile();
    const navigate = useNavigate();
    const handleChange = (event: any) => {
        setItem({
            ...item,
            [event.target.name]: event.target.value,
        });
    };
    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItem({
            ...item,
            [event.target.name]: event.target.checked,
        });
    };
    async function reload() {
        setCategorie((await DbCategoria(null, dispatch))?.data);
        setSottoCategorie((await Api.SottoCategoria.all(null, dispatch))?.data);
        if (guid)
            setItem(await Api.Ordine.all({ guid: guid }, dispatch));
        else
            setItem({});
        setProdottoSottoCategorie(await DbProdottotosottocategoria(guid, dispatch));
    }

    useEffect(() => {
        (async () => {
            await reload();
        })();
    }, [])
    useEffect(() => {
        if (item) {
            if (sottoCategorie)
                setCategoria(sottoCategorie.filter(x => x.nome == item.sottocategoria)[0]?.nome_categoria);
        }
    }, [item])
    useEffect(() => {
        if (prodottoSottoCategorie && sottoCategorie && prodottoSottoCategorie.length > 0) {
            setCategoria(sottoCategorie.filter(x => x.nome == prodottoSottoCategorie[0])[0]?.nome_categoria);
        }
    }, [prodottoSottoCategorie])
    useEffect(() => {
        if (sottoCategorie) {
            setSottoCategorieFlat(sottoCategorie.map(function (item) {
                return item.nome;
            }));
        }
    }, [sottoCategorie])
    useEffect(() => {
        if (sottoCategorie && categoria)
            setSottoCategorieFlat(sottoCategorie.filter(x => x.nome_categoria == categoria).map(function (item) {
                return item.nome;
            }));
    }, [sottoCategorie, categoria])
    return (
        <React.Fragment>
            <UiContainer>
                <PageHeader title={item?.prodotto_nome} />
                {item &&
                    // @ts-ignore
                    <fieldset className={"noBorder"} disabled="disabled">
                        <Box component="form"
                            id={"myForm"}
                            autoComplete="off">
                            <Box className={"nopadding nomargin"}>
                                <UiFrmControl first>
                                    <TextField
                                        disabled
                                        name="guid"
                                        label="guid"
                                        inputProps={{ style: { fontWeight: 700 } }}
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.guid ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        sx={{ width: !im ? "100%" : "100%" }}
                                        select
                                        label="tipologia"
                                        name="tipologia"
                                        SelectProps={{ style: { fontWeight: 700 } }}
                                        value={item?.tipologia ?? ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"PRODOTTO"}>Prodotto</MenuItem>
                                        <MenuItem value={"SERVIZIO"}>Servizio</MenuItem>
                                        <MenuItem value={"LOGISTICA"}>Logistica</MenuItem>
                                    </TextField>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="prodotto_nome"
                                        label="prodotto nome"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.prodotto_nome ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <DatePicker
                                        label="data"
                                        value={item?.data ?? new Date()}
                                        onChange={(newValue) => {
                                            setItem({
                                                ...item,
                                                data: newValue,
                                            });
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="fornitore_ragione_sociale"
                                        label="fornitore_ragione_sociale"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.fornitore_ragione_sociale ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="prodotto_sku"
                                        label="prodotto_sku"
                                        fullWidth
                                        type="text"
                                        defaultValue={item?.prodotto_sku ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <Stack spacing={2} direction={"row"}>
                                        <TextField
                                            name="prodotto_categoria"
                                            label={getLabel("categoria", root)}
                                            fullWidth
                                            type="text"
                                            defaultValue={item?.prodotto_categoria ?? ""}
                                            onBlur={handleChange}
                                        />
                                        <TextField
                                            name="prodotto_sottocategoria"
                                            label={getLabel("sottocategoria", root)}
                                            fullWidth
                                            type="text"
                                            defaultValue={item?.prodotto_sottocategoria ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Stack>
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="prezzo_unitario"
                                        label="prezzo unitario"
                                        fullWidth
                                        type="text"
                                        defaultValue={currencyFormat(item?.prezzo_unitario ?? "")}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="offerta"
                                        label="offerta"
                                        fullWidth
                                        type="text"
                                        defaultValue={currencyFormat(item?.offerta ?? "")}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <TextField
                                        name="quantita"
                                        label="quantita"
                                        fullWidth
                                        type="number"
                                        defaultValue={item?.quantita ?? ""}
                                        onBlur={handleChange}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={item?.pagato ?? false}
                                                name={"pagato"}
                                                onChange={handleChangeCheckbox}
                                            />}
                                        label={"pagato"} />
                                </UiFrmControl>
                            </Box>

                            <Box className={"mt"}>
                                <UiFormInfo item={item} />
                            </Box>

                            <UiFabContainer>
                                <Fab color="default" size="small"
                                    onClick={async () => {
                                        await reload();
                                    }}>
                                    <RefreshIcon />
                                </Fab>
                                <input id={"submitRef"} style={{ display: "none" }} type="submit" />
                            </UiFabContainer>

                        </Box>
                    </fieldset>
                }

                {!item && <UiSkeletonForm></UiSkeletonForm>}

            </UiContainer>
        </ React.Fragment>
    );
})