import React, { useState } from 'react';
import Api from "../../Api";
import { useDispatch } from "react-redux";
import { UiFrmControl } from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        color: 'red',
    },
}));

function RequestChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();
    const classes = useStyles();

    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleToggleOldPassword = () => {
        setShowOldPassword((prevShowOldPassword) => !prevShowOldPassword);
    };

    const handleToggleNewPassword = () => {
        setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Le nuove password non corrispondono.');
        } else if (oldPassword === newPassword) {
            setMessage('La nuova password deve essere diversa da quella precedente.');
        } else {
            const entity = {
                oldPassword,
                newPassword,
            };

            try {
                const response = await Api.Dorsale.changepassword(entity, dispatch);

                if (response.success) {
                    setMessage('');
                    // La password è stata cambiata con successo
                    alert('Password cambiata con successo');
                    window.location.href = '/dashboard';
                } else {
                    // Gestisci altri codici di stato HTTP come necessario
                  
                    setMessage('La vecchia password è errata');
                }
            } catch (error) {
               
                    setMessage('La vecchia password è errata');
               
            }
        }
    };


    return (
        <React.Fragment>
            <Box>
                <h2>Recupero Password</h2>
                <form onSubmit={handleSubmit}>
                    <UiFrmControl>
                        <TextField
                            defaultValue={""}
                            name="oldPassword"
                            id="oldPassword"
                            fullWidth
                            required={true}
                            label="Vecchia Password"
                            type={showOldPassword ? 'text' : 'password'}
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleOldPassword}>
                                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            defaultValue={""}
                            name="newPassword"
                            id="newPassword"
                            fullWidth
                            required={true}
                            label="Nuova Password"
                            type={showNewPassword ? 'text' : 'password'}
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleNewPassword}>
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </UiFrmControl>
                    <UiFrmControl>
                        <TextField
                            defaultValue={""}
                            name="confirmPassword"
                            id="confirmPassword"
                            fullWidth
                            required={true}
                            label="Conferma Nuova Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleConfirmPassword}>
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </UiFrmControl>
                    <Box sx={{ display: 'flex', flexDirection: 'row', py: 3 }}>
                        <Button type="submit" variant="contained" color="secondary">
                            Cambia Password
                        </Button>
                    </Box>
                    <p className={classes.errorMessage}>{message}</p>
                </form>
            </Box>
        </React.Fragment>
    );
}

export default RequestChangePassword;
