import { Box, Grid, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../Api";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CartProdottiAcquista from "../catalogo/CartProdottiAcquista";
import BaseProdottoOrizzontale from "../../components/BaseProdottoOrizzontale";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { TextNormal } from "../../utils/Text";
import { HookIsMedium, HookIsMobile } from "../../utils/Helpers";
import { getNewImmagine } from "../../utils/Functions";

export default function VetrinaDettaglio() {
    const [guidParametro, setGuidParametro] = useSearchParams();
    const root = useSelector((state: RootStateOrAny) => state);

    const dispatch = useDispatch();
    const [prodotti, setProdotti] = useState<any>([]);
    const [numeroProdotti, setNumeroProdotti] = useState<number>(0);
    const [fornitore, setFornitore] = useState<any>();
    const [prodotto, setProdotto] = useState<any>(null);
    const [schedaProdotto, setSchedaProdotto] = useState<any>(null);
    const [carouselCounter, setCarouselCounter] = useState<any>(0);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const g = guidParametro.get("guid");
    const im = HookIsMobile();
    const imed = HookIsMedium();
    const [immaginiFornitore, setImmaginiFornitore] = useState<any>();
    const [erroreVetrina, setErroreVetrina] = useState<string>("");


    useEffect(() => {
        (async () => {
            try {
                const fornitoreData = await Api.Anagrafica.vetrina({ guid: g }, dispatch);
                setFornitore(fornitoreData.Result.data[0]);
                const appoggioImmagini: any[] = [];

                if (fornitoreData?.Result.data[0].AnagraficaToMedia?.length > 0) {
                    for (const anagrafica_to_media of fornitoreData?.Result.data[0].AnagraficaToMedia) {
                        let immagine = getNewImmagine(anagrafica_to_media.guid_media);
                        appoggioImmagini.push(immagine);
                    }

                    setImmaginiFornitore(appoggioImmagini);
                } else {
                    setImmaginiFornitore([
                        "https://biellaup.it/static-content/images/distretto_commercio_logo.png",
                    ]);
                }

                const prodottiData = await Api.Prodotto.allVetrina(
                    {
                        nome_fornitore:
                            fornitoreData?.Result.data[0].nome_attivita || fornitoreData?.Result.data[0].ragione_sociale,
                    },
                    dispatch
                );
                setProdotti(prodottiData);
                setNumeroProdotti(prodottiData.length);
                setIsLoaded(true);

            } catch (error) {
                console.error("Vetrina non disponibile.", error);
                setErroreVetrina("Vetrina non disponibile.\nContattare l'assistenza.");
                setIsLoaded(true);
            }
        })();
    }, [g, dispatch]);

    const previousImage = () => {
        if (carouselCounter === 0) {
        } else {
            setCarouselCounter(carouselCounter - 1);
        }
    };

    const nextImage = () => {
        if (immaginiFornitore.length === carouselCounter + 1) {
        } else {
            setCarouselCounter(carouselCounter + 1);
        }
    };

    return (
        <>
            {isLoaded ? (
                <>
                    <Box sx={{ display: "flex", justifyContent: "center" }} padding={"20px 20px"}>
                        {erroreVetrina !== "" ? (
                            <div style={{ color: "red" }}>{erroreVetrina}</div>
                        ) : (
                            <Stack
                                flexDirection={"column"}
                                padding={im ? 0 : "8px"}
                                width={im ? window.innerWidth : "100%"}>
                                <Box padding={im ? "8px" : 0}>
                                    <Box sx={{ color: `${root.GlobalTheme.color_secondary} !important` }}>
                                        <h2 style={{ margin: 0 }}>{fornitore?.nome_attivita ?? fornitore?.ragione_sociale}</h2>
                                    </Box>
                                    <Box sx={{ fontSize: "14px" }}>
                                        {fornitore?.indirizzo}, {fornitore?.civico}
                                    </Box>
                                </Box>
                                <Stack
                                    margin={im ? "0px" : "5px"}
                                    spacing={1}
                                    direction={imed ? "column" : "row"}
                                    justifyContent={"space-around"}
                                    alignItems={"center"}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            display: "flex",
                                            minWidth: im ? "100%" : "60%",
                                            justifyContent: "center",
                                            margin: im ? "20px 0px" : "0px",
                                            objectFit: "contain",
                                        }}>
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                borderRadius: 0,
                                                display: immaginiFornitore.length === 1 || carouselCounter === 0 ? "none" : "inherit",
                                            }}
                                            onClick={previousImage}>
                                            <ChevronLeft />
                                        </IconButton>
                                        <>
                                            <img
                                                style={{
                                                    maxWidth: "inherit",
                                                    height: im ? "300px" : "350px",
                                                    borderRadius: "16px",
                                                    // minHeight: "300px",
                                                    // maxHeight: "380px",
                                                    // width: window.innerWidth,
                                                }}
                                                src={immaginiFornitore[carouselCounter]}
                                                alt='Foto Fornitore'
                                            />
                                        </>
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                bottom: 0,
                                                right: 0,
                                                borderRadius: 0,
                                                display:
                                                    immaginiFornitore.length === 1 || carouselCounter === immaginiFornitore.length - 1
                                                        ? "none"
                                                        : "inherit",
                                            }}
                                            onClick={nextImage}>
                                            <ChevronRight />
                                        </IconButton>
                                    </Box>
                                    <Box padding={im ? "8px" : 0}>
                                        <h3 style={{ marginBottom: "15px", color: root.GlobalTheme.color_secondary }}>
                                            Descrizione
                                        </h3>
                                        {fornitore?.descrizione || "Questo fornitore si affida a Dorsale!"}
                                    </Box>
                                </Stack>
                                <br />
                                <Stack
                                    margin={im ? "0px" : "5px"}
                                    spacing={1}
                                    justifyContent={"space-around"}>
                                    <Box padding={im ? "8px" : 0} >
                                        <h3 style={{ marginBottom: "15px", color: root.GlobalTheme.color_secondary }}>Prodotti</h3>
                                    </Box>
                                    <Grid container spacing={2}>
                                        {prodotti &&
                                            prodotti.data &&
                                            prodotti.data.length > 0 &&
                                            isLoaded &&
                                            prodotti.data.map((prodotto, index) => (
                                                <Grid item xs={6} key={index}>
                                                    <BaseProdottoOrizzontale
                                                        numeroProdotti={numeroProdotti}
                                                        prodotto={prodotto}
                                                        pIndex={index}
                                                        onAddToCart={() => setProdotto(prodotto)}
                                                        onZoom={() => setSchedaProdotto(prodotto)}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                    {prodotti && !prodotti.data && isLoaded && (
                                        <Box sx={{ margin: "15px 0px" }}>
                                            <TextNormal>Il fornitore non ha nessun prodotto</TextNormal>
                                        </Box>
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Box>
                    <CartProdottiAcquista
                        prodotto={prodotto}
                        schedaProdotto={schedaProdotto}
                        onClose={() => {
                            setProdotto(null);
                            setSchedaProdotto(null);
                        }}
                    />
                </>
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                    Caricamento in corso...
                </Box>
            )}
        </>
    );
}
