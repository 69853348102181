import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Api from "../Api";
import CartProdottiAcquista from "../pages/catalogo/CartProdottiAcquista";
import { HookIsMobile } from "../utils/Helpers";
import { TextNormal } from "../utils/Text";
import BaseProdottoOrizzontale from "./BaseProdottoOrizzontale";

export function SearchAll() {
    const [prodotti, setProdotti] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState<any>("");
    const [prodotto, setProdotto] = React.useState<any>(null);
    const [schedaProdotto, setSchedaProdotto] = React.useState<any>(null);
    HookIsMobile();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<any | HTMLElement>(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const delayedSearch = useRef(
        debounce(async (text) => {
            if (text !== "" && text != null) {
                const res = await Api.Search.all({ text }, dispatch);
                setProdotti(res.Result ?? []);
                setAnchorEl(document.getElementById("globalSearchTextField"));
            }
        }, 300)
    );

    useEffect(() => {
        delayedSearch.current(searchText);
    }, [searchText, dispatch]);

    return (
        <React.Fragment>
            <TextField
                size={"small"}
                sx={{ width: 300 }}
                aria-label="globalSearchTextField"
                id="globalSearchTextField"
                type="text"
                placeholder={"Cerca prodotti o servizi"}
                InputProps={{
                    className: "small_input",
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={handleSearchTextChange}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                variant={"menu"}
                disableAutoFocus={true}
                sx={{ ml: prodotti.data === undefined ? -1: 0, maxWidth: prodotti.data ===undefined ? 330: 520, maxHeight: 460 }}
            >
                <Stack direction={"column"}
                    alignItems={"stretch"}
                    justifyContent={"stretch"}
                    alignContent={"stretch"}
                    sx={{ width: "400px", padding: "8px" }}
                    spacing={1}>
                    {prodotti && prodotti.data === undefined && (
                        <TextNormal width>Nessun risultato</TextNormal>
                    )}
                    {prodotti &&
                        prodotti.data &&
                        prodotti.data.length > 0 &&
                        prodotti.data.map((p: any, pIndex: number) => {
                            return (
                                <BaseProdottoOrizzontale
                                    key={pIndex}
                                    pIndex={pIndex}
                                    onZoom={(p: any) => {
                                        setSchedaProdotto(p);
                                    }}
                                    onAddToCart={(p: any) => {
                                        setProdotto(p);
                                    }}
                                    numeroProdotti={prodotti.length}
                                    prodotto={p}
                                />
                                /* <div style={{display: "table-row"}}>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         <Avatar variant="square"
                                                 sx={{width: 40, height: 40}}
                                                 src={`/db/media/get?guid=${r.guid_immagine}`}/>
                                     </div>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         {r.type}
                                     </div>
                                     <div style={{display: "table-cell", whiteSpace: "nowrap"}}>
                                         {r.label}
                                     </div>
                                 </div>*/
                            );
                        })}
                </Stack>
            </Menu>
            <CartProdottiAcquista
                prodotto={prodotto}
                schedaProdotto={schedaProdotto}
                onClose={() => {
                    setProdotto(null);
                    setSchedaProdotto(null);
                }}
            />
        </React.Fragment>
    );
}
