import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import GoogleFontLoader from "react-google-font-loader";
import { Style } from "react-style-tag";
import { HookIsMobile } from "../utils/Helpers";

export default React.memo(function App_Styles() {

    const root = useSelector((state: RootStateOrAny) => state);
    const im = HookIsMobile();

    return (
        <React.Fragment>
            <GoogleFontLoader
                fonts={[
                    {
                        font: root.GlobalTheme.google_font,
                        weights: [300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],
                    },
                ]}
            />
            <Style>{`* { font-family: ${root.GlobalTheme.font_family ?? root.GlobalTheme.google_font} !important; }`}</Style>
            <Style>{`body, html, #root {  background-color: ${root.GlobalTheme.main_bgcolor} !important; }`}</Style>
            <Style>{`.myDrawer, .myDrawer > .MuiPaper-root, .myDrawer > .MuiList-root {  background-color: ${root.GlobalTheme.menu_bgcolor} !important; }`}</Style>
            <Style>{`.SubMenu {  background-color: ${root.GlobalTheme.submenu_bgcolor} !important; }`}</Style>

            {root.GlobalTheme.text_dsc_color &&
                <Style>{`.TextDescription { color: ${root.GlobalTheme.text_dsc_color} !important; }`}</Style>
            }
            {root.GlobalTheme.text_normal_color &&
                <Style>{`.TextNormal { color: ${root.GlobalTheme.text_normal_color} !important; }`}</Style>
            }
            {root.GlobalTheme.text_title_color &&
                <Style>{`.TextTitle { color: ${root.GlobalTheme.text_title_color} !important; }`}</Style>
            }
            {root.GlobalTheme.text_title_small_color &&
                <Style>{`.TextTitleSmall { color: ${root.GlobalTheme.text_title_small_color} !important; }`}</Style>
            }
            {root.GlobalTheme.button_chip_color &&
                <Style>{`.MuiChip-colorSecondary > .MuiChip-label, .MuiButton-containedSecondary { color: ${root.GlobalTheme.button_chip_color} !important; }`}</Style>
            }
            {root.GlobalTheme.caption_color &&
                <Style>{`.MuiInputLabel-root { color: ${root.GlobalTheme.caption_color} !important; }`}</Style>
            }
            {root.GlobalTheme.input_color &&
                <Style>{`input, select, .MuiTableCell-root { color: ${root.GlobalTheme.input_color} !important; }`}</Style>
            }
            {root.GlobalTheme.color_reverse == true &&
                <React.Fragment>
                    <Style>{`.Mui-checked {  color: ${root.GlobalTheme.color_secondary} !important; }`}</Style>
                    <Style>{`.MuiLink-root {  color: ${root.GlobalTheme.color_secondary} !important; }`}</Style>
                    <Style>{`.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline { border-color: ${root.GlobalTheme.color_secondary} }`}</Style>
                    <Style>{`.MuiInput-root:after { border-bottom-color: ${root.GlobalTheme.color_secondary} !important }`}</Style>
                </React.Fragment>
            }
            {im && root.Dorsale && root.Dorsale.layout == "WOLF" &&
                <React.Fragment>
                    <Style>{`.box_padding {padding: 0 !important} .box { box-shadow:none !important; -webkit-box-shadow: none !important }`}</Style>
                    <Style>{`.box_mt { margin-top: 1rem !important }`}</Style>
                    <Style>{`.box_mb { margin-bottom: 1rem !important }`}</Style>
                </ React.Fragment>
            }
            {!im && root.Dorsale && root.Dorsale.layout == "WOLF" &&
                <React.Fragment>
                    <Style>{`.MuiInputBase-multiline { background:none !important }`}</Style>
                </ React.Fragment>
            }
            {root.Dorsale && root.Dorsale.layout == "DEFAULT" &&
                <React.Fragment>
                    <Style>{`.box { background-color:white !important; box-shadow:none !important; -webkit-box-shadow: none !important; border-radius: 0 !important; }`}</Style>
                    <Style>{`.box_mt { margin-top: 0 !important }`}</Style>
                    <Style>{`.box_mb { margin-bottom: 0 !important }`}</Style>
                    {im &&
                        <Style>{`.box_padding {padding: 0 !important} `}</Style>
                    }
                </ React.Fragment>
            }
        </ React.Fragment>
    )
})