import { ChangeEvent, memo, useEffect, useState } from 'react';
import { setLoadingMessage, showError, showInfo } from "../../reducers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    FormControlLabel, InputAdornment,
    MenuItem,
    Tooltip, Modal
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { HookIsMobile, PageHeader, UiContainer, UiFabContainer, UiFormInfo, UiFrmControl, UiPaper, UiFileListUploader, UiFileUploader } from "../../utils/Helpers";
import { DbAnagraficaDel, DbComune, DbDorsale, DbFornitoreToCogestore, DbRuolo } from "../../Db";
import RefreshIcon from "@mui/icons-material/Refresh";
import Autocomplete from "@mui/material/Autocomplete";
import { getLabel, HasGrant, IsRuolo, RUOLO } from "../../utils/Functions";
import Api from "../../Api";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";



export default memo(function AnagraficaEditCliente() {
    let { guid } = useParams<any>();
    const [anagrafica, setAnagrafica] = useState<any>(null);
    const [dorsale, setDorsale] = useState<any>(null);
    const [comuni, setComuni] = useState<any>(null);
    const [prov, setProv] = useState<any>(null);
    const [ruoli, setRuoli] = useState<any>(null);
    const [settori, setSettori] = useState<any>(null);
    const [clienti, setClienti] = useState<any>(null);
    const [fornitori, setFornitori] = useState<any>([]);
    const [fornitoreToCogestore, setFornitoreToCogestore] = useState<any>([]);
    const [dialogContent, setDialogContent] = useState<any>(null);
    const [settoriSelected, setSettoriSelected] = useState<any>(null);
    const [scroll, setScroll] = useState<DialogProps['scroll']>('body');
    const [prodotti, setProdotti] = useState<any>([]);
    const [prodottiSelected, setProdottiSelected] = useState<any>([]);
    const [prodottiOpen, setProdottiOpen] = useState(false);
    const [prodottiLoading, setProdottiLoading] = useState(false);
    const [prodottiLabel, setProdottiLabel] = useState<any>(null);

    const [arrayImageToUpload, setArrayImageToUpload] = useState<any>([]);
    const [arrayImageToDelete, setArrayImageToDelete] = useState<any>([]);
    const [arrayDisplayImages, setArrayDisplayImages] = useState<any>([]);
    let [arrayDbImages, setArrayDbImages] = useState<any>([]);
    const [multiImgModalOpen, setMultiImgModalOpen] = useState<any>(false);
    const [file, setFile] = useState<any>(null);
    const [fileToDelete, setFileToDelete] = useState<any>(null);

    const [consensi, setConsensi] = useState<any>([]);
    const nazioni = [{ code: "it", label: "Italia" }, { code: "ee", label: "Estero" }];
    const root = useSelector((state: RootStateOrAny) => state);
    const im = HookIsMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();



    const [nazioneValue, setNazioneValue] = useState("it");
    let { ruolo } = useParams<any>();
    let loggedUserRole = root.LoginData?.ruolo;
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    useEffect(() => {
        (async () => {
            if (prodotti.length === 0 && prodottiOpen) {
                setProdottiLoading(true);
                setProdotti((await Api.Prodotto.allreduced(null, dispatch)).data);
                setProdottiLoading(false);
            }
        })();
    }, [prodottiOpen]);

    useEffect(() => {
        if (prodottiSelected) if (prodottiSelected.length > 0)
            setProdottiLabel("Prodotti selezionati");
        else
            setProdottiLabel("Tutti i prodotti sono associati");
    }, [prodottiSelected]);

    useEffect(() => {
        (async () => {
            await reload();
            setRuoli((await DbRuolo(dispatch)));
            if (root?.isLogged === true)
                setSettori((await Api.Settore.all(null, dispatch))?.data);
            setDorsale((await DbDorsale(null, dispatch)));
            getData();
        })();
    }, [])

    function isCittadino() {
        return (ruolo?.toUpperCase() === "CITTADINO" || anagrafica?.ruolo?.toUpperCase() === "CITTADINO");
    }

    function isFornitore() {
        return (ruolo?.toUpperCase() === "FORNITORE" || anagrafica?.ruolo?.toUpperCase() === "FORNITORE");
    }

    function isCliente() {
        return (ruolo?.toUpperCase() === "CLIENTE" || anagrafica?.ruolo?.toUpperCase() === "CLIENTE");
    }

    function hasRagioneSociale() {
        return anagrafica?.ragione_sociale !== undefined
    }

    function isDipendente() {
        return (ruolo?.toUpperCase() === "DIPENDENTE" || anagrafica?.ruolo?.toUpperCase() === "DIPENDENTE");
    }

    function displayRagioneSociale() {
        return (!isCittadino() && hasRagioneSociale()) || isFornitore() || isCliente();
    }
    async function register() {
        const required = true;
        const form = document.querySelector("form") as HTMLFormElement;
        const email = document.querySelector('input[name="email"]') as HTMLInputElement;
        const email_pec = document.querySelector('input[name="email_pec"]') as HTMLInputElement;
        const sdi = document.querySelector('input[name="sdi"]') as HTMLInputElement;
        const password = document.querySelector('input[name="password"]') as HTMLInputElement;
        let reportVal = true;
        if (form != null) {
            if (anagrafica.guid == null && email?.value != null && email?.value !== "") {
                const check = await Api.Anagrafica.checkemail({ email: email?.value }, dispatch);
                if (!check) {
                    email?.setCustomValidity("Indirizzo email già registrato, sceglierne un altro!");
                    form.reportValidity();
                    return false;
                } else {
                    email?.setCustomValidity("");
                    reportVal = form.reportValidity();
                }
            }
            /*            if (required && !(sdi == null) && email_pec.value === "" && sdi.value === "") {
                            sdi.setCustomValidity("specificare una mail pec valido o in alternativa il campo SDI");
                            form.reportValidity();
                            return false;
                        }*/
            sdi?.setCustomValidity("");
            if (required && password?.value != null && password?.value !== "") {
                const regex = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
                const result = regex.test(password?.value);
                if (!result) {
                    password?.setCustomValidity("La password deve essere di almeno 8 caratteri e contenere almeno un numero e un carattere speciale");
                    form.reportValidity();
                    return false;
                } else
                    password?.setCustomValidity("");
            }
            if (reportVal)
                reportVal = form.reportValidity();
        }
        if (!reportVal)
            return false;
        dispatch(setLoadingMessage("Salvataggio in corso..."))
        anagrafica.fornitoreToCogestore = fornitoreToCogestore;
        anagrafica.settori = settoriSelected;
        anagrafica.prodottiSelected = prodottiSelected;
        anagrafica.consensi = consensi;
        anagrafica.nazione = nazioneValue;

        try {

            const saveResultImage = await Api.Anagrafica.saveImage(anagrafica, [

                {
                    name: "fileAttachment",
                    value: file,
                    guid: file
                },
                {
                    name: "fileToDelete",
                    guid: fileToDelete
                },
                {
                    name: "arrayImageToUpload",
                    value: arrayImageToUpload,
                },
                {
                    name: "arrayImageToDelete",
                    value: arrayImageToDelete
                },

            ], dispatch);


            const saveResult = await Api.Anagrafica.save(anagrafica, dispatch);

            if (saveResult.success && saveResultImage.success) {
                if (anagrafica.guid == null)
                    dispatch(showInfo("Utente creato correttamente"));
                else
                    dispatch(showInfo("Utente modificato correttamente"));
            } else {
                // Qui puoi gestire l'errore come desiderato
                console.error('Errore durante il salvataggio:', saveResult.message);
                dispatch(setLoadingMessage(null));
            }
        } catch (error) {
            // Gestione degli errori, log, ecc.
            console.error('Errore durante la chiamata API:', error);

            dispatch(showError("Errore non si può rimuovere un settore assegnato da un amministratore"));
            //showError("Errore non si può rimuovere un settore assegnato da un amministratore");
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
        if ((location.pathname !== "/i-miei-dati") && root.isLogged)
            navigate(-1);
        else if (!root.isLogged) {
            navigate("/login");
        }
        dispatch(setLoadingMessage(null))
        return false;
    }

    const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        setAnagrafica({
            ...anagrafica,
            [event.target.name]: event.target.checked,
        });
    };

    const handleNazioni = (event: any) => {
        setNazioneValue(event.target.value);
    }
    const handleChange = (event: any) => {
        setAnagrafica({
            ...anagrafica,
            [event.target.name]: event.target.value,
        });
    };

    const validateDescrizione = (descrizione) => {
        if (anagrafica.descrizione !== undefined && descrizione.length > 500) {
            return 'La descrizione deve contenere al massimo 500 caratteri';
        }
        return '';
    }

    async function reload() {
        if (root.isLogged === true) {
            let dbAnagrafica: any;
            dispatch(setLoadingMessage("Caricamento in corso..."));
            if (location.pathname === "/i-miei-dati") {
                dbAnagrafica = (await Api.Anagrafica.all({ mine: true }, dispatch));
            }
            else {
                dbAnagrafica = (await Api.Anagrafica.all({ guid: guid }, dispatch));
            }
            dispatch(setLoadingMessage(null));
            setAnagrafica(dbAnagrafica);

            let allImagesFornitore: any;
            allImagesFornitore = await Api.Anagrafica.allimages({ email: dbAnagrafica.email }, dispatch);
            console.log(allImagesFornitore);
            if (allImagesFornitore != null) {

                var arrayImages: any[] = [];
                for (const anagraficaToMedia of allImagesFornitore) {
                    const media = await Api.Media.all({ guid: anagraficaToMedia }, dispatch);
                    arrayImages.push(media);
                    console.log(media);
                }
                setArrayDbImages(arrayImages);
                setArrayDisplayImages(arrayImages);
            }

            if (anagrafica?.ragione_sociale === undefined) {
                console.log("");
            }
            setConsensi(await Api.Privacy.getconsensi({ email: dbAnagrafica.email }, dispatch));

            setClienti((await Api.Anagrafica.allreduced({ ruolo: "CLIENTE" }, dispatch))?.data);
            setFornitori((await Api.Anagrafica.allreduced({ ruolo: "FORNITORE" }, dispatch))?.data);
            if (dbAnagrafica.prov)
                setComuni(await DbComune(dbAnagrafica.prov, dispatch));
            setFornitoreToCogestore((await DbFornitoreToCogestore(dbAnagrafica.email, dispatch)));
            setSettoriSelected((await Api.Settore.anagrafica({ email: dbAnagrafica.email }, dispatch)).data);
            setProdottiSelected((await Api.Anagrafica.prodotto({ email: dbAnagrafica.email }, dispatch)).data);
        } else {
            setAnagrafica({ ruolo: ruolo });
        }
    }

    const getData = () => {
        fetch('Json/prov.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setProv((myJson))
            });
    }

    return (
        <>
            {anagrafica &&
                <UiContainer className={"box box_padding box_mt"}>
                    {anagrafica?.guid && <PageHeader title={
                        location.pathname === "/i-miei-dati" ?
                            root.LoginData?.name :
                            anagrafica?.nome_attivita ??
                            anagrafica?.ragione_sociale ??
                            (anagrafica?.nome + " " + anagrafica?.cognome)}
                    />}
                    {!anagrafica?.guid && <PageHeader title={"Registrazione Nuovo Utente"} />}

                    {<Box component="form"
                        id={"myForm"}
                        autoComplete="off">

                        {HasGrant(root, RUOLO.ADMIN) &&
                            <UiPaper title={"utility"}>
                                <UiFrmControl first>
                                    <TextField
                                        disabled
                                        fullWidth
                                        label="Guid"
                                        type="text"
                                        defaultValue={anagrafica.guid ?? ""}
                                    />
                                </UiFrmControl>
                                <UiFrmControl>
                                    {ruoli &&
                                        <TextField
                                            name="ruolo"
                                            disabled
                                            fullWidth
                                            required
                                            value={ruolo?.toLowerCase() ?? anagrafica.ruolo?.toLowerCase() ?? ""}
                                            label="Ruolo"
                                        >
                                            {ruoli.map((option) => (
                                                <MenuItem key={option.nome} value={option.nome}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                </UiFrmControl>
                                {settori && anagrafica?.ruolo?.toUpperCase() === "CLIENTE" && location.pathname === "/i-miei-dati" &&
                                    <UiFrmControl>
                                        <Autocomplete
                                            multiple
                                            options={settori}
                                            isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                            getOptionLabel={(option: any) => option.nome}
                                            value={settoriSelected ?? []}
                                            onChange={(_, newValue) => {
                                                setSettoriSelected(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    name={"settori"}
                                                    label={getLabel("settori", root)}
                                                    placeholder="categorie"
                                                />
                                            )}
                                        />
                                    </UiFrmControl>
                                }
                                <UiFrmControl>
                                    <Autocomplete
                                        multiple
                                        options={prodotti ?? []}
                                        getOptionLabel={(option: any) => option?.nome as any}
                                        isOptionEqualToValue={(option, value) => option.sku === value.sku}
                                        onOpen={() => {
                                            setProdottiOpen(true);
                                        }}
                                        onClose={() => {
                                            setProdottiOpen(false);
                                        }}
                                        loading={prodottiLoading}
                                        value={prodottiSelected ?? []}
                                        onChange={(_, newValue) => {
                                            setProdottiSelected(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name={"prodotti"}
                                                label={prodottiLabel}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {prodottiLoading ?
                                                                <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </UiFrmControl>

                                {isDipendente() &&
                                    <>
                                        <UiFrmControl>
                                            <TextField
                                                name="email_cliente"
                                                select
                                                fullWidth
                                                defaultValue={anagrafica?.email_cliente ?? ""}
                                                label="Cliente"
                                                onBlur={handleChange}
                                            >
                                                {clienti.map((option) => (
                                                    <MenuItem key={option.email} value={option.email}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </UiFrmControl>
                                    </>
                                }


                                {anagrafica?.ruolo?.toUpperCase() === "CLIENTE" && hasRagioneSociale() &&
                                    <>
                                        <UiFrmControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={anagrafica?.catalogo_completo ?? false}
                                                        name={"catalogo_completo"}
                                                        onChange={handleChangeCheckbox}
                                                    />}
                                                label={"Catalogo cittadino completo"} />
                                        </UiFrmControl>
                                    </>
                                }

                                <UiFrmControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={anagrafica?.rivenditore ?? false}
                                                name={"rivenditore"}
                                                onChange={handleChangeCheckbox}
                                            />}
                                        label={"Rivenditore"} />
                                </UiFrmControl>

                            </UiPaper>
                        }

                        {HasGrant(root, RUOLO.ADMIN) && IsRuolo(anagrafica.ruolo, RUOLO.COGESTORE) && anagrafica.guid &&
                            <UiFrmControl>
                                <Autocomplete
                                    disabled={!HasGrant(root, RUOLO.ADMIN)}
                                    multiple
                                    options={fornitori}
                                    isOptionEqualToValue={(option, value) => option.email === value.email}
                                    getOptionLabel={(option: any) => option.ragione_sociale as any}
                                    value={fornitoreToCogestore}
                                    onChange={(_, newValue) => {
                                        setFornitoreToCogestore(newValue)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Fornitori"
                                            placeholder="Fornitori"
                                        />
                                    )}
                                />
                            </UiFrmControl>
                        }

                        <UiPaper title={"dati personali"}>
                            <Box sx={{}}>
                                <TextField
                                    name="email"
                                    disabled={anagrafica.guid != null}
                                    fullWidth
                                    required
                                    label="Email"
                                    type="email"
                                    inputProps={{ style: { fontWeight: 700 } }}
                                    defaultValue={anagrafica.email ?? ""}
                                    onBlur={handleChange}
                                    helperText={"La mail ti servirà come utenza di login e non potrà essere modificata"}
                                />
                            </Box>
                            {settori &&
                                <UiFrmControl>
                                    <Autocomplete
                                        multiple
                                        options={settori}
                                        isOptionEqualToValue={(option, value) => option.nome === value.nome}
                                        getOptionLabel={(option: any) => option.nome}
                                        value={settoriSelected ?? []}
                                        onChange={(_, newValue) => {
                                            setSettoriSelected(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                name={"settori"}
                                                label={getLabel("settori", root)}
                                                placeholder="Categorie"
                                            />
                                        )}
                                    />
                                </UiFrmControl>
                            }
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    name="notify"
                                    fullWidth
                                    required
                                    label="Notify"
                                    type="email"
                                    defaultValue={anagrafica?.notify ?? ""}
                                    onBlur={handleChange}
                                    helperText={"La mail a cui arriveranno le notifiche"}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    name="email_pec"
                                    fullWidth
                                    label="Email PEC"
                                    type="email"
                                    defaultValue={anagrafica.email_pec ?? ""}
                                    onBlur={handleChange}
                                />
                            </Box>

                            {displayRagioneSociale() &&
                                <>
                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            name="ragione_sociale"
                                            fullWidth
                                            label="Ragione sociale"
                                            type="text"
                                            defaultValue={anagrafica.ragione_sociale ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 4 }}>
                                        <TextField
                                            id="nome_attivita"
                                            name="nome_attivita"
                                            fullWidth
                                            label="Nome attività"
                                            type="text"
                                            defaultValue={anagrafica.nome_attivita ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            name="piva"
                                            fullWidth
                                            label="Partita IVA"
                                            inputProps={{
                                                maxLength: 11,
                                            }}
                                            type="text"
                                            defaultValue={anagrafica.piva ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Divider
                                        sx={{ mt: 3 }} />

                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            name="sdi"
                                            fullWidth
                                            inputProps={{
                                                maxLength: 7,
                                            }}
                                            label="SdI"
                                            type="text"
                                            defaultValue={anagrafica.sdi ?? ""}
                                            onBlur={handleChange}
                                        />
                                    </Box>
                                    <Divider sx={{ mt: 3 }} />
                                </>
                            }

                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    name="codice_fiscale"
                                    fullWidth
                                    required
                                    label="Codice fiscale"
                                    inputProps={{
                                        maxLength: 16,
                                    }}
                                    type="text"
                                    defaultValue={anagrafica.codice_fiscale ?? ""}
                                    onBlur={handleChange}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    name="telefono"
                                    fullWidth
                                    label="Telefono"
                                    type="tel"
                                    defaultValue={anagrafica.telefono ?? ""}
                                    onBlur={handleChange}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    name="nome"
                                    fullWidth
                                    required
                                    label="Nome"
                                    type="text"
                                    defaultValue={anagrafica.nome ?? ""}
                                    onBlur={handleChange}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    name="cognome"
                                    fullWidth
                                    required
                                    label="Cognome"
                                    type="text"
                                    defaultValue={anagrafica.cognome ?? ""}
                                    onBlur={handleChange}
                                />
                            </Box>
                            {isFornitore() &&
                                <Box sx={{ mt: 3 }}>
                                    <TextField
                                        name="email_referente"
                                        fullWidth
                                        label="Email referente"
                                        type="email"
                                        defaultValue={anagrafica.email_referente ?? ""}
                                        onBlur={handleChange}
                                    />
                                </Box>}
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    name="iban"
                                    fullWidth
                                    required={isFornitore()}
                                    inputProps={{
                                        maxLength: 34,
                                        minLength: 27,
                                    }}
                                    label="Iban"
                                    defaultValue={anagrafica.iban ?? ""}
                                    onBlur={handleChange}
                                >
                                </TextField>
                            </Box>
                        </UiPaper>
                        {isFornitore() &&
                            <UiPaper
                                title={"vetrina"}>
                                <UiFrmControl first>
                                    <FormControlLabel
                                        control={
                                            <Tooltip title="Il servizio della vetrina è stato acquistato dal fornitore">
                                                <Checkbox
                                                    checked={anagrafica.vetrina_abilitata ?? false}
                                                    name="checkboxVetrina"
                                                    onChange={(e) => {
                                                        setAnagrafica({ ...anagrafica, vetrina_abilitata: e.target.checked });
                                                    }}

                                                />
                                            </Tooltip>
                                        }
                                        label="Vetrina Abilitata"
                                    />
                                </UiFrmControl>
                                <Box sx={{ mt: 3 }}>
                                    <TextField
                                        name="descrizione"
                                        fullWidth
                                        label="Descrizione"
                                        type="text"
                                        defaultValue={anagrafica.descrizione ?? ""}
                                        onBlur={handleChange}
                                        multiline
                                        maxRows={10}
                                        error={validateDescrizione(anagrafica.descrizione) !== ''}
                                        helperText={validateDescrizione(anagrafica.descrizione)}
                                        disabled={location.pathname === "/i-miei-dati"}
                                    />
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <UiFrmControl first>
                                        <UiFileListUploader
                                            image={true}
                                            label="Carica Immagine Vetrina"
                                            media={arrayDisplayImages}
                                            onDelete={(e) => {
                                                if (location.pathname === "/i-miei-dati") {
                                                    alert("non sei abilitato per eliminare immagini per la vetrina, contatta l'amministratore")
                                                    window.location.reload()
                                                }
                                                let appoggio = [...arrayDisplayImages]
                                                appoggio = appoggio.filter((img) => e.filename !== img.filename)

                                                if (arrayDbImages.some((dbimg) => dbimg.filename === e.filename)) {
                                                    setArrayImageToDelete([...arrayImageToDelete, e])
                                                }
                                                setArrayDisplayImages([...appoggio])
                                                setArrayImageToUpload([...appoggio])
                                            }}
                                            onChange={(e) => {
                                                if (location.pathname === "/i-miei-dati") {
                                                    alert("non sei abilitato per caricare immagini per la vetrina, contatta l'amministratore")
                                                    window.location.reload()
                                                }
                                                if (arrayDisplayImages.find((image) => image.filename === e.filename)) {
                                                    setMultiImgModalOpen(true)
                                                } else {
                                                    setArrayDisplayImages([...arrayDisplayImages, e])
                                                    setArrayImageToUpload([...arrayImageToUpload, e]);
                                                }
                                            }} />
                                    </UiFrmControl>
                                </Box>
                            </UiPaper>
                        }

                        {consensi &&
                            <UiPaper title={"privacy"}>
                                <Stack sx={{ mb: 2 }} direction={"column"} spacing={2} justifyContent={"start"}
                                    alignItems={"start"}>
                                    {consensi.filter(x => x.obbligatorio === true).map((o, i) => {
                                        return (
                                            <>
                                                <Button size={"small"} color={"primary"} variant={"outlined"}
                                                    onClick={() => {
                                                        setDialogContent({ nome: o.nome, testo: o.testo });
                                                    }}>{o.nome}</Button>
                                            </>
                                        )
                                    })}
                                </Stack>
                                <Stack direction={"row"} spacing={2} justifyContent={"start"}
                                    alignItems={"center"}>

                                    <UiFrmControl first>
                                        <FormControlLabel
                                            control={
                                                <Tooltip title="Letta l'informativa privacy di questo sito, presto il mio consenso per l'invio di comunicazioni 
promozionali (compresa la newsletter) da parte del sito a mezzo mail e riferito a prodotti propri">
                                                    <Checkbox
                                                        checked={anagrafica.consensoMarketing ?? false}
                                                        name="checkbox1"
                                                        onChange={(e) => {
                                                            setAnagrafica({ ...anagrafica, consensoMarketing: e.target.checked });
                                                        }}

                                                    />
                                                </Tooltip>
                                            }
                                            label="Marketing Profilato"
                                        />
                                    </UiFrmControl>

                                    <UiFrmControl first>
                                        <FormControlLabel
                                            control={
                                                <Tooltip title="Letta l'informativa privacy di questo sito, presto il mio consenso per l'analisi delle 
mie scelte di acquisto su questo sito e'finalizzata all'invio di comunicazioni promozionali da parte di terzi soggetti a mezzo mail
e riferite a prodotti propri di mio specifico interesse">
                                                    <Checkbox
                                                        checked={anagrafica.consensoMarketingDaTerzi ?? false}
                                                        name="checkbox2"
                                                        onChange={(e) => {
                                                            setAnagrafica({ ...anagrafica, consensoMarketingDaTerzi: e.target.checked });
                                                        }}

                                                    />
                                                </Tooltip>
                                            }
                                            label="Marketing Profilato Da Terzi"
                                        />
                                    </UiFrmControl>

                                </Stack>

                            </UiPaper>
                        }

                        <UiPaper
                            title={(isCittadino() || !hasRagioneSociale()) ? "domicilio" : "sede"}>
                            <Box sx={{}}>
                                <Stack spacing={2}
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start">
                                    {nazioni &&
                                        <TextField
                                            fullWidth={im}
                                            sx={{
                                                width: {
                                                    lg: 300
                                                }
                                            }}
                                            name="nazione"
                                            select
                                            required={!isCittadino()}
                                            disabled
                                            defaultValue={"it"}
                                            label="Nazione"
                                            onBlur={handleNazioni}
                                        >
                                            {nazioni.map((option) => (
                                                <MenuItem key={option.code} value={option.code}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                    {prov &&
                                        <TextField
                                            name="prov"
                                            fullWidth={im}
                                            sx={{
                                                width: {
                                                    lg: 300
                                                }
                                            }}
                                            select
                                            required={!isCittadino()}
                                            label="Provincia"
                                            defaultValue={anagrafica.prov ?? ""}
                                            onChange={async (e) => {
                                                setComuni(await DbComune(e.target.value, dispatch))
                                            }}
                                            onBlur={handleChange}
                                        >
                                            {prov.map((option) => (
                                                <MenuItem key={option.sigla} value={option.sigla}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                </Stack>
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Stack spacing={2}
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start">
                                    {comuni &&
                                        <TextField
                                            name="comune"
                                            fullWidth={im}
                                            sx={{
                                                width: {
                                                    lg: 300
                                                }
                                            }}
                                            select
                                            required={!isCittadino()}
                                            label="Comune"
                                            defaultValue={anagrafica.comune ?? ""}
                                            onBlur={handleChange}
                                            onChange={(event) => {
                                                setAnagrafica({
                                                    ...anagrafica,
                                                    cap: comuni.filter(x => x.nome === event.target.value)[0].cap[0],
                                                });
                                            }}
                                        >
                                            {comuni.map((option) => (
                                                <MenuItem key={option.nome} value={option.nome}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                    <TextField
                                        name="cap"
                                        fullWidth={im}
                                        sx={{
                                            width: {
                                                lg: 78
                                            }
                                        }}
                                        required={!isCittadino()}
                                        inputProps={{
                                            maxLength: 5,
                                        }}
                                        label="CAP"
                                        value={anagrafica.cap ?? ""}
                                        onChange={handleChange}
                                    >
                                    </TextField>
                                </Stack>
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Stack spacing={2}
                                    direction="row"
                                    justifyContent="start"
                                    alignItems="start">
                                    <TextField
                                        name="indirizzo"
                                        fullWidth
                                        required={!isCittadino()}
                                        autoComplete={"indirizzo"}
                                        label="Indirizzo"
                                        type="text"
                                        defaultValue={anagrafica.indirizzo ?? ""}
                                        onBlur={handleChange}
                                    />
                                    <TextField
                                        name="civico"
                                        sx={{
                                            width: {
                                                lg: 300
                                            }
                                        }}
                                        fullWidth
                                        required={!isCittadino()}
                                        autoComplete={"civico"}
                                        label="N° Civico"
                                        type="text"
                                        defaultValue={anagrafica.civico ?? ""}
                                        onBlur={handleChange}
                                    />
                                </Stack>
                            </Box>

                        </UiPaper>
                        {location.pathname === "/i-miei-dati" &&
                            < UiPaper title={"sicurezza"}>
                                <Box sx={{ maxWidth: 400, mt: 4 }}>
                                    <Link to="/requestchangepassword">
                                        <Button variant="contained" color="secondary">
                                            Cambia Password
                                        </Button>
                                    </Link>
                                </Box>
                            </UiPaper>
                        }
                        {!anagrafica.guid &&
                            <UiPaper title={"sicurezza"}>
                                <Box sx={{}}>

                                    <TextField
                                        name="password"
                                        fullWidth
                                        required
                                        autoComplete={"current-password"}
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        defaultValue={anagrafica.password ?? ""}
                                        onBlur={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePassword}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ width: '90%' }}
                                    />
                                </Box>

                            </UiPaper>
                        }

                        <UiFormInfo item={anagrafica} />

                        <UiFabContainer>
                            {root.isLogged &&
                                <Fab color="default" size="small"
                                    onClick={async () => {
                                        await reload();
                                    }}>
                                    <RefreshIcon />
                                </Fab>
                            }
                            {root.isLogged &&
                                <Fab color="primary" size="small" onClick={async () => {
                                    if (!window.confirm("Confermi?"))
                                        return false;
                                    if (await DbAnagraficaDel(anagrafica, dispatch)) {
                                        dispatch(showInfo("Utente eliminato correttamente"));
                                        navigate(-1);
                                        return false;
                                    }
                                    return false;
                                }}>
                                    <DeleteIcon />
                                </Fab>
                            }
                            <Fab
                                color="secondary"
                                onClick={async () => {
                                    if (ruolo != null) anagrafica.ruolo = ruolo;

                                    if (anagrafica.descrizione !== undefined && anagrafica.descrizione.length > 500) {

                                        alert('La descrizione deve contenere al massimo 500 caratteri');

                                        return false;
                                    }
                                    await register();
                                    return false;
                                }}
                            >
                                <SaveIcon />
                            </Fab>

                        </UiFabContainer>

                    </Box>
                    }

                    <Dialog
                        fullScreen={im}
                        open={dialogContent != null}
                        onClose={() => {
                            setDialogContent(null);
                        }}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">{dialogContent?.nome}</DialogTitle>
                        <DialogContent dividers={scroll === 'paper'}>
                            <div
                                dangerouslySetInnerHTML={{ __html: dialogContent?.testo?.replaceAll("\n", "<BR>") }} />
                        </DialogContent>
                        <Stack sx={{ p: 4 }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                            spacing={2}>
                            <Button
                                variant={"outlined"}
                                color={"secondary"}
                                onClick={() => {
                                    setDialogContent(null);
                                }}>
                                Ho capito
                            </Button>
                        </Stack>
                    </Dialog>
                    <Modal open={multiImgModalOpen} onClose={() => setMultiImgModalOpen(false)}>
                        <Box sx={{ padding: 5, position: 'absolute', transform: 'translate(-50%, -50%)', top: '50%', left: '50%', width: 400, bgcolor: 'background.paper', boxShadow: 24 }}>
                            Non è possibile inserire due immagini identiche.
                        </Box>
                    </Modal>
                </UiContainer>
            }
        </>
    )
})